import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../../../stores/Store'
import { isMobile } from 'is-mobile';
import ClientBenefitsFlexiblePayUsers from './users';
import UserDoughnutChart from '../../../../../components/charts/UserDoughnut';

import './styles.scss';

const ClientBenefitsFlexiblePay = observer(({ backHome }) => {

    let navigate = useNavigate();
    const commonStore = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const [filter, setFilter] = useState('home')
    const [fpUsers, setFpUsers] = useState([])
    // const [advances, setAdvances] = useState([])
    // const [totalSalaries, setTotalSalaries] = useState(0)
    // const [totalUtilisations, setTotalUtilisations] = useState(0)
    const [mpUsers, setMpUsers] = useState([])

    useEffect(() => {
        getUsers()
    }, [])

    const getUsers = async () => {
        let flexiblePayClientId = commonStore.client.AvailableProducts.map((prod) => {
            if (prod.Name === "Flexible pay") {
                return prod.FlexiblePayClientId
            }
        })
        if (!flexiblePayClientId) {
            console.log("No flexible pay client id")
            commonStore.setSnackBar("warning", "Error accessing this benefit")
            backHome()
            return
        }
        let bulkData = await commonStore.getBulkFlexiblePayClientReporting(commonStore.user.Client._id, flexiblePayClientId)
        console.log(bulkData)
        if (!bulkData) {
            console.log("Error getting bulk reports")
            commonStore.setSnackBar("warning", "Error accessing this benefit")
            backHome()
            return
        }
        if (bulkData?.fpBulk && bulkData.mpUsers) {
            let mp = bulkData.mpUsers || []
            let fp = bulkData.fpBulk?.response?.users || []
            let advs = bulkData.fpBulk?.response?.users || []
            let arr = []
            if (fp.length > 0) {
                arr = fp.map((fpItem) => {
                    let result = mp.find(mpItem => fpItem.MarketplaceUserId === mpItem._id);
                    if (result) {
                        return {
                            ...fpItem,
                            SalaryAmount: result.SalaryAmount,
                            Client: result.Client
                        }
                    }
                    return null
                }).filter(item => item !== null)
            }
            // if (advs.length > 0) {
            //     //total utilisation
            //     let users = []
            //     if (arr.length === 0) 
            // }

            setMpUsers(mp)
            setFpUsers(arr)
        }
        else {
            commonStore.setSnackBar("error", "Error retrieving flexible pay reporting")
            backHome()
            return
        }

    }

    const handleSelectFilter = (e) => {
        setFilter(e.currentTarget.id)
    }

    if (isMobile()) {
        return (
            <div style={{ background: "#F4F5FA", width: '100%' }}>

            </div>
        );
    } else {
        return (
            <main style={{ background: "#F4F5FA", width: '100%' }}>
                <div className='flex flex-row gap-3'>
                    <button className='admin-dash-back-arrow-btn' id='home' onClick={() => backHome()}>Back</button>
                    <header className='admin-dash-option-header'>{`FlexiblePay`}</header>
                </div>
                <main style={{ background: "#FFFFFF", borderRadius: '20px', padding: '35px' }}>

                    <div className='flex gap-1 margin-2'>
                        <div id="home" onClick={handleSelectFilter} className={`client-benefits-filter-item ${filter === "home" ? 'selected' : null}`}>
                            Home
                        </div>
                        <div id="users" onClick={handleSelectFilter} className={`client-benefits-filter-item ${filter === "users" ? 'selected' : null}`}>
                            Users
                        </div>
                        {/* <div id="advances" onClick={handleSelectFilter} className={`client-benefits-filter-item ${filter === "advances" ? 'selected' : null}`}>
                            Advances
                        </div>
                        <div id="clients" onClick={handleSelectFilter} className={`client-benefits-filter-item ${filter === "clients" ? 'selected' : null}`}>
                            Clients
                        </div> */}
                    </div>

                    {filter === "home" &&
                        <div>
                            {mpUsers.length > 0 &&
                                <div className='flex flex-row w-full gap-10'>
                                    <div className='benefit-utilisation-graph-container' style={{ width: '100%' }}>
                                        <div className='flex flex-column gap-2 align-center'>
                                            <p>Active EWA users</p>
                                            <div>
                                                <p style={{ fontSize: '10px', margin: 0 }}>Active users</p>
                                                <div className='benefit-utilisation-graph-out-off-text'>{`${fpUsers.length}/${mpUsers.length}`}</div>
                                            </div>
                                        </div>
                                        <UserDoughnutChart current={fpUsers} total={mpUsers} currentColor={'#0062FF'} totalColor={'#BED8FF'} />
                                    </div>
                                    {/* <div className='benefit-utilisation-graph-container' style={{ width: '100%' }}>
                                        <div className='flex flex-column gap-2 align-center'>
                                            <p>Total EWA utilisation (Rands)</p>
                                            <div>
                                                <p style={{ fontSize: '10px', margin: 0 }}>Active users</p>
                                                <div style={{ color: '#D77519' }} className='benefit-utilisation-graph-out-off-text'>{`${fpUsers.length}/${mpUsers.length}`}</div>
                                            </div>
                                        </div>
                                        <UserDoughnutChart current={fpUsers} total={mpUsers} currentColor={'#D77519'} totalColor={'#FFE4CB'} />
                                    </div> */}
                                </div>

                            }
                            <br />
                        </div>
                    }
                    {filter === "users" &&
                        <ClientBenefitsFlexiblePayUsers data={{ fpUsers }} refresh={getUsers} />
                    }

                </main>
            </main>
        );
    }
})

export default ClientBenefitsFlexiblePay
