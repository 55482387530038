import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Navbar, Input } from '../../../components/basic/index'
import { EditPenBlueIcon, SearchIcon } from '../../../assets/icons/index'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import { permissions } from '../../../components/index'
import { AutohideSnackbar } from '../../../components/MUI';

import './styles.scss';

const ClientAdminEmployees = observer(() => {

    let navigate = useNavigate();
    const commonStore = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const [client, setClient] = useState([]);
    const [users, setUsers] = useState([]);
    const [clientFilterSelected, setClientFilterSelected] = useState('All')
    const [selectedClient, setSelectedClient] = useState(null)
    const [selectedUser, setSelectedUser] = useState(null)
    const [visible, setVisible] = useState("home")

    const [editVisible, setEditVisible] = useState("")

    const [userPrimaryDetails, setUserPrimaryDetails] = useState(null)
    const [oGUserPrimaryDetails, setUserOGPrimaryDetails] = useState(null)

    const [userPrimaryDetailsChanged, setUserPrimaryDetailsChanged] = useState(false)
    const [userAddress, setUserAddress] = useState(null)
    const [userAddressChanged, setUserAddressChanged] = useState(false)
    const [newUser, setNewUser] = useState(null)
    const [newAdminUser, setNewAdminUser] = useState(null)
    //PAYROLL
    const [clicked, setClicked] = useState('');
    const [nettSalary, setNettSalary] = useState(null)
    const [nettSalaryChanged, setNettSalaryChanged] = useState(false)

    const [oGUserAddress, setOGUserAddress] = useState(null)
    const [primaryBankAccount, setPrimaryBankAccount] = useState(null)
    const [ogPrimaryBankAccount, setOGPrimaryBankAccount] = useState(null)
    const [secondaryBankAccount, setSecondaryBankAccount] = useState(null)
    const [ogSecondaryBankAccount, setOGSecondaryBankAccount] = useState(null)
    const [primaryBankAccountChanged, setPrimaryBankAccountChanged] = useState(null)
    const [secondaryBankAccountChanged, setSecondaryBankAccountChanged] = useState(null)

    const [searchField, setSearchField] = useState('');
    const [editSalaryVisible, setEditSalaryVisible] = useState(false)
    const [viewSalary, setViewSalary] = useState(false)
    const [importResults, setImportResults] = useState({})
    const [filter, setFilter] = useState('Username')
    const [userPermissions, setUserPermissions] = useState(commonStore.user.Permissions || [])
    const [newAdminPermissionsChecked, setNewAdminPermissionsChecked] = useState([])
    const [updateAdminPermissionsChecked, setUpdateAdminPermissionsChecked] = useState([])
    const [usesPayroll, setUsesPayroll] = useState(commonStore.client?.PayrollInformation?.Verified || false)

    const emptyAddressObject = {
        Line1: "",
        Line2: "",
        Line3: "",
        City: "",
        Province: "",
        PostalCode: ""
    }

    useEffect(() => {
        reset()
        getClient()
        getUsers()

    }, [])

    const reset = () => {
        setViewSalary(false)
        setImportResults({})
        setEditSalaryVisible(false)
        setClientFilterSelected('All');
        setSelectedClient(null);
        setVisible("home");
        setUserPrimaryDetails(null);
        setUserPrimaryDetails(null);
        setUserPrimaryDetailsChanged(false);
        setUserAddress(null);
        setUserAddressChanged(false);
        setNewUser(null);
        setNewAdminUser(null)
        setClicked('');
        setEditVisible("")
        setOGUserAddress(null)
        forceUpdate()
    };

    const refresh = () => {
        reset()
        getClient()
        getUsers()
    }

    const handleOnSearchFieldChange = (e) => {
        let val = e.currentTarget.value
        setSearchField(val.toLowerCase())
    }

    const getClient = () => {
        setClient(commonStore.user.Client)
    }

    const getUsers = () => {
        commonStore.setLoading(true)
        commonStore.getClientUsers(commonStore.user.Client._id).then((ret) => {
            if (ret === "Users not found") {
                setUsers([])
            }
            else {
                setUsers(ret)
            }
            commonStore.setLoading(false)
        })

    }

    const goBack = () => {
        reset()
    }

    const handleNewUserChange = (e) => {
        let id = e.currentTarget.id
        let value;
        value = e.currentTarget.value

        let curr = newUser
        curr[id] = value

        setNewUser(curr)
        forceUpdate()
    }

    const handleNewAdminUserChange = (e) => {
        let id = e.currentTarget.id
        let value;
        value = e.currentTarget.value

        let curr = newAdminUser
        curr[id] = value

        setNewAdminUser(curr)
        forceUpdate()
    }

    const handleClientFilterOnClick = (e) => {
        setClientFilterSelected(e.target.id)
    }

    const updateUser = async (id, data) => {
        if (!window.confirm(`Are you sure you want to perform this action`)) {
            return
        }
        commonStore.setLoading(true)
        try {
            let res = await commonStore.updateUserByUsername(id, data)
            if (res) {
                commonStore.setSnackBar("success", "User updated")
                reset()
                getUsers()
            }
            else {
                commonStore.setSnackBar("error", "Error occured, please try again")
                commonStore.setLoading(false)
            }
        } catch (error) {
            console.error(error)
            commonStore.setSnackBar("error", "Error occured, please try again")
            commonStore.setLoading(false)
        }
    }

    const handleCreateUser = async () => {
        let user = newUser

        if (user.WorkEmail === "" || user.IDNumber === "" || user.MobileNumber === "" || user.FirstName === "") {
            alert("Please enter all required details")
            return
        }

        user.Username = user.IDNumber
        user.Source = `Created by ${commonStore.user.Username} at ${new Date()}`
        commonStore.setLoading(true)
        try {
            let create = await commonStore.createUser(user)
            commonStore.setLoading(false)
            if (create) {
                commonStore.setSnackBar("success", "User created")
                reset()
                getUsers()
            }
            else {
                commonStore.setLoading(false)
                commonStore.setSnackBar("error", "Error occured, please try again")
            }
        } catch (error) {
            commonStore.setLoading(false)
            console.error(error)
            commonStore.setSnackBar("error", "Error occured, please try again")
        }
    }

    const handleCreateAdminUser = async () => {
        let user = newAdminUser
        if (!userPermissions.includes("create-admin-users")) {
            commonStore.setSnackBar("warning", "You are not permitted to perform this action")
            return
        }
        if (user.WorkEmail === "" || user.FirstName === "" || user.Password === "") {
            alert("Please enter all required details")
            return
        }
        if (!window.confirm(`Are you sure you want to assign these ${newAdminPermissionsChecked.length} permission(s) for ${user.FirstName}`)) {
            return
        }

        user.Username = user.WorkEmail
        user.Permissions = newAdminPermissionsChecked
        user.CreatedDate = new Date()
        user.ConfirmedDate = new Date()
        commonStore.setLoading(true)
        try {
            let create = await commonStore.createUser(user)
            commonStore.setLoading(false)
            if (create) {
                if (create === "User already exists") {
                    commonStore.setSnackBar("warning", "User already exists")
                    reset()
                    getUsers()
                    return
                }
                commonStore.setSnackBar("success", "User created")
                reset()
                getUsers()
            }
            else {
                commonStore.setLoading(false)
                commonStore.setSnackBar("error", "Error occured, please try again")
            }
        } catch (error) {
            commonStore.setLoading(false)
            console.error(error)
            commonStore.setSnackBar("error", "Error occured, please try again")
        }
    }

    const handleOnClickUser = (object) => {
        if (!userPermissions.includes("view-user-details")) {
            commonStore.setSnackBar("warning", "You are not permitted to perform this action")
            return
        }
        if (object?.Role === "62bd55b7c5793941427c702d" && !userPermissions.includes("edit-permissions")) {
            commonStore.setSnackBar("warning", "You are not permitted to view this admin")
            return
        }
        setSelectedUser(object)

        //salary
        setNettSalary(object.SalaryAmount)
        //permissions
        setUpdateAdminPermissionsChecked(JSON.parse(JSON.stringify(object.Permissions || [])))

        //details
        setUserPrimaryDetails({
            WorkEmail: object.WorkEmail || "",
            PrivateEmail: object.PrivateEmail || "",
            MobileNumber: object.MobileNumber || "",
            FirstName: object.FirstName || "",
            LastName: object.LastName || "",
            BirthDate: object.BirthDate || ""
        })

        setUserOGPrimaryDetails({
            WorkEmail: object.WorkEmail || "",
            PrivateEmail: object.PrivateEmail || "",
            MobileNumber: object.MobileNumber || "",
            FirstName: object.FirstName || "",
            LastName: object.LastName || "",
            BirthDate: object.BirthDate || ""
        })

        //primary

        if (object.PrimaryBankAccount) {
            let prime = object.PrimaryBankAccount

            setPrimaryBankAccount({
                BankName: prime.BankName || "",
                Type: prime.Type || "",
                BranchName: prime.BranchName || "",
                BranchCode: prime.BranchCode || "",
                AccountNumber: prime.AccountNumber || ""
            })

            setOGPrimaryBankAccount({
                BankName: prime.BankName || "",
                Type: prime.Type || "",
                BranchName: prime.BranchName || "",
                BranchCode: prime.BranchCode || "",
                AccountNumber: prime.AccountNumber || ""
            })
        }
        else {
            setPrimaryBankAccount({
                BankName: "",
                Type: "",
                BranchName: "",
                BranchCode: "",
                AccountNumber: ""
            })
            setOGPrimaryBankAccount({
                BankName: "",
                Type: "",
                BranchName: "",
                BranchCode: "",
                AccountNumber: ""
            })
        }
        //secondary
        if (object.SecondaryBankAccount) {
            let sec = object.SecondaryBankAccount

            setSecondaryBankAccount({
                BankName: sec.BankName || "",
                Type: sec.Type || "",
                BranchName: sec.BranchName || "",
                BranchCode: sec.BranchCode || "",
                AccountNumber: sec.AccountNumber || ""
            })

            setOGSecondaryBankAccount({
                BankName: sec.BankName || "",
                Type: sec.Type || "",
                BranchName: sec.BranchName || "",
                BranchCode: sec.BranchCode || "",
                AccountNumber: sec.AccountNumber || ""
            })
        }
        else {
            setSecondaryBankAccount({
                BankName: "",
                Type: "",
                BranchName: "",
                BranchCode: "",
                AccountNumber: ""
            })
            setOGSecondaryBankAccount({
                BankName: "",
                Type: "",
                BranchName: "",
                BranchCode: "",
                AccountNumber: ""
            })
        }
        //address
        if (object.EmployeeAddresses) {
            let addyArr = {}
            if (object.EmployeeAddresses.length > 0) {
                addyArr = object.EmployeeAddresses.filter((a) => { return a.Type === "Residential" })
            }
            if (addyArr.length > 0) {
                let addy = addyArr[0]
                setUserAddress({
                    Line1: addy.Line1 || "",
                    Line2: addy.Line2 || "",
                    Line3: addy.Line3 || "",
                    City: addy.City || "",
                    Province: addy.Province || "",
                    PostalCode: addy.PostalCode || ""
                })

                setOGUserAddress({
                    Line1: addy.Line1 || "",
                    Line2: addy.Line2 || "",
                    Line3: addy.Line3 || "",
                    City: addy.City || "",
                    Province: addy.Province || "",
                    PostalCode: addy.PostalCode || ""
                })
            }
            else {
                setUserAddress(emptyAddressObject)
            }

        }
        else {
            setUserAddress(emptyAddressObject)
        }


        setVisible("singular-user")
        forceUpdate()
    }


    const handleUpdateUser = async (e) => {
        if (!window.confirm("Are you sure you want to make these changes")) {
            return
        }
        let id = e.currentTarget.id
        let updates = {}

        switch (id) {
            case "salary":
                updates = {
                    SalaryAmount: nettSalary
                }
                break
            case "userPermissions":
                updates = {
                    Permissions: updateAdminPermissionsChecked
                }
                break
            case "userDetails":
                updates = {
                    WorkEmail: userPrimaryDetails.WorkEmail || selectedUser.WorkEmail,
                    PrivateEmail: userPrimaryDetails.PrivateEmail || selectedUser.PrivateEmail,
                    MobileNumber: userPrimaryDetails.MobileNumber || selectedUser.MobileNumber,
                    FirstName: userPrimaryDetails.FirstName || selectedUser.FirstName,
                    LastName: userPrimaryDetails.LastName || selectedUser.LastName,
                    BirthDate: userPrimaryDetails.BirthDate || selectedUser.BirthDate
                }
                break

            case "userAddress":
                let temp = selectedUser.EmployeeAddresses
                let curr = []
                if (Array.isArray(temp)) {
                    if (temp.length > 0) {
                        curr = selectedUser.EmployeeAddresses.filter((addy) => {
                            return addy.Type !== "Residential"
                        })
                    }
                }

                curr.push({
                    Line1: userAddress.Line1 || "",
                    Line2: userAddress.Line2 || "",
                    Line3: userAddress.Line3 || "",
                    City: userAddress.City || "",
                    Province: userAddress.Province || "",
                    PostalCode: userAddress.PostalCode || ""
                })
                updates = { EmployeeAddresses: curr }
                break
            case "primaryBank":
                updates = {
                    PrimaryBankAccount: {
                        BankName: primaryBankAccount.BankName || "",
                        Type: primaryBankAccount.Type || "",
                        BranchName: primaryBankAccount.BranchName || "",
                        BranchCode: primaryBankAccount.BranchCode || "",
                        AccountNumber: primaryBankAccount.AccountNumber || ""
                    }
                }
                break
            case "secondaryBank":
                updates = {
                    SecondaryBankAccount: {
                        BankName: secondaryBankAccount.BankName || "",
                        Type: secondaryBankAccount.Type || "",
                        BranchName: secondaryBankAccount.BranchName || "",
                        BranchCode: secondaryBankAccount.BranchCode || "",
                        AccountNumber: secondaryBankAccount.AccountNumber || ""
                    }
                }
                break
        }
        commonStore.setLoading(true)

        try {
            let res = await commonStore.updateUserByUsername(selectedUser.Username, updates)
            if (res) {
                commonStore.setLoading(false)
                commonStore.setSnackBar("success", "User updated")
                reset()
                getUsers()
            }
            else {
                commonStore.setLoading(false)
                commonStore.setSnackBar("error", "Error occured, please try again")
                reset()
            }
        } catch (error) {
            console.error(error)
            commonStore.setLoading(false)
            commonStore.setSnackBar("error", "Error occured, please try again")
            reset()
        }


    }

    const handleOnDetailsChange = (e, type) => {
        let id = e.currentTarget.id
        let value = e.currentTarget.value

        switch (type) {
            case "userDetails":
                let temp = userPrimaryDetails
                temp[id] = value
                setUserPrimaryDetails(temp)
                forceUpdate()

                if (JSON.stringify(oGUserPrimaryDetails) === JSON.stringify(userPrimaryDetails)) {
                    setUserPrimaryDetailsChanged(false)
                }
                else {
                    setUserPrimaryDetailsChanged(true)
                }
                break
            case "primaryBank":
                let temp1 = primaryBankAccount
                temp1[id] = value
                setPrimaryBankAccount(temp1)
                forceUpdate()

                if (JSON.stringify(ogSecondaryBankAccount) === JSON.stringify(primaryBankAccount)) {
                    setPrimaryBankAccountChanged(false)
                }
                else {
                    setPrimaryBankAccountChanged(true)
                }
                break
            case "secondaryBank":
                let temp2 = secondaryBankAccount
                temp2[id] = value
                setSecondaryBankAccount(temp2)
                forceUpdate()

                if (JSON.stringify(ogSecondaryBankAccount) === JSON.stringify(secondaryBankAccount)) {
                    setSecondaryBankAccountChanged(false)
                }
                else {
                    setSecondaryBankAccountChanged(true)
                }
                break
            case "nettSalary":
                let formated = e.currentTarget.value?.replace(/^0+/, '')
                setNettSalary(formated)
                let ogNett = selectedUser.SalaryAmount
                if (e.currentTarget.value == ogNett) {
                    setNettSalaryChanged(false)
                }
                else {
                    setNettSalaryChanged(true)
                }
                break
            case "address":
                let curr = userAddress
                curr[id] = value
                setUserAddress(curr)
                forceUpdate()

                if (selectedUser.EmployeeAddresses) {
                    if (JSON.stringify(oGUserAddress) === JSON.stringify(userAddress)) {
                        setUserAddressChanged(false)
                    }
                    else {
                        setUserAddressChanged(true)
                    }
                }
                else {
                    if (JSON.stringify(emptyAddressObject) === JSON.stringify(userAddress)) {
                        setUserAddressChanged(false)
                    }
                    else {
                        setUserAddressChanged(true)
                    }
                }
                break
        }


        forceUpdate()
    }

    const handleAddUserOnClick = () => {
        setVisible("add-user")
        setNewUser({
            Client: commonStore.client._id,
            FirstName: "",
            LastName: "",
            WorkEmail: "",
            PrivateEmail: "",
            MobileNumber: "",
            IDNumber: "",
            SalaryAmount: 0,
            Blocked: false,
            Source: `Created by ${commonStore.user.Username}`,
            Confirmed: false,
            Role: '5d8e0b079f51d42bbece9202'
        })
        forceUpdate()
    }

    const handleAddAdminUserOnClick = () => {
        setVisible("add-admin-user")
        setNewAdminUser({
            FirstName: "",
            LastName: "",
            Username: "",
            WorkEmail: "",
            MobileNumber: "",
            Password: "",
            Blocked: false,
            Source: `Created by ${commonStore.user.Username}`,
            Client: commonStore.user.Client,
            Confirmed: true,
            Role: '62bd55b7c5793941427c702d'
        })
        forceUpdate()
    }

    const resetEditSection = () => {
        setEditVisible("")
        setUserPrimaryDetails(prev => ({ ...prev, ...oGUserPrimaryDetails }))
        setUserPrimaryDetailsChanged(false)
        setUserAddress(prev => ({ ...prev, ...oGUserAddress }))
        setUserAddressChanged(false)
        setPrimaryBankAccount(prev => ({ ...prev, ...ogPrimaryBankAccount }))
        setPrimaryBankAccountChanged(false)
        setSecondaryBankAccount(prev => ({ ...prev, ...ogSecondaryBankAccount }))
        setSecondaryBankAccountChanged(false)
        forceUpdate()
    }

    const handlePayrollPopulate = async () => {
        if (!userPermissions.includes("update-payroll")) {
            commonStore.setSnackBar("warning", "You are not permitted to perform this action")
            return
        }
        let payroll = commonStore.client.PayrollInformation
        if (!payroll?.Verified) {
            return
        }
        if (!window.confirm("Are you sure you want to perform this action?")) {
            return
        }
        switch (payroll.Provider) {
            case "Simple pay":
                populateSimplePay(payroll)
                break
            case "Pay space":
                populatePayspace(payroll)
                break
            case "Labour net":
                populateLabournet(payroll)
                break
            case "Sage 300":
                populateSage(payroll)
                break
            default:
                break
        }
        return

    }

    const populateLabournet = async (obj) => {
        try {
            commonStore.setLoading(true)

            let res = await commonStore.linkLabournet(obj, commonStore.client._id)
            if (res.initialPayrollCount) {
                commonStore.setClient(res.updatedClient)
                let results = {
                    statusInfoAvailable: false,
                    initialPayrollCount: res.initialPayrollCount,
                    clientInitialEmployeeCount: res.clientInitialEmployeeCount,
                    foundAndTerminated: res.foundAndTerminated.length,
                    foundAndEmployed: res.foundAndEmployed.length,
                    notFoundAndEmployed: res.notFoundAndEmployed.length,
                    notfoundAndTerminated: res.notfoundAndTerminated.length,
                    failedImports: res.failedImports.length
                }
                setImportResults(results)
                setEditVisible('populate-results')
                getUsers()
                forceUpdate()
                commonStore.setLoading(false)
            }
            else {
                commonStore.setLoading(false)
                commonStore.setSnackBar("error", "Error retrieving employees")
            }
        } catch (error) {
            commonStore.setLoading(false)
            console.error(error)
            commonStore.setSnackBar("error", "Error retrieving employees")
        }
    }

    const populateSage = async (obj) => {
        try {
            commonStore.setLoading(true)
            let res = await commonStore.linkSage(obj, commonStore.client._id)
            if (res.initialPayrollCount) {
                commonStore.setClient(res.updatedClient)
                let results = {
                    statusInfoAvailable: false,
                    initialPayrollCount: res.initialPayrollCount,
                    clientInitialEmployeeCount: res.clientInitialEmployeeCount,
                    foundAndTerminated: res.foundAndTerminated.length,
                    foundAndEmployed: res.foundAndEmployed.length,
                    notFoundAndEmployed: res.notFoundAndEmployed.length,
                    notfoundAndTerminated: res.notfoundAndTerminated.length,
                    failedImports: res.failedImports.length
                }
                setImportResults(results)
                setEditVisible('populate-results')
                getUsers()
                forceUpdate()
                commonStore.setLoading(false)
            }
            else {
                commonStore.setLoading(false)
                commonStore.setSnackBar("error", "Error retrieving employees")
            }
        } catch (error) {
            commonStore.setLoading(false)
            console.error(error)
            commonStore.setSnackBar("error", "Error retrieving employees")
        }
    }

    const populateSimplePay = async (obj) => {
        try {
            commonStore.setLoading(true)

            let res = await commonStore.linkSimplepay(obj, commonStore.client._id)
            if (res.initialPayrollCount) {
                let results = {
                    statusInfoAvailable: true,
                    initialPayrollCount: res.initialPayrollCount,
                    clientInitialEmployeeCount: res.clientInitialEmployeeCount,
                    foundAndTerminated: res.foundAndTerminated.length,
                    foundAndEmployed: res.foundAndEmployed.length,
                    notFoundAndEmployed: res.notFoundAndEmployed.length,
                    notfoundAndTerminated: res.notfoundAndTerminated.length,
                    failedImports: res.failedImports.length
                }
                setImportResults(results)
                setEditVisible('populate-results')
                getUsers()
                forceUpdate()
                commonStore.setLoading(false)
            }
            else {
                commonStore.setLoading(false)
                commonStore.setSnackBar("error", "Error retrieving employees")

            }
        } catch (error) {
            commonStore.setLoading(false)
            console.error(error)
            commonStore.setSnackBar("error", "Error retrieving employees")
        }


    }

    const populatePayspace = async (obj) => {
        try {
            commonStore.setLoading(true)

            let res = await commonStore.linkPayspace(obj, commonStore.client._id)
            if (res.initialPayrollCount) {
                commonStore.setClient(res.updatedClient)
                let results = {
                    statusInfoAvailable: true,
                    initialPayrollCount: res.initialPayrollCount,
                    clientInitialEmployeeCount: res.clientInitialEmployeeCount,
                    foundAndTerminated: res.foundAndTerminated.length,
                    foundAndEmployed: res.foundAndEmployed.length,
                    notFoundAndEmployed: res.notFoundAndEmployed.length,
                    notfoundAndTerminated: res.notfoundAndTerminated.length,
                    failedImports: res.failedImports.length
                }
                setImportResults(results)
                setEditVisible('populate-results')
                getUsers()
                commonStore.setLoading(false)
                forceUpdate()
            }
            commonStore.setLoading(false)
        } catch (error) {
            commonStore.setLoading(false)
            console.error(error)
            commonStore.setSnackBar("error", "Error retrieving employees")
        }


    }

    const handleFilterChange = (e) => {
        setFilter(e.target.value)
    }

    const handlePermissionsChecked = (e) => {
        let id = e.currentTarget.id;

        setNewAdminPermissionsChecked((prevItems) => {
            if (prevItems.includes(id)) {
                return prevItems.filter((item) => item !== id);
            } else {
                return [...prevItems, id];
            }
        });
    };

    const handleUpdatePermissionsChecked = (e) => {
        let id = e.currentTarget.id;

        setUpdateAdminPermissionsChecked((prevItems) => {
            if (prevItems.includes(id)) {
                return prevItems.filter((item) => item !== id);
            } else {
                return [...prevItems, id];
            }
        });
    };

    const handleUploadFileOnClick = () => {
        if (!userPermissions.includes("update-payroll")) {
            commonStore.setSnackBar("warning", "You are not permitted to perform this action")
            return
        }
        navigate('/client/employee/upload')
    }

    if (isMobile()) {
        return (
            <div>
                <main>
                    <div>

                    </div>

                </main>
            </div>
        );
    } else {
        return (
            <div style={{ background: "#F4F5FA", width: '100%', height: '100%' }}>
                <AutohideSnackbar content={commonStore.snackBarDetails.content} open={commonStore.snackBarDetails.open} type={commonStore.snackBarDetails.type} setSnackBarClose={commonStore.resetSnackBar} />

                {visible === 'add-user' &&
                    <main style={{ position: 'fixed', background: '#808080ba' }}>
                        <div className='admin-dash-edit-inner-container'>
                            <header className='admin-dash-client-details-text mediumbold' style={{ fontSize: '18px' }}>
                                Add New User
                            </header>
                            <br />
                            <br />
                            <div className='two-by-two-grid'>
                                <div className='flex-column'>
                                    <label className='admin-dash-client-details-text small'>First name<span style={{ color: 'red' }}>*</span></label>
                                    <input value={newUser.FirstName}
                                        id='FirstName'
                                        onChange={handleNewUserChange}
                                        className='admin-dash-assess-input'
                                        placeholder='First name'
                                    />
                                </div>
                                <div className='flex-column'>
                                    <label className='admin-dash-client-details-text small'>Last name</label>
                                    <input value={newUser.LastName}
                                        id='LastName'
                                        onChange={handleNewUserChange}
                                        className='admin-dash-assess-input'
                                        placeholder='Last name'
                                    />
                                </div>
                                <div className='flex-column'>
                                    <label className='admin-dash-client-details-text small'>ID number/passport<span style={{ color: 'red' }}>*</span></label>
                                    <input value={newUser.IDNumber}
                                        id='IDNumber'
                                        onChange={handleNewUserChange}
                                        className='admin-dash-assess-input'
                                        placeholder='ID number'
                                    />
                                </div>
                                <div className='flex-column'>
                                    <label className='admin-dash-client-details-text small'>Work email<span style={{ color: 'red' }}>*</span></label>
                                    <input value={newUser.WorkEmail}
                                        id='WorkEmail'
                                        onChange={handleNewUserChange}
                                        className='admin-dash-assess-input'
                                        placeholder='Work email'
                                    />
                                </div>
                                <div className='flex-column'>
                                    <label className='admin-dash-client-details-text small'>Mobile number<span style={{ color: 'red' }}>*</span></label>
                                    <input value={newUser.MobileNumber}
                                        id='MobileNumber'
                                        onChange={handleNewUserChange}
                                        className='admin-dash-assess-input'
                                        placeholder='Mobile number'
                                    />
                                </div>
                                <div className='flex-column'>
                                    <label className='admin-dash-client-details-text small'>Nett salary</label>
                                    <input value={newUser.SalaryAmount}
                                        id='SalaryAmount'
                                        onChange={handleNewUserChange}
                                        className='admin-dash-assess-input'
                                        placeholder='Nett salary'
                                    />
                                </div>
                                <div></div>
                                <br />
                                <button className='admin-dash-back-arrow-btn' onClick={reset}>Cancel</button>
                                <button className='admin-dash-back-arrow-btn'
                                    style={{ background: 'black', color: 'white' }}
                                    id='userDetails'
                                    onClick={handleCreateUser}>
                                    Add
                                </button>
                            </div>
                        </div>
                    </main>
                }
                {visible === 'add-admin-user' &&
                    <main style={{ position: 'fixed', background: '#808080ba' }}>
                        <div className='admin-dash-edit-inner-container'>
                            <header className='admin-dash-client-details-text mediumbold' style={{ fontSize: '18px' }}>
                                Add New Admin User
                            </header>
                            <div className="overflow-y-scroll h-64">
                                <div className='two-by-two-grid'>
                                    <div className='flex-column'>
                                        <label className='admin-dash-client-details-text small'>First name<span style={{ color: 'red' }}>*</span></label>
                                        <input value={newAdminUser.FirstName}
                                            id='FirstName'
                                            onChange={handleNewAdminUserChange}
                                            className='admin-dash-assess-input'
                                            placeholder='First name'
                                        />
                                    </div>
                                    <div className='flex-column'>
                                        <label className='admin-dash-client-details-text small'>Last name</label>
                                        <input value={newAdminUser.LastName}
                                            id='LastName'
                                            onChange={handleNewAdminUserChange}
                                            className='admin-dash-assess-input'
                                            placeholder='Last name'
                                        />
                                    </div>
                                    <div className='flex-column'>
                                        <label className='admin-dash-client-details-text small'>Work email (Username)<span style={{ color: 'red' }}>*</span></label>
                                        <input value={newAdminUser.WorkEmail}
                                            id='WorkEmail'
                                            onChange={handleNewAdminUserChange}
                                            className='admin-dash-assess-input'
                                            placeholder='Work email  (Username)'
                                        />
                                    </div>
                                    <div className='flex-column'>
                                        <label className='admin-dash-client-details-text small'>Mobile number</label>
                                        <input value={newAdminUser.MobileNumber}
                                            id='MobileNumber'
                                            onChange={handleNewAdminUserChange}
                                            className='admin-dash-assess-input'
                                            placeholder='Mobile number'
                                        />
                                    </div>
                                    <div className='flex-column'>
                                        <label className='admin-dash-client-details-text small'>Password<span style={{ color: 'red' }}>*</span></label>
                                        <input value={newAdminUser.Password}
                                            id='Password'
                                            type="password"
                                            onChange={handleNewAdminUserChange}
                                            className='admin-dash-assess-input'
                                            placeholder='Password'
                                        />
                                    </div>
                                    <br />
                                    <div>
                                        <div className='employee-benefits-assessment-multiple-choice-choices-container'>
                                            <header className='employee-benefits-assessment-multiple-choice-choices-header'>{`Permissions`}</header>
                                            {permissions.employerAdminPermissions.map((item, index) => (
                                                <div className='employee-benefits-assessment-multiple-choice-choices-item' key={index}>
                                                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                        <input
                                                            type='checkbox'
                                                            id={item.id}
                                                            className='employee-benefits-assessment-multiple-choice-choices-item-checkbox'
                                                            onChange={handlePermissionsChecked}
                                                            checked={newAdminPermissionsChecked.includes(item.id)}
                                                        />
                                                        <label htmlFor={item.id}>{item.title}</label>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <br />
                                </div>
                            </div>
                            <br />
                            <div className='flex gap-1'>
                                <button style={{ width: '100%' }} className='admin-dash-back-arrow-btn' onClick={reset}>Cancel</button>
                                <button className='admin-dash-back-arrow-btn'
                                    style={{ background: 'black', color: 'white', width: '100%' }}
                                    id='userDetails'
                                    onClick={handleCreateAdminUser}>
                                    Add
                                </button>
                            </div>

                        </div>

                    </main>
                }
                {editVisible === 'populate-results' &&
                    <main style={{ position: 'fixed', background: '#808080ba' }}>
                        <div className='admin-dash-edit-inner-container'>
                            <header className='admin-dash-client-details-text mediumbold' style={{ fontSize: '16px' }}>
                                Payroll populate results
                            </header>
                            <br />
                            {importResults.statusInfoAvailable ?
                                <>
                                    <p style={{ textAlign: 'left', fontSize: '15px' }} className='employee-benefits-assessment-submit-subheader'>
                                        {`Initial employees: ${importResults.clientInitialEmployeeCount}`}
                                    </p>
                                    <p style={{ textAlign: 'left', fontSize: '15px' }} className='employee-benefits-assessment-submit-subheader'>
                                        {`Employees found in payroll: ${importResults.initialPayrollCount}`}
                                    </p>
                                    <p style={{ textAlign: 'left', fontSize: '15px' }} className='employee-benefits-assessment-submit-subheader'>
                                        {`New employees added: ${importResults.notFoundAndEmployed}`}
                                    </p>
                                    <p style={{ textAlign: 'left', fontSize: '15px' }} className='employee-benefits-assessment-submit-subheader'>
                                        {`Updated employees: ${importResults.foundAndEmployed}`}
                                    </p>
                                    <p style={{ textAlign: 'left', fontSize: '15px' }} className='employee-benefits-assessment-submit-subheader'>
                                        {`Terminated employees (Found in Level): ${importResults.foundAndTerminated}`}
                                    </p>
                                    <p style={{ textAlign: 'left', fontSize: '15px' }} className='employee-benefits-assessment-submit-subheader'>
                                        {`Terminated employees (Not found in Level): ${importResults.notfoundAndTerminated}`}
                                    </p>
                                    <p style={{ textAlign: 'left', fontSize: '15px' }} className='employee-benefits-assessment-submit-subheader'>
                                        {`Failed imports (No ID number/passport in payroll): ${importResults.failedImports}`}
                                    </p>
                                </> :
                                <>
                                    <p style={{ textAlign: 'left', fontSize: '15px' }} className='employee-benefits-assessment-submit-subheader'>
                                        {`Initial employees in Level: ${importResults.clientInitialEmployeeCount}`}
                                    </p>
                                    <p style={{ textAlign: 'left', fontSize: '15px' }} className='employee-benefits-assessment-submit-subheader'>
                                        {`Employees found in payroll: ${importResults.initialPayrollCount}`}
                                    </p>
                                    <p style={{ textAlign: 'left', fontSize: '15px' }} className='employee-benefits-assessment-submit-subheader'>
                                        {`New employees added: ${importResults.notFoundAndEmployed}`}
                                    </p>
                                    <p style={{ textAlign: 'left', fontSize: '15px' }} className='employee-benefits-assessment-submit-subheader'>
                                        {`Failed imports (No ID number/passport in payroll): ${importResults.failedImports}`}
                                    </p>
                                </>
                            }
                            <br />
                            <button className='admin-dash-back-arrow-btn' onClick={resetEditSection}>Close</button>
                        </div>
                    </main>
                }
                {editVisible === 'personal' &&
                    <main style={{ position: 'fixed', background: '#808080ba' }}>
                        <div className='admin-dash-edit-inner-container'>
                            <header className='admin-dash-client-details-text mediumbold' style={{ fontSize: '18px' }}>
                                Edit Personal details
                            </header>
                            <br />
                            <div className='two-by-two-grid'>
                                <div className='flex-column'>
                                    <label className='admin-dash-client-details-text small'>First name</label>
                                    <input value={userPrimaryDetails.FirstName}
                                        id='FirstName'
                                        onChange={(e) => { handleOnDetailsChange(e, "userDetails") }}
                                        className='admin-dash-assess-input'
                                        placeholder='First name'
                                    />
                                </div>
                                <div className='flex-column'>
                                    <label className='admin-dash-client-details-text small'>Last Name</label>
                                    <input value={userPrimaryDetails.LastName}
                                        id='LastName'
                                        onChange={(e) => { handleOnDetailsChange(e, "userDetails") }}
                                        className='admin-dash-assess-input'
                                        placeholder='Last Name'
                                    />
                                </div>
                                <div className='flex-column'>
                                    <label className='admin-dash-client-details-text small'>Work email</label>
                                    <input value={userPrimaryDetails.WorkEmail}
                                        id='WorkEmail'
                                        onChange={(e) => { handleOnDetailsChange(e, "userDetails") }}
                                        className='admin-dash-assess-input'
                                        placeholder='Work email'
                                    />
                                </div>
                                <div className='flex-column'>
                                    <label className='admin-dash-client-details-text small'>Personal email</label>
                                    <input value={userPrimaryDetails.PrivateEmail}
                                        id='PrivateEmail'
                                        onChange={(e) => { handleOnDetailsChange(e, "userDetails") }}
                                        className='admin-dash-assess-input'
                                        placeholder='Personal Email'
                                    />
                                </div>
                                <div className='flex-column'>
                                    <label className='admin-dash-client-details-text small'>Mobile number</label>
                                    <input value={userPrimaryDetails.MobileNumber}
                                        id='MobileNumber'
                                        onChange={(e) => { handleOnDetailsChange(e, "userDetails") }}
                                        className='admin-dash-assess-input'
                                        placeholder='Mobile number'
                                    />
                                </div>
                                <br />
                                <button className='admin-dash-back-arrow-btn' onClick={resetEditSection}>Cancel</button>
                                {
                                    userPrimaryDetailsChanged &&
                                    <button className='admin-dash-back-arrow-btn'
                                        style={{ background: 'black', color: 'white' }}
                                        id='userDetails'
                                        onClick={handleUpdateUser}>
                                        Update
                                    </button>
                                }
                            </div>
                        </div>
                    </main>
                }
                {editVisible === 'address' &&
                    <main style={{ position: 'fixed', background: '#808080ba' }}>
                        <div className='admin-dash-edit-inner-container'>
                            <header className='admin-dash-client-details-text mediumbold' style={{ fontSize: '18px' }}>
                                Edit Address details
                            </header>
                            <br />
                            <div className='two-by-two-grid'>
                                <div className='flex-column'>
                                    <label className='admin-dash-client-details-text small'>Line 1</label>
                                    <input value={userAddress.Line1}
                                        id='Line1'
                                        onChange={(e) => { handleOnDetailsChange(e, "address") }}
                                        className='admin-dash-assess-input'
                                        placeholder='Line 1'
                                    />
                                </div>
                                <div className='flex-column'>
                                    <label className='admin-dash-client-details-text small'>Line 2</label>
                                    <input value={userAddress.Line2}
                                        id='Line2'
                                        onChange={(e) => { handleOnDetailsChange(e, "address") }}
                                        className='admin-dash-assess-input'
                                        placeholder='Line 2'
                                    />
                                </div>
                                <div className='flex-column'>
                                    <label className='admin-dash-client-details-text small'>Line 3</label>
                                    <input value={userAddress.Line3}
                                        id='Line3'
                                        onChange={(e) => { handleOnDetailsChange(e, "address") }}
                                        className='admin-dash-assess-input'
                                        placeholder='Line 3'
                                    />
                                </div>
                                <div className='flex-column'>
                                    <label className='admin-dash-client-details-text small'>City</label>
                                    <input value={userAddress.City}
                                        id='City'
                                        onChange={(e) => { handleOnDetailsChange(e, "address") }}
                                        className='admin-dash-assess-input'
                                        placeholder='City'
                                    />
                                </div>
                                <div className='flex-column'>
                                    <label className='admin-dash-client-details-text small'>Province</label>
                                    <input value={userAddress.Province}
                                        id='Province'
                                        onChange={(e) => { handleOnDetailsChange(e, "address") }}
                                        className='admin-dash-assess-input'
                                        placeholder='Province'
                                    />
                                </div>
                                <div className='flex-column'>
                                    <label className='admin-dash-client-details-text small'>Postal code</label>
                                    <input value={userAddress.PostalCode}
                                        id='PostalCode'
                                        onChange={(e) => { handleOnDetailsChange(e, "address") }}
                                        className='admin-dash-assess-input'
                                        placeholder='PostalCode'
                                    />
                                </div>

                                <button className='admin-dash-back-arrow-btn' onClick={resetEditSection}>Cancel</button>
                                {
                                    userAddressChanged &&
                                    <button className='admin-dash-back-arrow-btn'
                                        style={{ background: 'black', color: 'white' }}
                                        id='userAddress'
                                        onClick={handleUpdateUser}>
                                        Update
                                    </button>
                                }
                            </div>
                        </div>
                    </main>
                }
                {editVisible === 'primary-bank' &&
                    <main style={{ position: 'fixed', background: '#808080ba' }}>
                        <div className='admin-dash-edit-inner-container'>
                            <header className='admin-dash-client-details-text mediumbold' style={{ fontSize: '18px' }}>
                                Edit Primary Bank account details
                            </header>
                            <br />
                            <div className='two-by-two-grid'>
                                <div className='flex-column'>
                                    <label className='admin-dash-client-details-text small'>Type</label>
                                    <input value={primaryBankAccount.Type}
                                        id='Type'
                                        onChange={(e) => { handleOnDetailsChange(e, "primaryBank") }}
                                        className='admin-dash-assess-input'
                                        placeholder='Type'
                                    />
                                </div>
                                <div className='flex-column'>
                                    <label className='admin-dash-client-details-text small'>Bank name</label>
                                    <input value={primaryBankAccount.BankName}
                                        id='BankName'
                                        onChange={(e) => { handleOnDetailsChange(e, "primaryBank") }}
                                        className='admin-dash-assess-input'
                                        placeholder='Bank name'
                                    />
                                </div>
                                <div className='flex-column'>
                                    <label className='admin-dash-client-details-text small'>Account number</label>
                                    <input value={primaryBankAccount.AccountNumber}
                                        id='AccountNumber'
                                        onChange={(e) => { handleOnDetailsChange(e, "primaryBank") }}
                                        className='admin-dash-assess-input'
                                        placeholder='Account number'
                                    />
                                </div>
                                <div className='flex-column'>
                                    <label className='admin-dash-client-details-text small'>Branch code</label>
                                    <input value={primaryBankAccount.BranchCode}
                                        id='BranchCode'
                                        onChange={(e) => { handleOnDetailsChange(e, "primaryBank") }}
                                        className='admin-dash-assess-input'
                                        placeholder='Branch code'
                                    />
                                </div>
                                <div className='flex-column'>
                                    <label className='admin-dash-client-details-text small'>Branch Name</label>
                                    <input value={primaryBankAccount.BranchName}
                                        id='BranchName'
                                        onChange={(e) => { handleOnDetailsChange(e, "primaryBank") }}
                                        className='admin-dash-assess-input'
                                        placeholder='Branch Name'
                                    />
                                </div>
                                <br />
                                <button className='admin-dash-back-arrow-btn' onClick={resetEditSection}>Cancel</button>
                                {
                                    primaryBankAccountChanged &&
                                    <button className='admin-dash-back-arrow-btn'
                                        style={{ background: 'black', color: 'white' }}
                                        id='primaryBank'
                                        onClick={handleUpdateUser}>
                                        Update
                                    </button>
                                }
                            </div>
                        </div>
                    </main>
                }
                {editVisible === 'sec-bank' &&
                    <main style={{ position: 'fixed', background: '#808080ba' }}>
                        <div className='admin-dash-edit-inner-container'>
                            <header className='admin-dash-client-details-text mediumbold' style={{ fontSize: '18px' }}>
                                Edit Secondary bank account details
                            </header>
                            <br />
                            <div className='two-by-two-grid'>
                                <div className='flex-column'>
                                    <label className='admin-dash-client-details-text small'>Type</label>
                                    <input value={secondaryBankAccount.Type}
                                        id='Type'
                                        onChange={(e) => { handleOnDetailsChange(e, "secondaryBank") }}
                                        className='admin-dash-assess-input'
                                        placeholder='Type'
                                    />
                                </div>
                                <div className='flex-column'>
                                    <label className='admin-dash-client-details-text small'>Bank name</label>
                                    <input value={secondaryBankAccount.BankName}
                                        id='BankName'
                                        onChange={(e) => { handleOnDetailsChange(e, "secondaryBank") }}
                                        className='admin-dash-assess-input'
                                        placeholder='Bank name'
                                    />
                                </div>
                                <div className='flex-column'>
                                    <label className='admin-dash-client-details-text small'>Account number</label>
                                    <input value={secondaryBankAccount.AccountNumber}
                                        id='AccountNumber'
                                        onChange={(e) => { handleOnDetailsChange(e, "secondaryBank") }}
                                        className='admin-dash-assess-input'
                                        placeholder='Account number'
                                    />
                                </div>
                                <div className='flex-column'>
                                    <label className='admin-dash-client-details-text small'>Branch code</label>
                                    <input value={secondaryBankAccount.BranchCode}
                                        id='BranchCode'
                                        onChange={(e) => { handleOnDetailsChange(e, "secondaryBank") }}
                                        className='admin-dash-assess-input'
                                        placeholder='Branch code'
                                    />
                                </div>
                                <div className='flex-column'>
                                    <label className='admin-dash-client-details-text small'>Branch Name</label>
                                    <input value={secondaryBankAccount.BranchName}
                                        id='BranchName'
                                        onChange={(e) => { handleOnDetailsChange(e, "secondaryBank") }}
                                        className='admin-dash-assess-input'
                                        placeholder='Branch Name'
                                    />
                                </div>
                                <br />
                                <button className='admin-dash-back-arrow-btn' onClick={resetEditSection}>Cancel</button>
                                {
                                    secondaryBankAccountChanged &&
                                    <button className='admin-dash-back-arrow-btn'
                                        style={{ background: 'black', color: 'white' }}
                                        id='secondaryBank'
                                        onClick={handleUpdateUser}>
                                        Update
                                    </button>
                                }
                            </div>
                        </div>
                    </main>
                }
                {editVisible === 'permissions' &&
                    <main style={{ position: 'fixed', background: '#808080ba' }}>
                        <div className='admin-dash-edit-inner-container' style={{ overFlowY: 'hidden', width: '50%' }}>
                            <header className='admin-dash-client-details-text mediumbold' style={{ fontSize: '18px' }}>
                                Permissions
                            </header>
                            <div className="overflow-y-scroll h-64">
                                <div className='employee-benefits-assessment-multiple-choice-choices-container'>
                                    {permissions.employerAdminPermissions.map((item, index) => (
                                        <div className='employee-benefits-assessment-multiple-choice-choices-item' key={index}>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                <input
                                                    type='checkbox'
                                                    id={item.id}
                                                    className='employee-benefits-assessment-multiple-choice-choices-item-checkbox'
                                                    onChange={handleUpdatePermissionsChecked}
                                                    checked={updateAdminPermissionsChecked.includes(item.id)}
                                                />
                                                <label htmlFor={item.id}>{item.title}</label>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                            </div>
                            <br />
                            <div className='flex gap-2'>
                                <button style={{ width: '100%' }} className='admin-dash-back-arrow-btn' onClick={reset}>Cancel</button>
                                <button className='admin-dash-back-arrow-btn'
                                    style={{ background: 'black', color: 'white', width: '100%' }}
                                    id='userPermissions'
                                    onClick={handleUpdateUser}>
                                    Update
                                </button>
                            </div>
                        </div>
                    </main>
                }
                {/* MAIN SECTION */}
                {visible === "home" &&
                    <main>
                        <header className='admin-dash-option-header'>{`${commonStore.client.Name}'s employees`}</header>
                        <main style={{ background: "#FFFFFF", borderRadius: '20px', padding: '35px' }}>
                            {/* TOP DIV */}
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                                <header className='admin-dash-sub-header'>{`Employees - Total (${users.length}) `}<span><i style={{ cursor: 'pointer' }} onClick={refresh} class="fa-solid fa-arrow-rotate-right"></i></span></header>

                                <div className='flex gap-3 items-center'>
                                    {usesPayroll ?
                                        <>
                                            <p className='text-center text-[#E66261] '>{`Payroll last updated ${commonStore.client.LastPayrollUpdate ? commonStore.formatDate(commonStore.client.LastPayrollUpdate) : "..."}`}</p>
                                            <p className='cursor-pointer' onClick={handlePayrollPopulate}>+ Update payroll</p>
                                        </>
                                        :
                                        <>
                                            <p className='cursor-pointer' onClick={handleUploadFileOnClick}><i class="fa-solid fa-arrow-up-from-bracket"></i> Upload payroll file</p>
                                            <button className='admin-dash-add-btn' onClick={() => handleAddUserOnClick()}>&#43; Add Employee</button>
                                        </>
                                    }
                                    <button className='admin-dash-add-btn' onClick={() => handleAddAdminUserOnClick()}>&#43; {`Add ${commonStore.client.Name} Admin`}</button>
                                </div>

                            </div>
                            {/* FILTER */}
                            <div style={{ display: 'flex', gap: '20px' }}>
                                <div id='All'
                                    className={`admin-dash-filter-item ${clientFilterSelected === 'All' ? 'selected' : null}`}
                                    onClick={handleClientFilterOnClick}>
                                    All
                                </div>
                                <div id='Active'
                                    className={`admin-dash-filter-item ${clientFilterSelected === 'Active' ? 'selected' : null}`}
                                    onClick={handleClientFilterOnClick}>
                                    Active
                                </div>
                                <div id='Pending'
                                    className={`admin-dash-filter-item ${clientFilterSelected === 'Pending' ? 'selected' : null}`}
                                    onClick={handleClientFilterOnClick}>
                                    Pending
                                </div>
                                <div id='Deactivated'
                                    className={`admin-dash-filter-item ${clientFilterSelected === 'Deactivated' ? 'selected' : null}`}
                                    onClick={handleClientFilterOnClick}>
                                    Deactivated
                                </div>
                            </div>
                            {/* LIST */}
                            <div >
                                {/* SEARCH */}
                                <br />
                                <div style={{ display: 'flex', gap: '5px' }}>
                                    <img src={SearchIcon} style={{ width: '20px' }} />
                                    <FormControl style={{ width: '200px' }}>
                                        <InputLabel id="demo-simple-select-label">Filter</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={filter}
                                            label="Filter"
                                            onChange={handleFilterChange}
                                            height='35px'
                                            style={{ fontSize: '13px' }}
                                        >
                                            <MenuItem value={"FirstName"}>First name</MenuItem>
                                            <MenuItem value={"LastName"}>Last name</MenuItem>
                                            <MenuItem value={"IDNumber"}>ID number</MenuItem>
                                            <MenuItem value={"Username"}>Username</MenuItem>
                                            <MenuItem value={"WorkEmail"}>Work Email</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <input className='admin-dash-search-input'
                                        type='search' value={searchField}
                                        onChange={handleOnSearchFieldChange}
                                        placeholder='Search users by ID number/Full name'
                                    />
                                </div>
                                <br />
                                {/* LIST ITEMS */}
                                <div className='admin-dash-users-table-headers'>
                                    <div className='admin-dash-users-table-headers-text left'>FULL NAME</div>
                                    <div style={{ display: 'flex' }}>
                                        <div className='admin-dash-users-table-headers-text right'>
                                            USERNAME
                                        </div>
                                        <div className='admin-dash-users-table-headers-text right'>
                                            STATUS
                                        </div>
                                        <div className='admin-dash-users-table-headers-text right'>
                                            ACTIVATION DATE
                                        </div>
                                    </div>
                                </div>
                                {/* <p>{`Filtered - Showing ${} users`}</p> */}
                                {users.length === 0 ? null :
                                    users.filter(usr => {
                                        if (clientFilterSelected === 'Active' && usr[filter] !== undefined) {
                                            return usr.Confirmed === true && usr[filter].toLowerCase().includes(searchField);
                                        }
                                        else if (clientFilterSelected === 'Pending' && usr[filter] !== undefined) {
                                            return usr.Confirmed === false && usr.Blocked === false && usr[filter].toLowerCase().includes(searchField);
                                        }
                                        else if (clientFilterSelected === 'Deactivated' && usr[filter] !== undefined) {
                                            return usr.Blocked === true && usr[filter].toLowerCase().includes(searchField);
                                        }
                                        else {
                                            if (usr[filter] !== undefined) {
                                                return usr[filter].toLowerCase().includes(searchField);
                                            }
                                            else {
                                                return false
                                            }
                                        }
                                    })
                                        .map((usr, index) => (
                                            <div className='hover' key={usr._id} id={usr._id} onClick={() => handleOnClickUser(usr)}>
                                                <div className='admin-dash-users-table-headers white'>
                                                    <div className='admin-dash-users-table-headers-text left bold'>{usr.FirstName || "Anon"} {usr.LastName || ""}</div>
                                                    <div style={{ display: 'flex' }}>
                                                        <div className='admin-dash-users-table-headers-text right'>
                                                            <div
                                                                className={`admin-dash-users-table-headers-text`}>
                                                                {usr.IDNumber ? usr.IDNumber : usr.Username}
                                                            </div>
                                                        </div>
                                                        <div className='admin-dash-users-table-headers-text right bold'>
                                                            {usr.Blocked === true &&
                                                                <div
                                                                    className={`admin-dash-users-table-headers-text bold ${'deactivated'}`}>
                                                                    {'Deactivated'}
                                                                </div>
                                                            }
                                                            {usr.Confirmed === true && usr.Blocked === false &&
                                                                <div
                                                                    className={`admin-dash-users-table-headers-text bold ${'active'}`}>
                                                                    {'Active'}
                                                                </div>
                                                            }
                                                            {usr.Confirmed === false && usr.Blocked === false &&
                                                                <div
                                                                    className={`admin-dash-users-table-headers-text bold ${'pending'}`}>
                                                                    {'Pending'}
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className='admin-dash-users-table-headers-text right'>
                                                            <div
                                                                className={`admin-dash-users-table-headers-text`}>
                                                                {usr.ConfirmedDate ? usr.ConfirmedDate : 'N/A'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        ))
                                }

                            </div>
                        </main>
                    </main>}
                {visible === 'singular-user' &&
                    <main style={{ background: "#F4F5FA", width: '100%' }}>
                        <header className='admin-dash-option-header'>{`User`}</header>
                        <main style={{ background: "#FFFFFF", width: '95%', borderRadius: '20px', padding: '35px' }}>
                            {/* TOP DIV */}
                            <div style={{ display: 'flex', marginBottom: '20px', gap: '25px', alignItems: 'center' }}>
                                <button className='admin-dash-back-arrow-btn' onClick={() => goBack()}>Back</button>
                                <header className='admin-dash-sub-header' style={{ fontSize: '14px' }}>
                                    <span className='admin-dash-blue-clients-text normal'>
                                        {'User '}</span>{` > ${selectedUser.FirstName || "Anon"} ${selectedUser.LastName || ""}`}
                                </header>
                            </div>
                            <div style={{ display: 'flex', gap: '20%' }}>
                                {/* SALARY */}

                                {!editSalaryVisible && <>
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                        <header className='admin-dash-client-details-text mediumbold'>Nett salary:</header>
                                        <label className='admin-dash-client-details-text small'>
                                            <>
                                                {viewSalary ? (selectedUser.SalaryAmount ? `R${selectedUser.SalaryAmount}` : `Not set`) : "▪▪▪▪▪▪▪"}
                                                <br />
                                                {viewSalary ? (
                                                    <i onClick={() => setViewSalary(false)} style={{ cursor: 'pointer' }} className="fa-regular fa-eye-slash"></i>
                                                ) : (
                                                    <i onClick={() => setViewSalary(true)} style={{ cursor: 'pointer' }} className="fa-regular fa-eye"></i>
                                                )}
                                            </>
                                        </label>
                                    </div>
                                    {userPermissions.includes("edit-users") && !usesPayroll &&
                                        <div style={{ display: 'flex', gap: '8px' }}>
                                            <img src={EditPenBlueIcon} style={{ height: '20px' }} />
                                            <div className='admin-dash-blue-clients-text' style={{ fontSize: '12px' }}
                                                onClick={() => setEditSalaryVisible(true)}>Change salary</div>
                                        </div>
                                    }
                                </>}
                                {editSalaryVisible &&
                                    <div className='flex-column' style={{ gap: '10px' }}>
                                        <header style={{ textAlign: 'left' }}
                                            className='admin-dash-client-details-text mediumbold'>Change Nett salary</header>
                                        <input value={nettSalary}
                                            id='SalaryAmount'
                                            onChange={(e) => { handleOnDetailsChange(e, "nettSalary") }}
                                            className='admin-dash-assess-input'
                                            placeholder='Nett salary'
                                        />
                                        <div style={{ display: 'flex', gap: '8px' }}>
                                            <div className='admin-dash-blue-clients-text' style={{ fontSize: '12px', color: 'black' }}
                                                onClick={() => {
                                                    setEditSalaryVisible(false)
                                                    setNettSalary(selectedUser.SalaryAmount)
                                                }}
                                            >Cancel
                                            </div>
                                        </div>
                                        {
                                            nettSalaryChanged &&
                                            <div style={{ display: 'flex', gap: '8px' }}>
                                                <img src={EditPenBlueIcon} style={{ height: '20px' }} />
                                                <div className='admin-dash-blue-clients-text' style={{ fontSize: '12px' }}
                                                    id='salary'
                                                    onClick={handleUpdateUser}
                                                >Update
                                                </div>
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                            <hr style={{ width: '100%' }} />
                            <div style={{ display: 'flex', gap: '20%' }}>
                                {/* ACTIVE */}
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                    <header className='admin-dash-client-details-text mediumbold'>User status:</header>
                                    <label className='admin-dash-client-details-text small'>
                                        {selectedUser.Confirmed && selectedUser.Blocked === false &&
                                            <>
                                                <span style={{ color: 'blue' }}>Active</span>
                                                <br />
                                                {userPermissions.includes("edit-users") &&
                                                    <label onClick={() => updateUser(selectedUser.Username, { Blocked: true })}
                                                        style={{ color: 'red' }} className='admin-dash-blue-clients-text'>Block user
                                                    </label>
                                                }
                                            </>
                                        }
                                        {selectedUser.Blocked === true &&
                                            <>
                                                <span style={{ color: 'red' }}>
                                                    {selectedUser.EmploymentStatus === "Terminated" ?
                                                        `Terminated - ${selectedUser.TerminationReason || "Unknown Reason"}`
                                                        :
                                                        `Blocked - ${selectedUser.BlockedReason || "Unknown Reason"}`
                                                    }
                                                </span>
                                                <br />
                                                {userPermissions.includes("edit-users") &&
                                                    <label onClick={() => updateUser(selectedUser.Username, { Blocked: false })}
                                                        style={{ color: 'red' }} className='admin-dash-blue-clients-text'>Unblock user
                                                    </label>
                                                }
                                            </>
                                        }
                                        {selectedUser.Confirmed === false && selectedUser.Blocked === false &&
                                            <>
                                                <span style={{ color: 'orange' }}>Pending</span>
                                                <br />
                                                {userPermissions.includes("edit-users") &&
                                                    <label onClick={() => updateUser(selectedUser.Username, { Blocked: true })}
                                                        style={{ color: 'red' }} className='admin-dash-blue-clients-text'>Block user
                                                    </label>
                                                }
                                            </>
                                        }
                                    </label>
                                </div>

                            </div>
                            <hr style={{ width: '100%' }} />
                            {(selectedUser.Role === "62bd55b7c5793941427c702d") &&
                                <>
                                    <div style={{ display: 'flex', gap: '20%' }}>
                                        {/* PERMISSIONS */}
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                            <header className='admin-dash-client-details-text mediumbold'>Permissions</header>
                                            {userPermissions.includes("edit-permissions") &&
                                                <div style={{ display: 'flex', gap: '8px' }}>
                                                    <img src={EditPenBlueIcon} style={{ height: '20px' }} />
                                                    <div className='admin-dash-blue-clients-text' style={{ fontSize: '12px' }}
                                                        onClick={() => setEditVisible("permissions")}>Edit permissions</div>
                                                </div>
                                            }
                                            {selectedUser.Permissions.length > 0 &&
                                                selectedUser.Permissions.map((perm) => (
                                                    <div className='flex gap-1'>
                                                        <label className='admin-dash-client-details-text small'>
                                                            {perm}
                                                        </label>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    <hr style={{ width: '100%' }} />
                                </>}
                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '25%' }}>
                                {/* DETAILS */}
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>

                                    <header className='admin-dash-client-details-text mediumbold'>Personal details</header>
                                    <br />
                                    <div className='flex-column' style={{ gap: '17px' }}>
                                        <div className='flex-column'>
                                            <label className='admin-dash-client-details-text small'>ID/Passport</label>
                                            <div className='admin-dash-client-details-text smallbold'>
                                                {selectedUser?.Username}
                                            </div>
                                        </div>
                                        <div className='flex-column'>
                                            <label className='admin-dash-client-details-text small'>First name</label>
                                            <div className='admin-dash-client-details-text smallbold'>
                                                {userPrimaryDetails.FirstName}
                                            </div>
                                        </div>
                                        <div className='flex-column'>
                                            <label className='admin-dash-client-details-text small'>Last name</label>
                                            <div className='admin-dash-client-details-text smallbold'>
                                                {userPrimaryDetails.LastName}
                                            </div>
                                        </div>
                                        <div className='flex-column'>
                                            <label className='admin-dash-client-details-text small'>Work email</label>
                                            <div className='admin-dash-client-details-text smallbold'>
                                                {userPrimaryDetails.WorkEmail}
                                            </div>
                                        </div>

                                        <div className='flex-column'>
                                            <label className='admin-dash-client-details-text small'>Mobile number</label>
                                            <div className='admin-dash-client-details-text smallbold'>
                                                {userPrimaryDetails.MobileNumber}
                                            </div>
                                        </div>
                                        <div className='flex-column'>
                                            <label className='admin-dash-client-details-text small'>Payroll information</label>
                                            <div className='admin-dash-client-details-text smallbold'>
                                                {selectedUser?.PayrollInformation?.EmployeeId}
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    {userPermissions.includes("edit-users") &&
                                        <div style={{ display: 'flex', gap: '8px' }}>
                                            <img src={EditPenBlueIcon} style={{ height: '20px' }} />
                                            <div className='admin-dash-blue-clients-text' style={{ fontSize: '12px' }}
                                                onClick={() => setEditVisible("personal")}>Edit personal details</div>
                                        </div>}
                                    <br />

                                </div>
                                {/* ADDRESS */}
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>

                                    <header className='admin-dash-client-details-text mediumbold'>Physical address</header>
                                    <br />
                                    <div className='flex-column' style={{ gap: '17px' }}>
                                        <div className='flex-column'>
                                            <label className='admin-dash-client-details-text small'>Address line 1</label>
                                            <div className='admin-dash-client-details-text smallbold'>
                                                {userAddress.Line1}
                                            </div>
                                        </div>
                                        <div className='flex-column'>
                                            <label className='admin-dash-client-details-text small'>Address line 2</label>
                                            <div className='admin-dash-client-details-text smallbold'>
                                                {userAddress.Line2}
                                            </div>
                                        </div>
                                        <div className='flex-column'>
                                            <label className='admin-dash-client-details-text small'>Address line 3</label>
                                            <div className='admin-dash-client-details-text smallbold'>
                                                {userAddress.Line3}
                                            </div>
                                        </div>
                                        <div className='flex-column'>
                                            <label className='admin-dash-client-details-text small'>Town/City</label>
                                            <div className='admin-dash-client-details-text smallbold'>
                                                {userAddress.City}
                                            </div>
                                        </div>
                                        <div className='flex-column'>
                                            <label className='admin-dash-client-details-text small'>Province</label>
                                            <div className='admin-dash-client-details-text smallbold'>
                                                {userAddress.Province}
                                            </div>
                                        </div>
                                        <div className='flex-column'>
                                            <label className='admin-dash-client-details-text small'>Postal code</label>
                                            <div className='admin-dash-client-details-text smallbold'>
                                                {userAddress.PostalCode}
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    {userPermissions.includes("edit-users") && !usesPayroll &&
                                        <div style={{ display: 'flex', gap: '8px' }}>
                                            <img src={EditPenBlueIcon} style={{ height: '20px' }} />
                                            <div className='admin-dash-blue-clients-text' style={{ fontSize: '12px' }}
                                                onClick={() => setEditVisible("address")}>Edit address</div>
                                        </div>}
                                    <br />

                                </div>
                            </div>
                            <hr style={{ width: '100%' }} />
                            {/* BANKACCOUNT */}
                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '25%' }}>
                                {/* PRIMARY */}
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>

                                    <header className='admin-dash-client-details-text mediumbold'>Primary bank account</header>
                                    <br />
                                    <div className='flex-column' style={{ gap: '17px' }}>
                                        <div className='flex-column'>
                                            <label className='admin-dash-client-details-text small'>Type</label>
                                            <div className='admin-dash-client-details-text smallbold'>
                                                {primaryBankAccount.Type}
                                            </div>
                                        </div>
                                        <div className='flex-column'>
                                            <label className='admin-dash-client-details-text small'>Bank</label>
                                            <div className='admin-dash-client-details-text smallbold'>
                                                {primaryBankAccount.BankName}
                                            </div>
                                        </div>
                                        <div className='flex-column'>
                                            <label className='admin-dash-client-details-text small'>Account number</label>
                                            <div className='admin-dash-client-details-text smallbold'>
                                                {primaryBankAccount.AccountNumber}
                                            </div>
                                        </div>
                                        <div className='flex-column'>
                                            <label className='admin-dash-client-details-text small'>Branch code</label>
                                            <div className='admin-dash-client-details-text smallbold'>
                                                {primaryBankAccount.BranchCode}
                                            </div>
                                        </div>
                                        <div className='flex-column'>
                                            <label className='admin-dash-client-details-text small'>Branch name</label>
                                            <div className='admin-dash-client-details-text smallbold'>
                                                {primaryBankAccount.BranchName}
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    {userPermissions.includes("edit-users") && !usesPayroll &&
                                        <div style={{ display: 'flex', gap: '8px' }}>
                                            <img src={EditPenBlueIcon} style={{ height: '20px' }} />
                                            <div className='admin-dash-blue-clients-text' style={{ fontSize: '12px' }}
                                                onClick={() => setEditVisible("primary-bank")}>Edit primary bank</div>
                                        </div>}
                                    <br />

                                </div>
                                {/* SECONDARY */}
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>

                                    <header className='admin-dash-client-details-text mediumbold'>Secondary bank account</header>
                                    <br />
                                    <div className='flex-column' style={{ gap: '17px' }}>
                                        <div className='flex-column'>
                                            <label className='admin-dash-client-details-text small'>Type</label>
                                            <div className='admin-dash-client-details-text smallbold'>
                                                {secondaryBankAccount.Type}
                                            </div>
                                        </div>
                                        <div className='flex-column'>
                                            <label className='admin-dash-client-details-text small'>Bank</label>
                                            <div className='admin-dash-client-details-text smallbold'>
                                                {secondaryBankAccount.BankName}
                                            </div>
                                        </div>
                                        <div className='flex-column'>
                                            <label className='admin-dash-client-details-text small'>Account number</label>
                                            <div className='admin-dash-client-details-text smallbold'>
                                                {secondaryBankAccount.AccountNumber}
                                            </div>
                                        </div>
                                        <div className='flex-column'>
                                            <label className='admin-dash-client-details-text small'>Branch code</label>
                                            <div className='admin-dash-client-details-text smallbold'>
                                                {secondaryBankAccount.BranchCode}
                                            </div>
                                        </div>
                                        <div className='flex-column'>
                                            <label className='admin-dash-client-details-text small'>Branch name</label>
                                            <div className='admin-dash-client-details-text smallbold'>
                                                {secondaryBankAccount.BranchName}
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    {userPermissions.includes("edit-users") &&
                                        <div style={{ display: 'flex', gap: '8px' }}>
                                            <img src={EditPenBlueIcon} style={{ height: '20px' }} />
                                            <div className='admin-dash-blue-clients-text' style={{ fontSize: '12px' }}
                                                onClick={() => setEditVisible("sec-bank")}>Edit secondary bank</div>
                                        </div>}
                                    <br />

                                </div>
                            </div>
                        </main>
                    </main>
                }

            </div>
        );
    }
})

export default ClientAdminEmployees
