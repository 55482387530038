import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { isMobile } from 'is-mobile';
import { Link } from 'react-router-dom';
import { Button, Navbar } from '../../components/basic/index';
import AdminClient from './client';
import AdminUser from './user';
import AdminBenefits from './benefits';
import AdminReporting from './reporting'
import {
    LevelBlueWhiteLogo,
    SideBarOverviewIcon,
    SideBarBenefitsIcon,
    SideBarSettingsIcon,
    SideBarUsersIcon,
    SideBarClientsIcon,
    SideBarReportsIcon,
    GreyProfileIcon,
    DropDownArrowDown,
    LogoutIcon

} from '../../assets/icons'
import NonResponsivePage from '../nonResponsive';

import './styles.scss';

const AdminHome = observer(() => {

    let navigate = useNavigate();
    const commonStore = useStore();
    const { section } = useParams();

    const [sideBarOptionClicked, setSideBarOptionClicked] = useState(section ? section : "Overview")
    const [userPermissions, setUserPermissions] = useState(commonStore.user.Permissions || [])
    const [ozowBalance, setOzowBalance] = useState(0)

    useEffect(() => {
        if (section) {
            setSideBarOptionClicked(section)
        }
    }, [section])

    useEffect(() => {
        getOzowBalance()
    }, [])

    const getOzowBalance = async () => {
        let res = await commonStore.getOzowBalance()
        console.log(res)
        if (res) {
            setOzowBalance(res)
        }
        else commonStore.setSnackBar('error', "Error retrieving ozow float balance")
    }

    const handleSideBarOptionsOnClick = (e) => {
        const selectedSection = e.target.id
        navigate(`/admin/home/${selectedSection}`)
    }

    if (isMobile()) {
        return (
            <div>
                <NonResponsivePage />
            </div>
        );
    } else {
        return (
            <div>
                {/* TOP BAR */}
                <Navbar />
                <div style={{ display: "flex" }}>
                    {/* SIDE BAR */}
                    <div id="admin-dash-sidebar">
                        <section className='admin-dash-sidebar-container'>
                            <div className='admin-dash-sidebar-container-items-container'>
                                <div style={{ display: "flex", gap: '8px', alignItems: 'center' }}>
                                    <img src={SideBarOverviewIcon} style={{ filter: sideBarOptionClicked === 'Overview' ? 'brightness(0) saturate(100%)' : 'none' }} />
                                    <div onClick={handleSideBarOptionsOnClick}
                                        id='Overview'
                                        className={`admin-dash-sidebar-text ${sideBarOptionClicked === 'Overview' ? 'bold' : null}`}
                                    >Overview</div>
                                </div>
                                {userPermissions.includes("view-benefits") &&
                                <div style={{ display: "flex", gap: '8px', alignItems: 'center' }}>
                                    <img src={SideBarBenefitsIcon} style={{ filter: sideBarOptionClicked === 'Benefits' ? 'brightness(0) saturate(100%)' : 'none' }} />
                                    <div onClick={handleSideBarOptionsOnClick}
                                        id='Benefits'
                                        className={`admin-dash-sidebar-text ${sideBarOptionClicked === 'Benefits' ? 'bold' : null}`}
                                    >Benefits</div>
                                </div>}
                                {userPermissions.includes("view-users") &&
                                    <div style={{ display: "flex", gap: '8px', alignItems: 'center' }}>
                                        <img src={SideBarUsersIcon} style={{ filter: sideBarOptionClicked === 'Users' ? 'brightness(0) saturate(100%)' : 'none' }} />
                                        <div onClick={handleSideBarOptionsOnClick}
                                            id='Users'
                                            className={`admin-dash-sidebar-text ${sideBarOptionClicked === 'Users' ? 'bold' : null}`}
                                        >Users</div>
                                    </div>
                                }
                                {userPermissions.includes("view-clients") &&
                                    <div style={{ display: "flex", gap: '8px', alignItems: 'center' }}>
                                        <img src={SideBarClientsIcon} style={{ filter: sideBarOptionClicked === 'Clients' ? 'brightness(0) saturate(100%)' : 'none' }} />
                                        <div onClick={handleSideBarOptionsOnClick}
                                            id='Clients'
                                            className={`admin-dash-sidebar-text ${sideBarOptionClicked === 'Clients' ? 'bold' : null}`}
                                        >Clients</div>
                                    </div>
                                }
                                {userPermissions.includes("view-reports") &&
                                    <div style={{ display: "flex", gap: '8px', alignItems: 'center' }}>
                                    <img src={SideBarReportsIcon} style={{ filter: sideBarOptionClicked === 'Reports' ? 'brightness(0) saturate(100%)' : 'none' }} />
                                    <div onClick={handleSideBarOptionsOnClick}
                                        id='Reports'
                                        className={`admin-dash-sidebar-text ${sideBarOptionClicked === 'Reports' ? 'bold' : null}`}
                                    >Reports</div>
                                </div>}
                                <div style={{ display: "flex", gap: '8px', alignItems: 'center' }}>
                                    <img src={LogoutIcon} />
                                    <div onClick={() => { commonStore.logout(); navigate('/signin'); }}
                                        id='Reports'
                                        className={`admin-dash-sidebar-text`}
                                    >Logout</div>
                                </div>
                            </div>

                        </section>
                    </div>
                    {/* MAIN SECTION */}
                    {sideBarOptionClicked === 'Overview' &&
                        <div style={{ height: '90vh', width: '100%', overflowY: 'scroll' }}>
                            <main>
                                <p className='text-[30px]'>{ozowBalance}</p>
                            </main>
                        </div>
                    }
                    {sideBarOptionClicked === 'Clients' &&
                        <div style={{ height: '90vh', width: '100%', overflowY: 'scroll' }}>
                            <AdminClient />
                        </div>
                    }
                    {sideBarOptionClicked === 'Users' &&
                        <div style={{ height: '90vh', width: '100%', overflowY: 'scroll' }}>
                            <AdminUser />
                        </div>
                    }
                    {sideBarOptionClicked === 'Benefits' &&
                        <div style={{ height: '90vh', width: '100%', overflowY: 'scroll' }}>
                            <AdminBenefits />
                        </div>
                    }
                    {sideBarOptionClicked === 'Reports' &&
                        <div style={{ height: '90vh', width: '100%', overflowY: 'scroll' }}>
                            <AdminReporting />
                        </div>
                    }
                </div>

            </div>
        );
    }
})

export default AdminHome
