import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import AdminBenefitsReport from '../benefitsReport';
import { Navbar, Input } from '../../../components/basic/index'
import { AutohideSnackbar } from '../../../components/MUI';
import { permissions } from '../../../components/index'
import allBenefits from '../../../components/benefits';

import { PinkCheckCircle, BlueAddIcon, SageIcon, SimplePayIcon, LabourNetIcon, PaySpaceIcon, SearchIcon, EditPenBlueIcon } from '../../../assets/icons/index'

import './styles.scss';
const XLSX = require('xlsx');

const AdminClient = observer(() => {

    let navigate = useNavigate();
    const commonStore = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const chartRef = useRef();

    const [clients, setClients] = useState([]);
    const [clientFilterSelected, setClientFilterSelected] = useState('All')
    const [selectedClient, setSelectedClient] = useState(null)
    const [visible, setVisible] = useState("home")
    const [visible2, setVisible2] = useState("")
    const [assessmentVisible, setAssessmentVisible] = useState(false)
    const [selectedAssessment, setSelectedAssessment] = useState(null)
    const [selectedTempAssessment, setSelectedTempAssessment] = useState(null)
    const [assessmentChanged, setAssessmentChanged] = useState(false)

    const [payrollStatusChanged, setPayrollStatusChanged] = useState(false)
    const [companyPrimaryDetails, setCompanyPrimaryDetails] = useState(null)
    const [oGCompanyPrimaryDetails, setOGCompanyPrimaryDetails] = useState(null)
    const [companyPrimaryDetailsChanged, setCompanyPrimaryDetailsChanged] = useState(false)
    const [companyAddress, setCompanyAddress] = useState(null)
    const [ogCompanyAddress, setOGCompanyAddress] = useState(null)
    const [companyAddressChanged, setcompanyAddressChanged] = useState(false)
    const [payrollStatus, setPayrollStatus] = useState("N/A")
    const [activeStatus, setActiveStatus] = useState(false)
    const [activeStatusChanged, setActiveStatusChanged] = useState(false)
    const [newClient, setNewClient] = useState(null)
    //PAYROLL
    const [clicked, setClicked] = useState('');
    //sage
    const [sageUsername, setSageUsername] = useState('');
    const [sagePassword, setSagePassword] = useState('');
    const [sageApiKey, setSageApiKey] = useState('');
    //simple pay
    const [simplePayApiKey, setSimplePayApiKey] = useState('');
    const [simplePayClientId, setSimplePayClientId] = useState('');
    //pay space
    const [paySpaceClientId, setPaySpaceClientId] = useState('');
    const [paySpaceApiKey, setPaySpaceApiKey] = useState('');
    const [paySpaceScope, setPaySpaceScope] = useState('');
    const [paySpaceCompanyId, setPaySpaceCompanyId] = useState('');
    //labournet
    const [labourNetUsername, setLabourNetUsername] = useState('');
    const [labourNetPassword, setLabourNetPassword] = useState('');
    const [labourNetInstance, setLabourNetInstance] = useState('');
    const [labourNetPayrollRegNo, setLabourNetPayrollRegNo] = useState('');
    const [labourNetInterfaceCode, setLabourNetInterfaceCode] = useState('');

    const [accountHolder, setAccountHolder] = useState({})
    const [newClientAddress, setNewClientAddress] = useState({})
    const [employerAdmin, setEmployerAdmin] = useState({})

    const [searchField, setSearchField] = useState('');
    const [editVisible, setEditVisible] = useState("")
    const [assessLinkCopied, setAssessLinkCopied] = useState(false)
    const [activationLinkCopied, setActivationLinkCopied] = useState(false)
    const [activationLink, setActivationLink] = useState("")

    const [benefitsReport, setBenefitsReport] = useState(null)

    const [benefitsSelected, setBenefitsSelected] = useState([])

    const [employerLegalsSigned, setEmployerLegalsSigned] = useState(false)
    const [userPermissions, setUserPermissions] = useState(commonStore.user.Permissions || [])

    const [snackBarOpen, setSnackBarOpen] = useState(false)
    const [snackBarType, setSnackBarType] = useState("")
    const [snackBarContent, setSnackBarContent] = useState("")

    const [newAdminUser, setNewAdminUser] = useState(null)
    const [newAdminPermissionsChecked, setNewAdminPermissionsChecked] = useState([])

    const emptyAddressObject = {
        Line1: "",
        Line2: "",
        Line3: "",
        City: "",
        Province: "",
        PostalCode: ""
    }

    useEffect(() => {
        reset()
        getClients()

    }, [])

    const reset = () => {
        setNewAdminPermissionsChecked([])
        setNewAdminUser(null)
        setAssessLinkCopied(false)
        setActivationLinkCopied(false)
        setActivationLink("")
        setClientFilterSelected('All');
        setOGCompanyAddress(null)
        setEditVisible("")
        setSelectedClient(null);
        setVisible("home");
        setVisible2("")
        setAssessmentVisible(false);
        setSelectedAssessment(null);
        setSelectedTempAssessment(null);
        setAssessmentChanged(false);
        setPayrollStatusChanged(false);
        setCompanyPrimaryDetails(null);
        setOGCompanyPrimaryDetails(null);
        setCompanyPrimaryDetailsChanged(false);
        setCompanyAddress(null);
        setcompanyAddressChanged(false);
        setPayrollStatus('N/A');
        setActiveStatus(false);
        setActiveStatusChanged(false);
        setNewClient(null);
        setClicked('');
        // Payroll
        setSageUsername('');
        setSagePassword('');
        setSageApiKey('');
        setSimplePayApiKey('');
        setSimplePayClientId('');
        setPaySpaceClientId('');
        setPaySpaceApiKey('');
        setPaySpaceScope('');
        setPaySpaceCompanyId('');
        setLabourNetUsername('');
        setLabourNetPassword('');
        setLabourNetInstance('');
        setLabourNetPayrollRegNo('');
        setLabourNetInterfaceCode('');
        // Account Holder
        setAccountHolder({});
        setNewClientAddress({})
        setEmployerAdmin({})
        setBenefitsSelected([])
        forceUpdate()

    };

    const resetSnackBar = () => {
        setSnackBarOpen(false)
        setSnackBarType("")
        setSnackBarContent("")
    }

    const handleOnSearchFieldChange = (e) => {

        let val = e.currentTarget.value
        setSearchField(val.toLowerCase())
    }

    const getClients = async () => {
        commonStore.setLoading(true)
        try {
            let ret = await commonStore.getAllClients()
            if (ret === "No clients found") {
                setClients([])
            }
            else {
                setClients(ret)
            }
        }
        catch (e) {
            console.error(e)
        }
        finally {
            commonStore.setLoading(false)

        }

    }

    const goBack = () => {
        reset()
        getClients()
    }

    // Sage
    const handleSageUsernameOnChange = (value) => {
        setSageUsername(value);
    };

    const handleSagepasswordOnChange = (value) => {
        setSagePassword(value);
    };

    const handleSageApiKeyOnChange = (value) => {
        setSageApiKey(value);
    };

    // Simple Pay
    const handleSimplePayKeyOnChange = (value) => {
        setSimplePayApiKey(value);
    };

    // LabourNet
    const handleLabourNetUsernameOnChange = (value) => {
        setLabourNetUsername(value);
    };

    const handleLabourNetPasswordOnChange = (value) => {
        setLabourNetPassword(value);
    };

    const handleLabourNetInstanceOnChange = (value) => {
        setLabourNetInstance(value);
    };
    // PaySpace
    const handlePaySpaceKeyOnChange = (value) => {
        setPaySpaceApiKey(value);
    };

    const handlePayClientIdOnChange = (value) => {
        setPaySpaceClientId(value);
    };

    const handlePayScopeOnChange = (value) => {
        setPaySpaceScope(value);
    };

    const handleOnProviderClick = (value) => {

        if (clicked === value) {
            setClicked("")
            return
        }
        setClicked(value)
    }

    const handleOnAccountHolderChange = (e) => {

        console.log('triggered ac')
        let value = e.currentTarget.value
        let id = e.currentTarget.id

        let curr = accountHolder
        console.log('account holder value', accountHolder)
        curr[id] = value

        forceUpdate()
    }
    const handleOnNewClientAddressChange = (e) => {

        console.log('triggered addy')
        let value = e.currentTarget.value
        let id = e.currentTarget.id

        let curr = newClientAddress
        console.log('address value', newClientAddress)
        curr[id] = value

        forceUpdate()
    }
    const handleNewClientChange = (e) => {

        let id = e.currentTarget.id
        let value = e.currentTarget.value

        let curr = newClient
        curr[id] = value

        setNewClient(curr)
        forceUpdate()
    }

    const handleClientFilterOnClick = (e) => {

        setClientFilterSelected(e.target.id)
    }

    const handleCreateClient = async () => {

        let curr = newClient

        curr.PayDate = parseInt(curr.PayDate)
        curr.CutOffDate = parseInt(curr.CutOffDate)
        curr.Address = newClientAddress

        if (clicked === 'sage') {
            curr.PayrollInformation = {
                Provider: "Sage 300",
                PayrollRunDefinition: "LEVEL_FINANCE",
                ApiKey: sageApiKey,
                Username: sageUsername,
                Password: sagePassword,
                ApiUri: "https://online.sage.co.za/W02493"
            }
        }

        if (clicked === 'simple') {
            curr.PayrollInformation = {
                Provider: "Simple pay",
                ApiKey: simplePayApiKey,
                ClientId: simplePayClientId,
                ApiUri: "https://api.payroll.simplepay.cloud/v1"
            }
        }

        if (clicked === 'payspace') {
            curr.PayrollInformation = {
                Provider: "Pay space",
                ApiKey: paySpaceApiKey,
                ClientId: paySpaceClientId,
                Scope: paySpaceScope,
                CompanyId: paySpaceCompanyId,
                ApiUri: "https://api.payroll.simplepay.cloud/v1"
            }
        }

        if (clicked === 'labour') {
            curr.PayrollInformation = {
                Provider: "Labour net",
                Username: labourNetUsername,
                Password: labourNetPassword,
                Instance: labourNetInstance,
                PayrollRegNo: labourNetPayrollRegNo,
                InterfaceCode: labourNetInterfaceCode
            }
        }

        let client = curr

        let user = accountHolder
        //add all permissions
        user.Permissions = permissions.employerAdminPermissions.map(item => item.id)
        client.Source = `Created by ${commonStore.user.Username}`
        debugger
        if (!client.Name || !client.PayDate || !client.CutOffDate || !client.VATNo || !client.RegCode) {
            alert("Please enter all required all client details")
            return
        }

        if (client.CutOffDate > 31 || client.PayDate < 1 || client.PayDate > 31 || client.CutOffDate < 1) {
            alert("Dates should be between 1 to 31")
            return
        }

        if (!user.WorkEmail || !user.FirstName) {
            alert("Please enter all required account holder details")
            return
        }

        if (Object.keys(newClientAddress).length === 0) {
            alert("Please enter company address")
            return
        }
        if (!newClientAddress.Line1 || !newClientAddress.Province || !newClientAddress.City) {
            alert("Please enter all required address details")
            return
        }

        console.log(client)
        console.log(user)

        try {
            commonStore.setLoading(true)
            let res = await commonStore.createClient(client, user)
            console.log(res)
            if (res) {
                commonStore.setLoading(false)
                if (res.clientCreated) {
                    if (res.userCreated) {
                        setSnackBarType("success")
                        setSnackBarContent("Client created")
                        setSnackBarOpen(true)
                        reset()
                        getClients()
                    }
                    else {
                        setSnackBarType("success")
                        setSnackBarContent("Client created, but account holder email already exists/ or error occured creating user")
                        setSnackBarOpen(true)
                        reset()
                        getClients()
                    }
                }
            }
            else {
                commonStore.setLoading(false)
                setSnackBarType("error")
                setSnackBarContent("Error occured, please try again")
                setSnackBarOpen(true)
                reset()
                getClients()
            }
        } catch (error) {
            console.error(error)
            commonStore.setLoading(false)
            setSnackBarType("error")
            setSnackBarContent("Error occured, please try again")
            setSnackBarOpen(true)
            reset()
            getClients()
        }
    }

    const handleOnClickClient = async (object) => {

        if (!userPermissions.includes("view-clients")) {
            setSnackBarType("error")
            setSnackBarContent("You are not permitted to perform this action")
            setSnackBarOpen(true)
            return
        }
        setSelectedClient(object)
        setPayrollStatus(object.Status ? object.Status : "N/A")

        setCompanyPrimaryDetails({
            Name: object.Name || "",
            CutOffDate: object.CutOffDate || "",
            PayDate: object.PayDate || "",
            VATNo: object.VATNo || "",
            RegCode: object.RegCode || ""

        })

        setOGCompanyPrimaryDetails({
            Name: object.Name || "",
            CutOffDate: object.CutOffDate || "",
            PayDate: object.PayDate || "",
            VATNo: object.VATNo || "",
            RegCode: object.RegCode || ""
        })
        //address
        if (object.Address) {
            setCompanyAddress({
                Line1: object.Address.Line1 || "",
                Line2: object.Address.Line2 || "",
                Line3: object.Address.Line3 || "",
                City: object.Address.City || "",
                Province: object.Address.Province || "",
                PostalCode: object.Address.PostalCode || ""
            })

            setOGCompanyAddress({
                Line1: object.Address.Line1 || "",
                Line2: object.Address.Line2 || "",
                Line3: object.Address.Line3 || "",
                City: object.Address.City || "",
                Province: object.Address.Province || "",
                PostalCode: object.Address.PostalCode || ""
            })

        }
        else {
            //pointer issue, have to set it like this
            setCompanyAddress(JSON.parse(JSON.stringify(emptyAddressObject)))
            setOGCompanyAddress(JSON.parse(JSON.stringify(emptyAddressObject)))
        }

        setActiveStatus(object.Active || false)

        setEmployerAdmin(object.AccountHolder ||
            {}
        )
        //get report
        if (object.BenefitsAssessment) {

            if (object.BenefitsAssessment.OptToSendToEmployees) {
                commonStore.setLoading(true)
                commonStore.getJointBenefitsReport(object._id).then((ret) => {
                    commonStore.setLoading(false)
                    if (!ret) {
                        setSnackBarType("error")
                        setSnackBarContent("Error retrieving benefits report")
                        setSnackBarOpen(true)
                        return
                    }
                    commonStore.setLoading(false)
                    setBenefitsReport(ret)
                })
            }
        }
        //products loaded
        if (object.AvailableProducts.length > 0) {
            let curr = object.AvailableProducts.map((item) => { return item.id })
            console.log("Loaded", curr)
            setBenefitsSelected(curr)
        }

        setVisible("singular-employer")
        forceUpdate()
    }

    const handleSetAssessmentVisible = (assess) => {

        if (assessmentVisible) {
            setSelectedAssessment(null);
            setSelectedTempAssessment(null);
            setAssessmentVisible(false);
            return;
        }
        setSelectedAssessment(assess);
        setSelectedTempAssessment(JSON.parse(JSON.stringify(assess)));
        //Apparently if you set them to the same object reference they become references to the same object in memory
        setAssessmentVisible(true);
        forceUpdate()
    }


    const handleAssessmentAnswerOnChange = (e, question) => {

        e.preventDefault()
        let curr = selectedTempAssessment
        let answer = e.currentTarget.value
        curr[question].answer = answer
        setSelectedTempAssessment(curr)
        forceUpdate()

        //Detect changes from original changes
        let temp = selectedTempAssessment
        if (!selectedAssessment["Question1"]) {
            delete temp["Question1"]
        }
        if (!selectedAssessment["Question2"]) {
            delete temp["Question2"]
        }
        if (JSON.stringify(selectedAssessment) === JSON.stringify(selectedTempAssessment)) {
            setAssessmentChanged(false)
        }
        else {
            setAssessmentChanged(true)
        }

    }

    const handleUpdateClient = async (e) => {

        commonStore.setLoading(true)
        let id = e.currentTarget.id
        let updates = {}

        switch (id) {
            case "payrollStatus":
                updates = {
                    Status: payrollStatus
                }
                break
        }
        switch (id) {
            case "companyDetails":
                updates = {
                    Name: companyPrimaryDetails.Name || selectedClient.Name,
                    CutOffDate: parseInt(companyPrimaryDetails.CutOffDate) || selectedClient.CutOffDate,
                    RegCode: companyPrimaryDetails.RegCode || selectedClient.RegCode,
                    VATNo: companyPrimaryDetails.VATNo || selectedClient.VATNo,
                    PayDate: parseInt(companyPrimaryDetails.PayDate) || selectedClient.PayDate,
                }
                break

            case "companyAddress":
                updates = {
                    Address: {
                        Line1: companyAddress.Line1 || "",
                        Line2: companyAddress.Line2 || "",
                        Line3: companyAddress.Line3 || "",
                        City: companyAddress.City || "",
                        Province: companyAddress.Province || "",
                        PostalCode: companyAddress.PostalCode || ""
                    }
                }
                break

            case "activeStatus":
                updates = {
                    Active: activeStatus
                }
                break
        }

        commonStore.updateClientById(selectedClient._id, updates).then((res) => {

            if (res._id === selectedClient._id) {
                commonStore.setLoading(false)
                setSnackBarType("success")
                setSnackBarContent("Client successfully updated")
                setSnackBarOpen(true)
                reset()
                getClients()
            }
            else {
                commonStore.setLoading(false)
                setSnackBarType("error")
                setSnackBarContent("Client udpate error occured")
                setSnackBarOpen(true)
                reset()
                getClients()
            }
        })
    }

    const handleSubmitAssessment = async () => {

        commonStore.setLoading(true)
        let temp = selectedClient.BenefitsAssessment
        temp.EmployerAssessment = selectedTempAssessment
        let updates = {
            BenefitsAssessment: temp
        }

        commonStore.updateClientById(selectedClient._id, updates).then((res) => {

            if (res._id === selectedClient._id) {
                commonStore.setLoading(false)
                setSnackBarType("success")
                setSnackBarContent("Assessment submitted")
                setSnackBarOpen(true)
                reset()
                getClients()
            }
            else {
                commonStore.setLoading(false)
                setSnackBarType("error")
                setSnackBarContent("Error submitting assessment")
                setSnackBarOpen(true)
                reset()
                getClients()
            }
        })
    }

    const handleOnDetailsChange = (e, type) => {

        let id = e.currentTarget.id
        let value = e.currentTarget.value

        switch (type) {

            case "active":
                setActiveStatus(e.currentTarget.checked)
                let actv = selectedClient.Active || false
                if (e.currentTarget.checked == actv) {
                    setActiveStatusChanged(false)
                }
                else {
                    setActiveStatusChanged(true)
                }
                break
            case "companyDetails":
                let temp = companyPrimaryDetails
                temp[id] = value
                setCompanyPrimaryDetails(temp)

                if (JSON.stringify(oGCompanyPrimaryDetails) === JSON.stringify(temp)) {
                    setCompanyPrimaryDetailsChanged(false)
                }
                else {
                    setCompanyPrimaryDetailsChanged(true)
                }
                forceUpdate()
                break
            case "address":
                let curr = companyAddress
                curr[id] = value
                setCompanyAddress(curr)

                if (selectedClient.Address) {
                    if (JSON.stringify(selectedClient.Address) === JSON.stringify(curr)) {
                        setcompanyAddressChanged(false)
                    }
                    else {
                        setcompanyAddressChanged(true)
                    }
                }
                else {
                    if (JSON.stringify(emptyAddressObject) === JSON.stringify(curr)) {
                        setcompanyAddressChanged(false)
                    }
                    else {
                        setcompanyAddressChanged(true)
                    }
                }
                forceUpdate()
                break
        }


        forceUpdate()
    }

    const handleOnCreateAssessmentClick = () => {


        navigate({ pathname: `/client/benefits/assessments`, search: selectedClient._id })
    }

    const handleAddClientOnClick = () => {

        //
        setVisible("add-client")
        setNewClient({
            Name: "",
            VATNo: '',
            PayDate: 27,
            CutOffDate: 22,
            PayrollInformation: {},
            ActivatedDate: "",
            AvailableProducts: [],
            RegCode: '',
        })
        forceUpdate()
    }

    const handleAddBenefit = () => {
        setVisible2("add-benefit")
    }

    const getBenefitsReport = () => {


        if (selectedClient.BenefitsAssessment.EmployeeAssessments) {
            if (selectedClient.BenefitsAssessment.EmployeeAssessments.length > 0) {
                //convert to excel
                //EMPLOYER OG
                let employerA = selectedClient.BenefitsAssessment.EmployerAssessment
                let employerOGarray = []
                let employerOGobject = {}

                //additional fields for excel report to match
                //add first 2 questions if empty
                if (!employerA["Question1"]) {
                    employerOGobject["Please provide your full company name"] = ""
                }
                if (!employerA["Question2"]) {
                    employerOGobject["Please provide your role within the company"] = ""
                }

                Object.keys(employerA).filter((k) => { return k !== "DateTaken" })
                    .map((item) => {
                        let key = employerA[item].question
                        let value = employerA[item].answer
                        if (Array.isArray(value)) {
                            employerOGobject[key] = value.join(";")
                        }
                        else {
                            employerOGobject[key] = value
                        }

                    })

                employerOGarray.push(employerOGobject)
                console.log(employerOGarray)

                const employerOGsheet = XLSX.utils.json_to_sheet(employerOGarray)
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, employerOGsheet, "Employer OG Survey");

                //EMPLOYEES OG
                let employeeA = selectedClient.BenefitsAssessment.EmployeeAssessments
                let employeeOGarray = []

                employeeA.map((item, index) => {
                    let employeeOGobject = {}
                    Object.keys(item).map((keys) => {
                        employeeOGobject["ID"] = index + 1
                        employeeOGobject["Start time"] = ""
                        employeeOGobject["Completion time"] = item.Submitted
                        employeeOGobject["Email"] = "anonymous"
                        employeeOGobject["Name"] = ""
                        employeeOGobject["Last modified time"] = "anonymous"
                        let key, value
                        if (keys === "FirstName" || keys === "LastName" || keys === "WorkEmail" || keys === "MobileNumber") {
                            key = keys
                            value = item[keys]
                        }

                        else {
                            key = item[keys].question
                            value = item[keys].answer
                        }

                        if (Array.isArray(value)) {
                            employeeOGobject[key] = value.join(";")
                        }
                        else {
                            employeeOGobject[key] = value
                        }

                    })
                    employeeOGarray.push(employeeOGobject)
                })

                const employeeOGsheet = XLSX.utils.json_to_sheet(employeeOGarray)
                XLSX.utils.book_append_sheet(workbook, employeeOGsheet, "OG Employee Survey");

                XLSX.writeFile(workbook, `${selectedClient.Name} Benefits Report.xlsx`, { compression: true });

                return

            }
        }
        else {
            //convert to excel
            //EMPLOYER OG
            let employerA = selectedClient.BenefitsAssessment.EmployerAssessment
            let employerOGarray = []
            let employerOGobject = {}

            //additional fields for excel report to match
            //add first 2 questions if empty
            if (!employerA["Question1"]) {
                employerOGobject["Please provide your full company name"] = ""
            }
            if (!employerA["Question2"]) {
                employerOGobject["Please provide your role within the company"] = ""
            }

            Object.keys(employerA).filter((k) => { return k !== "DateTaken" })
                .map((item) => {
                    let key = employerA[item].question
                    let value = employerA[item].answer
                    if (Array.isArray(value)) {
                        employerOGobject[key] = value.join(";")
                    }
                    else {
                        employerOGobject[key] = value
                    }

                })

            employerOGarray.push(employerOGobject)
            console.log(employerOGarray)

            const employerOGsheet = XLSX.utils.json_to_sheet(employerOGarray)
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, employerOGsheet, "Employer OG Survey");

            XLSX.writeFile(workbook, `${selectedClient.Name} Benefits results.xlsx`, { compression: true });

            return
        }
    }

    const resetEditSection = () => {

        setEditVisible("")
        setCompanyPrimaryDetails((prev => ({ ...prev, ...oGCompanyPrimaryDetails })))
        setCompanyPrimaryDetailsChanged(false)
        setCompanyAddress(prev => ({ ...prev, ...ogCompanyAddress }))
        setcompanyAddressChanged(false)
        forceUpdate()
    }

    const handleViewBenefitsReport = async () => {

        try {
            commonStore.setLoading(true);

            if (benefitsReport !== null) {
                commonStore.setLoading(false);
                setBenefitsReport(benefitsReport);
                setVisible("benefits-report");
            } else {
                if (selectedClient.BenefitsAssessment.OptToSendToEmployees) {
                    const ret = await commonStore.getJointBenefitsReport(selectedClient._id);
                    commonStore.setLoading(false);
                    if (!ret) {
                        setSnackBarType("error")
                        setSnackBarContent("Error retrieving benefits report")
                        setSnackBarOpen(true)
                        return;
                    }
                    setBenefitsReport(ret);
                    setVisible("benefits-report");
                } else {
                    commonStore.setLoading(false);
                    setSnackBarType("error")
                    setSnackBarContent("Employees have not submitted")
                    setSnackBarOpen(true)
                }
            }
        } catch (error) {
            commonStore.setLoading(false);
            console.error("Error fetching benefits report:", error);
            setSnackBarType("error")
            setSnackBarContent("Error retrieving benefits report")
            setSnackBarOpen(true)
        }
    };


    const copyAssessLink = (link) => {

        navigator.clipboard.writeText(link)
        setAssessLinkCopied(true)
    }

    const handleBackToSingleEmployer = (cli) => {

        handleOnClickClient(cli)
    }

    const handleBenefitChecked = (e) => {

        let checked = e.currentTarget.checked
        let id = e.currentTarget.id

        if (checked) {
            // Add the new ID to multiChoiceSelectedIds
            let newArrayids = [...benefitsSelected, id];

            setBenefitsSelected(newArrayids);
        } else {
            //cant just uncheck a benefit
            if (selectedClient.AvailableProducts.length > 0) {
                let alreadyLoaded = false
                selectedClient.AvailableProducts.forEach((i) => {
                    if (i.id === id) {
                        alreadyLoaded = true
                    }
                })
                console.log(alreadyLoaded)
                if (alreadyLoaded) {
                    alert("Please go select to specific benefit to unload it from this client")
                    return
                }
            }
            // Remove the ID from multiChoiceSelectedIds
            let newArrayids = benefitsSelected.filter((item) => item !== id);

            setBenefitsSelected(newArrayids);
        }
    };

    const handleConfirmBenefits = async () => {

        console.log(benefitsSelected)
        let added = allBenefits.filter((item) => benefitsSelected.includes(item.id))
        added = added.map((i) => {
            let curr = i
            curr.ActivatedDate = new Date()
            curr.Source = `Added by ${commonStore.user.Username}`
            curr.ClientHasConfirmed = false
            curr.Status = 'Pending'
            return curr
        })

        commonStore.setLoading(true)
        try {
            let res = await commonStore.activateClientBenefits(selectedClient._id, added, selectedClient)
            console.log(res)
            commonStore.setLoading(false)
            if (res) {
                setSnackBarType("success")
                setSnackBarContent("Benefits successfully loaded")
                setSnackBarOpen(true)
                reset()
                getClients()
            }
            else {
                commonStore.setLoading(false)
                setSnackBarType("error")
                setSnackBarContent("Benefits were not loaded, please try again")
                setSnackBarOpen(true)
            }
        } catch (error) {
            console.error(error)
            commonStore.setLoading(false)
            setSnackBarType("error")
            setSnackBarContent("Benefits were not loaded, please try again")
            setSnackBarOpen(true)
        }
    }

    const handleGetActivationLink = async () => {

        commonStore.setLoading(true)
        try {
            let res = await commonStore.getClientActivationLink(employerAdmin.WorkEmail)
            commonStore.setLoading(false)
            if (res.activationLink) {
                console.log("ran")
                setActivationLink(res.activationLink)
                navigator.clipboard.writeText(res.activationLink)
                commonStore.setSnackBar("success", "Copied to clipboard")
                setAssessLinkCopied(true)
            }
            else {
                commonStore.setSnackBar("warning", res || "Error occured, please try again")
            }
        } catch (error) {
            console.error(error)
            commonStore.setLoading(false)
            commonStore.setSnackBar("error", "Error occured, please try again")
        }
    }

    const copyActivationLink = (link) => {

        navigator.clipboard.writeText(link)
        setActivationLinkCopied(true)
    }

    const handleAddAdminUserOnClick = () => {
        setVisible2("add-admin-user")
        setNewAdminUser({
            FirstName: "",
            LastName: "",
            Username: "",
            WorkEmail: "",
            MobileNumber: "",
            Blocked: false,
            Source: `Created by ${commonStore.user.Username}`,
            Client: selectedClient._id,
            Confirmed: true,
            Role: '62bd55b7c5793941427c702d'
        })
        forceUpdate()
    }

    const handleNewAdminUserChange = (e) => {
        let id = e.currentTarget.id
        let value;
        value = e.currentTarget.value

        let curr = newAdminUser
        curr[id] = value

        setNewAdminUser(curr)
        forceUpdate()
    }

    const handlePermissionsChecked = (e) => {
        let id = e.currentTarget.id;

        setNewAdminPermissionsChecked((prevItems) => {
            if (prevItems.includes(id)) {
                return prevItems.filter((item) => item !== id);
            } else {
                return [...prevItems, id];
            }
        });
    };

    const handleCreateAdminUser = async () => {
        let user = newAdminUser
        if (!userPermissions.includes("create-admin-users")) {
            commonStore.setSnackBar("warning", "You are not permitted to perform this action")
            return
        }
        if (user.WorkEmail === "" || user.FirstName === "") {
            alert("Please enter all required details")
            return
        }
        if (!window.confirm(`Are you sure you want to assign these ${newAdminPermissionsChecked.length} permission(s) for ${user.FirstName}`)) {
            return
        }

        user.Username = user.WorkEmail
        user.Permissions = newAdminPermissionsChecked
        user.CreatedDate = new Date()
        user.ConfirmedDate = new Date()
        console.log(user)
        commonStore.setLoading(true)
        try {
            let create = await commonStore.createUser(user)
            commonStore.setLoading(false)
            if (create) {
                if (create === "User already exists") {
                    commonStore.setSnackBar("warning", "User already exists")
                    reset()
                    return
                }
                commonStore.setSnackBar("success", "User created")
                reset()
            }
            else {
                commonStore.setLoading(false)
                commonStore.setSnackBar("error", "Error occured, please try again")
            }
        } catch (error) {
            commonStore.setLoading(false)
            console.error(error)
            commonStore.setSnackBar("error", "Error occured, please try again")
        }
    }

    if (isMobile()) {
        return (
            <div>
                <Navbar />
                <main>
                    <div>
                        <select name='Select client'>
                            {clients.map((cl) => {
                                <option value={cl.Name}>{cl.Name}</option>
                            })}
                        </select>
                    </div>

                </main>
            </div>
        );
    } else {
        return (
            <div style={{ background: "#F4F5FA", width: '100%' }}>
                <AutohideSnackbar content={snackBarContent} open={snackBarOpen} type={snackBarType} setSnackBarClose={resetSnackBar} />
                {/* EDIT SECTION */}
                {editVisible === 'details' &&
                    <main style={{ position: 'fixed', background: '#808080ba' }}>
                        <div className='admin-dash-edit-inner-container'>
                            <header className='admin-dash-client-details-text mediumbold' style={{ fontSize: '18px' }}>
                                Edit company details
                            </header>
                            <br />
                            <div className='two-by-two-grid'>
                                <div className='flex-column'>
                                    <label className='admin-dash-client-details-text small'>Company name</label>
                                    <input value={companyPrimaryDetails.Name}
                                        id='Name'
                                        onChange={(e) => { handleOnDetailsChange(e, "companyDetails") }}
                                        className='admin-dash-assess-input'
                                        placeholder='Company name'
                                    />
                                </div>
                                <div className='flex-column'>
                                    <label className='admin-dash-client-details-text small'>Payroll pay date</label>
                                    <input value={companyPrimaryDetails.PayDate}
                                        id='PayDate'
                                        onChange={(e) => { handleOnDetailsChange(e, "companyDetails") }}
                                        className='admin-dash-assess-input'
                                        placeholder='Payroll pay date'
                                    />
                                </div>
                                <div className='flex-column'>
                                    <label className='admin-dash-client-details-text small'>Cut off date (Payroll submission day)</label>
                                    <input value={companyPrimaryDetails.CutOffDate}
                                        id='CutOffDate'
                                        onChange={(e) => { handleOnDetailsChange(e, "companyDetails") }}
                                        className='admin-dash-assess-input'
                                        placeholder='Cut off date (Payroll submission day)'
                                    />
                                </div>
                                <div className='flex-column'>
                                    <label className='admin-dash-client-details-text small'>VAT No</label>
                                    <input value={companyPrimaryDetails.VATNo}
                                        id='VATNo'
                                        onChange={(e) => { handleOnDetailsChange(e, "companyDetails") }}
                                        className='admin-dash-assess-input'
                                        placeholder='VAT No'
                                    />
                                </div>
                                <div className='flex-column'>
                                    <label className='admin-dash-client-details-text small'>Registration code</label>
                                    <input value={companyPrimaryDetails.RegCode}
                                        id='RegCode'
                                        onChange={(e) => { handleOnDetailsChange(e, "companyDetails") }}
                                        className='admin-dash-assess-input'
                                        placeholder='Registration code'
                                    />
                                </div>
                                <div></div>
                                <button className='admin-dash-back-arrow-btn' onClick={resetEditSection}>Cancel</button>
                                {
                                    companyPrimaryDetailsChanged &&
                                    <button className='admin-dash-back-arrow-btn'
                                        style={{ background: 'black', color: 'white' }}
                                        id='companyDetails'
                                        onClick={handleUpdateClient}>
                                        Update
                                    </button>
                                }
                            </div>
                        </div>
                    </main>
                }
                {editVisible === 'address' &&
                    <main style={{ position: 'fixed', background: '#808080ba' }}>
                        <div className='admin-dash-edit-inner-container'>
                            <header className='admin-dash-client-details-text mediumbold' style={{ fontSize: '18px' }}>
                                Edit physical address
                            </header>
                            <br />
                            <div className='two-by-two-grid'>
                                <div className='flex-column'>
                                    <label className='admin-dash-client-details-text small'>Line 1</label>
                                    <input value={companyAddress.Line1}
                                        id='Line1'
                                        onChange={(e) => { handleOnDetailsChange(e, "address") }}
                                        className='admin-dash-assess-input'
                                        placeholder='Line 1'
                                    />
                                </div>
                                <div className='flex-column'>
                                    <label className='admin-dash-client-details-text small'>Line 2</label>
                                    <input value={companyAddress.Line2}
                                        id='Line2'
                                        onChange={(e) => { handleOnDetailsChange(e, "address") }}
                                        className='admin-dash-assess-input'
                                        placeholder='Line 2'
                                    />
                                </div>
                                <div className='flex-column'>
                                    <label className='admin-dash-client-details-text small'>Line 3</label>
                                    <input value={companyAddress.Line3}
                                        id='Line3'
                                        onChange={(e) => { handleOnDetailsChange(e, "address") }}
                                        className='admin-dash-assess-input'
                                        placeholder='Line 3'
                                    />
                                </div>
                                <div className='flex-column'>
                                    <label className='admin-dash-client-details-text small'>City</label>
                                    <input value={companyAddress.City}
                                        id='City'
                                        onChange={(e) => { handleOnDetailsChange(e, "address") }}
                                        className='admin-dash-assess-input'
                                        placeholder='City'
                                    />
                                </div>
                                <div className='flex-column'>
                                    <label className='admin-dash-client-details-text small'>Province</label>
                                    <input value={companyAddress.Province}
                                        id='Province'
                                        onChange={(e) => { handleOnDetailsChange(e, "address") }}
                                        className='admin-dash-assess-input'
                                        placeholder='Province'
                                    />
                                </div>
                                <div className='flex-column'>
                                    <label className='admin-dash-client-details-text small'>Postal code</label>
                                    <input value={companyAddress.PostalCode}
                                        id='PostalCode'
                                        onChange={(e) => { handleOnDetailsChange(e, "address") }}
                                        className='admin-dash-assess-input'
                                        placeholder='PostalCode'
                                    />
                                </div>

                                <button className='admin-dash-back-arrow-btn' onClick={resetEditSection}>Cancel</button>
                                {
                                    companyAddressChanged &&
                                    <button className='admin-dash-back-arrow-btn'
                                        style={{ background: 'black', color: 'white' }}
                                        id='companyAddress'
                                        onClick={handleUpdateClient}>
                                        Update
                                    </button>
                                }
                            </div>
                        </div>
                    </main>
                }
                {/* MAIN SECTION */}
                {visible === "home" &&
                    <main>
                        <header className='admin-dash-option-header'>{`Clients`}</header>
                        <main style={{ background: "#FFFFFF", borderRadius: '20px', padding: '35px' }}>
                            {/* TOP DIV */}
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                                <header className='admin-dash-sub-header'>Clients</header>
                                <button className='admin-dash-add-btn' onClick={() => handleAddClientOnClick()}>&#43; Add client</button>
                            </div>
                            {/* FILTER */}
                            <div style={{ display: 'flex', gap: '20px' }}>
                                <div id='All'
                                    className={`admin-dash-filter-item ${clientFilterSelected === 'All' ? 'selected' : null}`}
                                    onClick={handleClientFilterOnClick}>
                                    All
                                </div>
                                <div id='Active'
                                    className={`admin-dash-filter-item ${clientFilterSelected === 'Active' ? 'selected' : null}`}
                                    onClick={handleClientFilterOnClick}>
                                    Active
                                </div>
                                <div id='Pending'
                                    className={`admin-dash-filter-item ${clientFilterSelected === 'Pending' ? 'selected' : null}`}
                                    onClick={handleClientFilterOnClick}>
                                    Pending
                                </div>
                                <div id='Deactivated'
                                    className={`admin-dash-filter-item ${clientFilterSelected === 'Deactivated' ? 'selected' : null}`}
                                    onClick={handleClientFilterOnClick}>
                                    Deactivated
                                </div>
                            </div>
                            {/* LIST */}
                            <div >
                                {/* SEARCH */}
                                <br />
                                <div style={{ display: 'flex', gap: '5px' }}>
                                    <img src={SearchIcon} style={{ width: '20px' }} />
                                    <input className='admin-dash-search-input'
                                        type='search' value={searchField}
                                        onChange={handleOnSearchFieldChange}
                                        placeholder='Search clients by name'
                                    />
                                </div>
                                <br />
                                {/* LIST ITEMS */}
                                <div className='admin-dash-users-table-headers'>
                                    <div className='admin-dash-users-table-headers-text left'>CLIENT NAME</div>
                                    <div style={{ display: 'flex' }}>
                                        <div className='admin-dash-users-table-headers-text right'>STATUS</div>
                                        <div className='admin-dash-users-table-headers-text right'>PAYROLL</div>
                                        <div className='admin-dash-users-table-headers-text right'>ACTIVATION DATE</div>
                                    </div>
                                </div>
                                {clients.length === 0 ? null :
                                    clients.filter(cli => {
                                        if (clientFilterSelected === 'Active') {
                                            return cli.Active === true && cli.Name.toLowerCase().includes(searchField);
                                        }
                                        else if (clientFilterSelected === 'Pending') {
                                            return (cli.Active === null || cli.Active === undefined) && cli.Name.toLowerCase().includes(searchField);
                                        }
                                        else if (clientFilterSelected === 'Deactivated') {
                                            return cli.Active === false && cli.Name.toLowerCase().includes(searchField);
                                        }
                                        else {
                                            return cli.Name.toLowerCase().includes(searchField);
                                        }
                                    })
                                        .map((cli, index) => (
                                            <div className='hover' key={cli._id} id={cli._id} onClick={() => handleOnClickClient(cli)}>
                                                <div className='admin-dash-users-table-headers white'>
                                                    <div className='admin-dash-users-table-headers-text left bold'>{cli.Name}</div>
                                                    <div style={{ display: 'flex' }}>
                                                        <div className='admin-dash-users-table-headers-text right bold'>
                                                            {cli.Active === false &&
                                                                <div
                                                                    className={`admin-dash-users-table-headers-text bold ${'deactivated'}`}>
                                                                    {'Deactivated'}
                                                                </div>
                                                            }
                                                            {cli.Active === true &&
                                                                <div
                                                                    className={`admin-dash-users-table-headers-text bold ${'active'}`}>
                                                                    {'Active'}
                                                                </div>
                                                            }
                                                            {!cli.Active &&
                                                                <div
                                                                    className={`admin-dash-users-table-headers-text bold ${'pending'}`}>
                                                                    {'Pending'}
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className='admin-dash-users-table-headers-text right'>
                                                            <div
                                                                className={`admin-dash-users-table-headers-text`}>
                                                                {cli.PayrollInformation?.Provider ? cli.PayrollInformation.Provider : 'Manual'}
                                                            </div>
                                                        </div>
                                                        <div className='admin-dash-users-table-headers-text right'>
                                                            <div
                                                                className={`admin-dash-users-table-headers-text`}>
                                                                {cli.ActivatedDate ? cli.ActivatedDate : 'N/A'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        ))
                                }

                            </div>
                        </main>
                    </main>
                }

                {visible === "benefits-report" &&
                    <div id="adminBenefitsReport">
                        <AdminBenefitsReport
                            data={{ client: selectedClient, report: benefitsReport }}
                            handleBackToSingleEmployer={handleBackToSingleEmployer}
                        />
                    </div>
                }
                {visible === 'singular-employer' &&

                    <main ref={chartRef} style={{ background: "#F4F5FA", width: '100%' }}>
                        {visible2 === 'add-admin-user' &&
                            <main style={{ position: 'fixed', background: '#808080ba' }}>
                                <div className='admin-dash-edit-inner-container'>
                                    <header className='admin-dash-client-details-text mediumbold' style={{ fontSize: '18px' }}>
                                        Add New Admin User
                                    </header>
                                    <div className="overflow-y-scroll h-64">
                                        <div className='two-by-two-grid'>
                                            <div className='flex-column'>
                                                <label className='admin-dash-client-details-text small'>First name<span style={{ color: 'red' }}>*</span></label>
                                                <input value={newAdminUser.FirstName}
                                                    id='FirstName'
                                                    onChange={handleNewAdminUserChange}
                                                    className='admin-dash-assess-input'
                                                    placeholder='First name'
                                                />
                                            </div>
                                            <div className='flex-column'>
                                                <label className='admin-dash-client-details-text small'>Last name</label>
                                                <input value={newAdminUser.LastName}
                                                    id='LastName'
                                                    onChange={handleNewAdminUserChange}
                                                    className='admin-dash-assess-input'
                                                    placeholder='Last name'
                                                />
                                            </div>
                                            <div className='flex-column'>
                                                <label className='admin-dash-client-details-text small'>Work email (Username)<span style={{ color: 'red' }}>*</span></label>
                                                <input value={newAdminUser.WorkEmail}
                                                    id='WorkEmail'
                                                    onChange={handleNewAdminUserChange}
                                                    className='admin-dash-assess-input'
                                                    placeholder='Work email  (Username)'
                                                />
                                            </div>
                                            <div className='flex-column'>
                                                <label className='admin-dash-client-details-text small'>Mobile number</label>
                                                <input value={newAdminUser.MobileNumber}
                                                    id='MobileNumber'
                                                    onChange={handleNewAdminUserChange}
                                                    className='admin-dash-assess-input'
                                                    placeholder='Mobile number'
                                                />
                                            </div>
                                            <div>
                                                <div className='employee-benefits-assessment-multiple-choice-choices-container'>
                                                    <header className='employee-benefits-assessment-multiple-choice-choices-header'>{`Permissions`}</header>
                                                    {permissions.employerAdminPermissions.map((item, index) => (
                                                        <div className='employee-benefits-assessment-multiple-choice-choices-item' key={index}>
                                                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                                <input
                                                                    type='checkbox'
                                                                    id={item.id}
                                                                    className='employee-benefits-assessment-multiple-choice-choices-item-checkbox'
                                                                    onChange={handlePermissionsChecked}
                                                                    checked={newAdminPermissionsChecked.includes(item.id)}
                                                                />
                                                                <label htmlFor={item.id}>{item.title}</label>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <br />
                                        </div>
                                    </div>
                                    <br />
                                    <div className='flex gap-1'>
                                        <button style={{ width: '100%' }} className='admin-dash-back-arrow-btn' onClick={reset}>Cancel</button>
                                        <button className='admin-dash-back-arrow-btn'
                                            style={{ background: 'black', color: 'white', width: '100%' }}
                                            id='userDetails'
                                            onClick={handleCreateAdminUser}>
                                            Add
                                        </button>
                                    </div>

                                </div>

                            </main>
                        }
                        {visible2 === 'add-benefit' &&
                            <main style={{ position: 'fixed', background: '#808080ba' }}>
                                <div className='admin-dash-edit-inner-container' style={{ marginLeft: '10%', width: '50%' }}>
                                    <header className='admin-dash-client-details-text mediumbold' style={{ fontSize: '18px' }}>
                                        {`Add benefits for ${selectedClient.Name}`}
                                    </header>
                                    <br />
                                    <div>
                                        {allBenefits.map((ben, index) => (
                                            <div className='employee-benefits-assessment-multiple-choice-choices-item' key={index}>
                                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                    <input
                                                        type='checkbox'
                                                        id={ben.id}
                                                        className='employee-benefits-assessment-multiple-choice-choices-item-checkbox'
                                                        onChange={(event) => handleBenefitChecked(event)}
                                                        checked={benefitsSelected.includes(`${ben.id}`)} // set checked attribute here
                                                    />
                                                    <label htmlFor={`${ben.id}`}>{ben.Name}</label>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className='two-by-two-grid'>
                                        <button className='admin-dash-back-arrow-btn' onClick={reset}>Cancel</button>
                                        {benefitsSelected.length > 0 ? <button className='admin-dash-back-arrow-btn'
                                            onClick={handleConfirmBenefits}
                                            style={{ background: 'black', color: 'white' }}>Add
                                        </button> : <div></div>
                                        }
                                    </div>
                                </div>
                            </main>
                        }
                        <header className='admin-dash-option-header'>{`Clients`}</header>
                        <main style={{ background: "#FFFFFF", width: '95%', borderRadius: '20px', padding: '35px' }}>
                            {/* TOP DIV */}
                            <div style={{ display: 'flex', marginBottom: '20px', gap: '25px', alignItems: 'center' }}>
                                <button className='admin-dash-back-arrow-btn' onClick={() => goBack()}>Back</button>
                                <header className='admin-dash-sub-header' style={{ fontSize: '14px' }}>
                                    <span className='admin-dash-blue-clients-text normal'>
                                        {'Clients '}</span>{` > ${selectedClient.Name}`}
                                </header>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                {/* ACTIVE */}
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                    <header className='admin-dash-client-details-text mediumbold'>Client status:</header>
                                    <label className='admin-dash-client-details-text small' style={{ color: `${activeStatus ? "blue" : "red"}` }}>
                                        {`${activeStatus ? 'Active' : 'Inactive'}`}</label>
                                    <div>
                                        <input type="checkbox" checked={activeStatus}
                                            id="toggle-checkbox"
                                            className='admin-dash-toggle-active-status visually-hidden'
                                            onChange={(e) => { handleOnDetailsChange(e, "active") }}
                                        />
                                        <label className="toggle" htmlFor="toggle-checkbox"></label>
                                    </div>

                                    {activeStatusChanged &&
                                        (
                                            <div className='admin-dash-blue-clients-text'
                                                id='activeStatus'
                                                onClick={handleUpdateClient}>
                                                {`Update active status`}
                                            </div>
                                        )
                                    }
                                </div>
                                {/* ACCOUNT HOLDER */}
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                    <header className='admin-dash-client-details-text mediumbold'>Client account holder</header>
                                    {Object.keys(employerAdmin).length > 0 ?
                                        <>
                                            <div className='admin-dash-client-details-text small'>{`${employerAdmin.FirstName || ""} ${employerAdmin.LastName || ""}`}</div>
                                            <div className='admin-dash-client-details-text small'>{`${employerAdmin.WorkEmail || ""}`}</div>
                                            <div className='admin-dash-client-details-text small'>{`${employerAdmin.MobileNumber || ""}`}</div>
                                        </> : <div className='admin-dash-client-details-text small'>{"No account holder"}</div>
                                    }
                                </div>
                                <p className='admin-dash-blue-clients-text'
                                    onClick={handleGetActivationLink}>
                                    {`Get activation link`}
                                </p>
                                {activationLink &&
                                    <p onClick={() => copyActivationLink(activationLink)}>
                                        {activationLinkCopied ? "Copied" : "Copy"}
                                    </p>
                                }
                                {userPermissions.includes("create-admin-users") &&
                                    <button className='admin-dash-add-btn' style={{ height: 'fit-content' }}
                                        onClick={() => handleAddAdminUserOnClick()}>
                                        &#43; Add admin user
                                    </button>}

                            </div>
                            <hr style={{ width: '100%' }} />
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                <header className='admin-dash-client-details-text mediumbold'>Payroll</header>
                                {selectedClient.PayrollInformation ?
                                    <>
                                        <div className='admin-dash-client-details-text small'>{`${selectedClient.PayrollInformation.Provider || "N/A"}`}</div>
                                        <div className='admin-dash-client-details-text small'>{`${selectedClient.PayrollInformation.Verified ? "Connected" : "Not connected"}`}</div>
                                    </> :
                                    <>
                                        <div className='admin-dash-client-details-text small'>{"Manual"}</div>
                                    </>

                                }
                            </div>
                            <hr style={{ width: '100%' }} />
                            {/* BENEFITS */}
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <header className='admin-dash-client-details-text mediumbold'>Benefits</header>
                                {userPermissions.includes("edit-clients") &&
                                    <button
                                        className='admin-dash-add-btn'
                                        onClick={() => handleAddBenefit()}>
                                        &#43; Add benefit
                                    </button>}
                            </div>
                            <div className='admin-dash-users-table-headers'>
                                <div className='admin-dash-users-table-headers-text left'>BENEFIT NAME</div>
                                <div style={{ display: 'flex' }}>
                                    <div className='admin-dash-users-table-headers-text right'>STATUS</div>
                                    <div className='admin-dash-users-table-headers-text right'>ACTIVATION DATE</div>
                                </div>
                            </div>
                            {selectedClient.AvailableProducts.length > 0 ?
                                selectedClient.AvailableProducts.map((prod, index) => (

                                    <div className='hover' key={index} id={prod.id}>
                                        {console.log(prod)}
                                        <div className='admin-dash-users-table-headers white'>
                                            <div className='admin-dash-users-table-headers-text left bold'>{prod.Name}</div>
                                            <div style={{ display: 'flex' }}>
                                                <div className='admin-dash-users-table-headers-text right bold'>
                                                    {prod.Status === "Blocked" &&
                                                        <div
                                                            className={`admin-dash-users-table-headers-text bold ${'deactivated'}`}>
                                                            {'Deactivated'}
                                                        </div>
                                                    }
                                                    {prod.Status === "Active" &&
                                                        <div
                                                            className={`admin-dash-users-table-headers-text bold ${'active'}`}>
                                                            {'Active'}
                                                        </div>
                                                    }
                                                    {(!prod.Status || prod.Status === "Pending") &&
                                                        <div
                                                            className={`admin-dash-users-table-headers-text bold ${'pending'}`}>
                                                            {'Pending'}
                                                        </div>
                                                    }
                                                </div>
                                                <div className='admin-dash-users-table-headers-text right'>
                                                    <div
                                                        className={`admin-dash-users-table-headers-text`}>
                                                        {prod.ConfirmedDate ? prod.ConfirmedDate : 'N/A'}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                                :
                                <div className='admin-dash-users-table-headers white'>
                                    <div className='admin-dash-users-table-headers-text left bold'>No benefits</div>
                                </div>
                            }
                            <br />
                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '25%' }}>
                                {/* DETAILS */}
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>

                                    <header className='admin-dash-client-details-text mediumbold'>Company details</header>
                                    <br />
                                    <div className='flex-column' style={{ gap: '17px' }}>
                                        <div className='flex-column'>
                                            <label className='admin-dash-client-details-text small'>Company name</label>
                                            <div className='admin-dash-client-details-text smallbold'>
                                                {companyPrimaryDetails.Name}
                                            </div>
                                        </div>
                                        <div className='flex-column'>
                                            <label className='admin-dash-client-details-text small'>Payroll pay date</label>
                                            <div className='admin-dash-client-details-text smallbold'>
                                                {companyPrimaryDetails.PayDate}
                                            </div>
                                        </div>
                                        <div className='flex-column'>
                                            <label className='admin-dash-client-details-text small'>Cut off date</label>
                                            <div className='admin-dash-client-details-text smallbold'>
                                                {companyPrimaryDetails.CutOffDate}
                                            </div>
                                        </div>
                                        <div className='flex-column'>
                                            <label className='admin-dash-client-details-text small'>VAT No.</label>
                                            <div className='admin-dash-client-details-text smallbold'>
                                                {companyPrimaryDetails.VATNo}
                                            </div>
                                        </div>
                                        <div className='flex-column'>
                                            <label className='admin-dash-client-details-text small'>Registration code</label>
                                            <div className='admin-dash-client-details-text smallbold'>
                                                {companyPrimaryDetails.RegCode}
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    {userPermissions.includes("edit-clients") &&
                                        <div style={{ display: 'flex', gap: '8px' }}>
                                            <img src={EditPenBlueIcon} style={{ height: '20px' }} />
                                            <div className='admin-dash-blue-clients-text' style={{ fontSize: '12px' }}
                                                onClick={() => setEditVisible("details")}>Edit company details</div>
                                        </div>}
                                    <br />

                                </div>

                                {/* ADDRESS */}
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>

                                    <header className='admin-dash-client-details-text mediumbold'>Physical address</header>
                                    <br />
                                    <div className='flex-column' style={{ gap: '17px' }}>
                                        <div className='flex-column'>
                                            <label className='admin-dash-client-details-text small'>Address line 1</label>
                                            <div className='admin-dash-client-details-text smallbold'>
                                                {companyAddress.Line1}
                                            </div>
                                        </div>
                                        <div className='flex-column'>
                                            <label className='admin-dash-client-details-text small'>Address line 2</label>
                                            <div className='admin-dash-client-details-text smallbold'>
                                                {companyAddress.Line2}
                                            </div>
                                        </div>
                                        <div className='flex-column'>
                                            <label className='admin-dash-client-details-text small'>Address line 3</label>
                                            <div className='admin-dash-client-details-text smallbold'>
                                                {companyAddress.Line3}
                                            </div>
                                        </div>
                                        <div className='flex-column'>
                                            <label className='admin-dash-client-details-text small'>Town/City</label>
                                            <div className='admin-dash-client-details-text smallbold'>
                                                {companyAddress.City}
                                            </div>
                                        </div>
                                        <div className='flex-column'>
                                            <label className='admin-dash-client-details-text small'>Province</label>
                                            <div className='admin-dash-client-details-text smallbold'>
                                                {companyAddress.Province}
                                            </div>
                                        </div>
                                        <div className='flex-column'>
                                            <label className='admin-dash-client-details-text small'>Postal code</label>
                                            <div className='admin-dash-client-details-text smallbold'>
                                                {companyAddress.PostalCode}
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    {userPermissions.includes("edit-clients") &&
                                        <div style={{ display: 'flex', gap: '8px' }}>
                                            <img src={EditPenBlueIcon} style={{ height: '20px' }} />
                                            <div className='admin-dash-blue-clients-text' style={{ fontSize: '12px' }}
                                                onClick={() => setEditVisible("address")}>Edit address
                                            </div>
                                        </div>
                                    }
                                    <br />
                                </div>

                            </div>
                            <hr style={{ width: '100%' }} />
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                {/* ASSESSMENTS */}
                                <header className='admin-dash-client-details-text mediumbold'>Employer Assessments</header>
                                {selectedClient.BenefitsAssessment ?
                                    <>
                                        <div className='admin-dash-client-assessments-section'>
                                            <div className='admin-dash-client-details-text small'
                                            >
                                                {`Date taken: 
                                                ${selectedClient.BenefitsAssessment.EmployerAssessment.DateTaken ?
                                                        selectedClient.BenefitsAssessment.EmployerAssessment.DateTaken : "Not set"}`}
                                            </div>
                                            {selectedClient.BenefitsAssessment.EmployeeAssessments &&
                                                <div className='admin-dash-blue-clients-text normal'
                                                    onClick={handleViewBenefitsReport}>
                                                    {`View benefits report`}
                                                </div>
                                            }

                                            <div className='admin-dash-blue-clients-text normal'
                                                onClick={() => getBenefitsReport()}>
                                                {`Download assessment results`}
                                            </div>
                                            <div className='admin-dash-blue-clients-text normal'
                                                onClick={() => handleSetAssessmentVisible(selectedClient.BenefitsAssessment.EmployerAssessment)}>
                                                {`${assessmentVisible ? 'Close' : 'Edit'} assessment`}
                                            </div>
                                            <br />
                                            <br />
                                        </div>
                                        {
                                            selectedClient.BenefitsAssessment.OptToSendToEmployees &&
                                            <>
                                                <br />
                                                {selectedClient.BenefitsAssessment.EmployeeAssessments ?
                                                    <p>{`${selectedClient.BenefitsAssessment.EmployeeAssessments.length} employee(s) have taken the assessment [Deadline: ${new Date(selectedClient.BenefitsAssessment.Deadline).toLocaleDateString()}]`}</p>
                                                    :
                                                    <p>{`0 employees have taken the assessment [Deadline: ${new Date(selectedClient.BenefitsAssessment.Deadline).toLocaleDateString()}]`}</p>

                                                }
                                                <div className='admin-dash-client-details-text small'>
                                                    Copy EMPLOYEE assessment link
                                                </div>

                                                <div className='admin-dash-blue-clients-text normal'
                                                    onClick={() => copyAssessLink(`${process.env.REACT_APP_FRONTEND_URL}/employees/benefits/assessment?cli=${selectedClient._id}`)}
                                                >
                                                    {`${assessLinkCopied ? "Copied!" : "Copy to clipboard"}`}
                                                </div>
                                            </>

                                        }
                                        <br />
                                    </>
                                    :
                                    //IF NO ASSESSMENT SUBMITTED

                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                        <div style={{ display: 'flex', gap: '2px' }}>
                                            <img src={BlueAddIcon} style={{ width: '16px' }} />
                                            <div className='admin-dash-blue-clients-text'
                                                style={{ fontSize: '12px' }}
                                                onClick={() => handleOnCreateAssessmentClick()}>
                                                {`Create new assessment`}
                                            </div>
                                        </div>
                                    </div>

                                }
                                {/* FULL ASSESSMENT */}
                                {assessmentVisible && selectedClient.BenefitsAssessment && (
                                    <>
                                        <header className='admin-dash-client-details-text mediumbold'>Edit</header>
                                        {console.log(selectedTempAssessment)}
                                        <div style={{ display: 'flex', flexWrap: 'wrap', background: "#F5F5F5" }}>
                                            {Object.keys(selectedAssessment).filter((item) => { return item !== "DateTaken" }).map((keys) => (
                                                <div key={keys} className='admin-dash-assess-q-a-item half'>
                                                    <label className='admin-dash-client-details-text small' style={{ height: '40px' }}>{selectedAssessment[keys].question}</label>
                                                    <input value={selectedTempAssessment[keys].answer}
                                                        onChange={(e) => handleAssessmentAnswerOnChange(e, keys)}
                                                        className='admin-dash-assess-input'
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                        {assessmentChanged &&
                                            (<div className='admin-dash-blue-clients-text'
                                                onClick={() => handleSubmitAssessment()}>
                                                {`Update assessment`}
                                            </div>)
                                        }
                                    </>
                                )}

                            </div>
                            <hr style={{ width: '100%' }} />
                            {/* PAYROLL */}
                        </main>
                    </main>
                }
                {visible === 'add-client' &&
                    <main style={{ background: "#F4F5FA", width: '100%' }}>
                        <header className='admin-dash-option-header'>{`Add`}</header>
                        <main style={{ background: "#FFFFFF", width: '80%', borderRadius: '20px', padding: '35px' }}>
                            {/* TOP DIV */}
                            <div style={{ display: 'flex', marginBottom: '20px', gap: '25px', alignItems: 'center' }}>
                                <button className='admin-dash-back-arrow-btn' onClick={() => goBack()}>Back</button>
                                <header className='admin-dash-sub-header' style={{ fontSize: '14px' }}>
                                    <span className='admin-dash-blue-clients-text normal'>{'Clients '}</span>{` > New`}
                                </header>
                            </div>

                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
                                {/* DETAILS */}
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>

                                    <header className='admin-dash-client-details-text mediumbold'>Client details</header>
                                    <br />
                                    <div className='two-by-two-grid'>
                                        <div className='flex-column'>
                                            <label className='admin-dash-client-details-text small'>Company Name<span style={{ color: 'red' }}>{` *`}</span></label>
                                            <input value={newClient.Name}
                                                id='Name'
                                                onChange={handleNewClientChange}
                                                className='admin-dash-assess-input'
                                                placeholder='Company Name'
                                            />
                                        </div>
                                        <div className='flex-column'>
                                            <label className='admin-dash-client-details-text small'>Payroll pay date<span style={{ color: 'red' }}>{` *`}</span></label>
                                            <input value={newClient.PayDate}
                                                id='PayDate'
                                                onChange={handleNewClientChange}
                                                className='admin-dash-assess-input'
                                                placeholder='Payroll pay date'
                                            />
                                        </div>

                                        <div className='flex-column'>
                                            <p>Note: preferably 5 days before pay day</p>
                                            <label className='admin-dash-client-details-text small'>Cut off date (Payroll submission day)<span style={{ color: 'red' }}>{` *`}</span></label>
                                            <input value={newClient.CutOffDate}
                                                id='CutOffDate'
                                                onChange={handleNewClientChange}
                                                className='admin-dash-assess-input'
                                                placeholder='Cut off date (Payroll submission day)'
                                            />
                                        </div>
                                        <div className='flex-column' style={{ justifyContent: 'flex-end' }}>
                                            <label className='admin-dash-client-details-text small'>Registration Code<span style={{ color: 'red' }}>{` *`}</span></label>
                                            <input value={newClient.RegCode}
                                                id='RegCode'
                                                onChange={handleNewClientChange}
                                                className='admin-dash-assess-input'
                                                placeholder='Registration Code'
                                            />
                                        </div>
                                        <div className='flex-column'>
                                            <label className='admin-dash-client-details-text small'>VAT No.<span style={{ color: 'red' }}>{` *`}</span></label>
                                            <input value={newClient.VATNo}
                                                id='VATNo'
                                                onChange={handleNewClientChange}
                                                className='admin-dash-assess-input'
                                                placeholder='VAT No.'
                                            />
                                        </div>

                                    </div>

                                    <br />
                                </div>
                                {/* ACCOUNT HOLDER */}
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                    <br />
                                    <header className='admin-dash-client-details-text mediumbold'>Account holder</header>

                                    <div className='two-by-two-grid'>
                                        <div className='flex-column'>
                                            <label className='admin-dash-client-details-text small'>First name<span style={{ color: 'red' }}>{` *`}</span></label>
                                            <input value={accountHolder.FirstName}
                                                id='FirstName'
                                                onChange={handleOnAccountHolderChange}
                                                className='admin-dash-assess-input'
                                                placeholder='First name'
                                            />
                                        </div>
                                        <div className='flex-column'>
                                            <label className='admin-dash-client-details-text small'>Last name</label>
                                            <input value={accountHolder.LastName}
                                                id='LastName'
                                                onChange={handleOnAccountHolderChange}
                                                className='admin-dash-assess-input'
                                                placeholder='First name'
                                            />
                                        </div>
                                        <div className='flex-column'>
                                            <label className='admin-dash-client-details-text small'>Work Email<span style={{ color: 'red' }}>{` *`}</span></label>
                                            <input value={accountHolder.WorkEmail}
                                                id='WorkEmail'
                                                onChange={handleOnAccountHolderChange}
                                                className='admin-dash-assess-input'
                                                placeholder='First name'
                                            />
                                        </div>
                                        <div className='flex-column'>
                                            <label className='admin-dash-client-details-text small'>Mobile number</label>
                                            <input value={accountHolder.MobileNumber}
                                                id='MobileNumber'
                                                onChange={handleOnAccountHolderChange}
                                                className='admin-dash-assess-input'
                                                placeholder='Mobile number'
                                            />
                                        </div>
                                    </div>
                                    <br />
                                </div>
                                {/* ADDRESS */}
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                    <br />
                                    <header className='admin-dash-client-details-text mediumbold'>Company address</header>

                                    <div className='two-by-two-grid'>
                                        <div className='flex-column'>
                                            <label className='admin-dash-client-details-text small'>Line 1<span style={{ color: 'red' }}>{` *`}</span></label>
                                            <input value={newClientAddress.Line1}
                                                id='Line1'
                                                onChange={handleOnNewClientAddressChange}
                                                className='admin-dash-assess-input'
                                                placeholder='Line 1'
                                            />
                                        </div>
                                        <div className='flex-column'>
                                            <label className='admin-dash-client-details-text small'>Line 2</label>
                                            <input value={newClientAddress.Line2}
                                                id='Line2'
                                                onChange={handleOnNewClientAddressChange}
                                                className='admin-dash-assess-input'
                                                placeholder='Line 2'
                                            />
                                        </div>
                                        <div className='flex-column'>
                                            <label className='admin-dash-client-details-text small'>Line 3</label>
                                            <input value={newClientAddress.Line3}
                                                id='Line3'
                                                onChange={handleOnNewClientAddressChange}
                                                className='admin-dash-assess-input'
                                                placeholder='Line 3'
                                            />
                                        </div>
                                        <div className='flex-column'>
                                            <label className='admin-dash-client-details-text small'>Line 4</label>
                                            <input value={newClientAddress.Line4}
                                                id='Line4'
                                                onChange={handleOnNewClientAddressChange}
                                                className='admin-dash-assess-input'
                                                placeholder='Line 4'
                                            />
                                        </div>
                                        <div className='flex-column'>
                                            <label className='admin-dash-client-details-text small'>City<span style={{ color: 'red' }}>{` *`}</span></label>
                                            <input value={newClientAddress.City}
                                                id='City'
                                                onChange={handleOnNewClientAddressChange}
                                                className='admin-dash-assess-input'
                                                placeholder='City'
                                            />
                                        </div>
                                        <div className='flex-column'>
                                            <label className='admin-dash-client-details-text small'>Province<span style={{ color: 'red' }}>{` *`}</span></label>
                                            <input value={newClientAddress.Province}
                                                id='Province'
                                                onChange={handleOnNewClientAddressChange}
                                                className='admin-dash-assess-input'
                                                placeholder='Province'
                                            />
                                        </div>
                                        <div className='flex-column'>
                                            <label className='admin-dash-client-details-text small'>PostalCode</label>
                                            <input value={newClientAddress.PostalCode}
                                                id='PostalCode'
                                                onChange={handleOnNewClientAddressChange}
                                                className='admin-dash-assess-input'
                                                placeholder='Postal code'
                                            />
                                        </div>
                                    </div>
                                    <br />
                                </div>
                            </div>
                            <hr style={{ width: '100%' }} />

                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>

                                <header className='admin-dash-client-details-text mediumbold'>Payroll</header>
                                <br />
                                <div className='two-by-two-grid'>
                                    <div onClick={() => handleOnProviderClick('sage')}
                                        className={`connect-payroll-providers-card${clicked === 'sage' ? ' active' : ''}`}>
                                        {clicked === 'sage' &&
                                            <img className='connect-payroll-providers-card-check' src={PinkCheckCircle} />
                                        }
                                        <div >
                                            <img className='connect-payroll-providers-card-logo' src={SageIcon} />
                                            <p style={{ fontWeight: '700', textAlign: 'center' }}>Sage 300</p>
                                        </div>
                                    </div>

                                    <div onClick={() => handleOnProviderClick('simple')}
                                        className={`connect-payroll-providers-card${clicked === 'simple' ? ' active' : ''}`}>
                                        {clicked === 'simple' &&
                                            <img className='connect-payroll-providers-card-check' src={PinkCheckCircle} />
                                        }
                                        <div >
                                            <img className='connect-payroll-providers-card-logo' src={SimplePayIcon} />
                                            <p style={{ fontWeight: '700', textAlign: 'center' }}>Simple pay</p>
                                        </div>
                                    </div>

                                    <div onClick={() => handleOnProviderClick('labour')}
                                        className={`connect-payroll-providers-card${clicked === 'labour' ? ' active' : ''}`}>
                                        {clicked === 'labour' &&
                                            <img className='connect-payroll-providers-card-check' src={PinkCheckCircle} />
                                        }
                                        <div >
                                            <img className='connect-payroll-providers-card-logo' src={LabourNetIcon} />
                                            <p style={{ fontWeight: '700', textAlign: 'center' }}>Labournet</p>
                                        </div>
                                    </div>

                                    <div onClick={() => handleOnProviderClick('payspace')}
                                        className={`connect-payroll-providers-card${clicked === 'payspace' ? ' active' : ''}`}>
                                        {clicked === 'payspace' &&
                                            <img className='connect-payroll-providers-card-check' src={PinkCheckCircle} />
                                        }
                                        <div >
                                            <img className='connect-payroll-providers-card-logo' src={PaySpaceIcon} />
                                            <p style={{ fontWeight: '700', textAlign: 'center' }}>Pay space</p>
                                        </div>
                                    </div>

                                </div>

                                {clicked === 'sage' &&
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                                        <p style={{ textAlign: 'left', fontSize: '18px' }} className='employee-benefits-assessment-submit-subheader'>
                                            Complete the fields below.
                                        </p>
                                        <Input value={sageApiKey}
                                            onChange={(e) => handleSageApiKeyOnChange(e.target.value)}
                                            placeholder={'API key'} />
                                        <Input value={sageUsername}
                                            onChange={(e) => handleSageUsernameOnChange(e.target.value)}
                                            placeholder={'Username'} />
                                        <Input value={sagePassword}
                                            onChange={(e) => handleSagepasswordOnChange(e.target.value)} inputType='password'
                                            placeholder={'Password'} />
                                    </div>
                                }

                                {clicked === 'simple' &&
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                                        <p style={{ textAlign: 'left', fontSize: '18px' }} className='employee-benefits-assessment-submit-subheader'>
                                            Complete the fields below.
                                        </p>
                                        <Input value={simplePayApiKey}
                                            onChange={(e) => handleSimplePayKeyOnChange(e.target.value)}
                                            placeholder={'API key'} />
                                        {/* <Input value={simplePayClientId}
                                            onChange={(e) => handleSimplePayUsernameOnChange(e.target.value)}
                                            placeholder={'Client ID'} /> */}

                                    </div>
                                }

                                {clicked === 'labour' &&
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                                        <p style={{ textAlign: 'left', fontSize: '18px' }} className='employee-benefits-assessment-submit-subheader'>
                                            Complete the fields below.
                                        </p>
                                        <Input value={labourNetUsername}
                                            onChange={(e) => handleLabourNetUsernameOnChange(e.target.value)}
                                            placeholder={'Username'} />
                                        <Input value={labourNetPassword}
                                            onChange={(e) => handleLabourNetPasswordOnChange(e.target.value)}
                                            placeholder={'Password'} />
                                        <Input value={labourNetInstance}
                                            onChange={(e) => handleLabourNetInstanceOnChange(e.target.value)}
                                            placeholder={'Instance'} />
                                    </div>
                                }

                                {clicked === 'payspace' &&
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                                        <p style={{ textAlign: 'left', fontSize: '18px' }} className='employee-benefits-assessment-submit-subheader'>
                                            Complete the fields below.
                                        </p>
                                        <Input value={paySpaceApiKey}
                                            onChange={(e) => handlePaySpaceKeyOnChange(e.target.value)}
                                            placeholder={'API key'} />
                                        <Input value={paySpaceClientId}
                                            onChange={(e) => handlePayClientIdOnChange(e.target.value)}
                                            placeholder={'Client ID'} />
                                        <Input value={paySpaceScope}
                                            onChange={(e) => handlePayScopeOnChange(e.target.value)}
                                            placeholder={'Scope'} />
                                    </div>
                                }

                            </div>
                            <hr style={{ width: '100%' }} />
                            <button
                                className='admin-dash-add-btn'
                                onClick={() => handleCreateClient()}>
                                &#43; Add client
                            </button>
                        </main>
                    </main>
                }

            </div>
        );
    }
})

export default AdminClient
