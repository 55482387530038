import React, { useState } from 'react';
import { LogoutIconWhite, ProfileIconWhite, BottomNavHomeOff, BottomNavHomeOn, BottomNavBenefitsOff, BottomNavBenefitsOn, BottomNavStoreOn, BottomNavStoreOff, BottomNavMore } from '../../assets/icons';
import { useStore } from '../../stores/Store';
import './styles.scss'
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'is-mobile';

const EmployeeBottomNav = ({ setPage }) => {

    const commonStore = useStore()
    const navigate = useNavigate()
    const [collapsed, setCollapsed] = useState(false)
    const [selected, setSelected] = useState("home")

    const handleSelected = (e) => {
        let sel = e.currentTarget.id
        setSelected(sel)
        setPage(sel)
    }

    const handleDropDown = () => {
        setCollapsed(!collapsed)
    }
    const handleLogout = () => {
        commonStore.logout()
        navigate('/signin')
    }
    const handleToProfile = () => {
        navigate('/profile')
    }

    return (
        <div style={{ boxShadow: '0px 0px 18px 0px rgba(0, 0, 0, 0.12)', border: 'none', position: 'sticky', bottom: 0, background: '#fff', zIndex: 10 }} className='admin-dash-topbar'>
            <div style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <div id="home" onClick={handleSelected} className='flex-column' style={{ alignItems: 'center' }}>
                    <img src={selected === "home" ? BottomNavHomeOn : BottomNavHomeOff} style={{ width: '26px' }} />
                    <div className={`bottom-nav-label ${selected === "home" && 'selected'}`}>Home</div>
                </div>
                {/* <div id="shop" onClick={handleSelected} className='flex-column' style={{ alignItems: 'center' }}>
                    <img src={selected === "shop" ? BottomNavStoreOn : BottomNavStoreOff} style={{ width: '26px' }} />
                    <div className={`bottom-nav-label ${selected === "shop" && 'selected'}`}>Shop</div>
                </div> */}
                <div id="benefits" onClick={handleSelected} className='flex-column' style={{ alignItems: 'center' }}>
                    <img src={selected === "benefits" ? BottomNavBenefitsOn : BottomNavBenefitsOff} style={{ width: '26px' }} />
                    <div className={`bottom-nav-label ${selected === "benefits" && 'selected'}`}>Benefits</div>
                </div>
                <div id="more" onClick={handleDropDown} className='flex-column' style={{ alignItems: 'center' }}>
                    <img src={BottomNavMore} style={{ width: '26px' }} />
                    <div className={`bottom-nav-label`}>More</div>
                </div>
            </div>
            {collapsed &&
                <div className='employee-profile-dropdown'>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '7px', alignItems: 'center' }}>
                        <div><img src={ProfileIconWhite} /></div>
                        <div className='admin-dash-sidebar-text' style={{ color: 'white', fontSize: '13px' }}
                            onClick={handleToProfile}>View profile</div>
                    </div>
                    <hr />
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '7px' }}>
                        <div><img src={LogoutIconWhite} /></div>
                        <div className='admin-dash-sidebar-text' style={{ color: 'white', fontSize: '13px' }}
                            onClick={handleLogout}>Logout</div>
                    </div>
                </div>
            }

        </div>
    );
};

export default EmployeeBottomNav;
