import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../../stores/Store'
import { isMobile } from 'is-mobile';
import { SearchIcon } from '../../../../assets/icons/index'
import { BasicMuiTextField, DateInput, DialogConfirm, CustomizedTooltips } from '../../../../components/MUI';

import './styles.scss';
import { common } from '@mui/material/colors';
import { Alert, TextField } from '@mui/material';

const AdminReportingHome = observer(({ data, setVisible }) => {

    let navigate = useNavigate();
    const commonStore = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [visibleChild, setVisibleChild] = useState('home')

    const [error, setError] = useState('');

    const handleSetVisible = (state) => {
        setVisible(state)
    };

    const reset = () => {
        setVisibleChild("home")
    }

    if (isMobile()) {
        return (
            <div style={{ background: "#F4F5FA", width: '100%' }}>

            </div>
        );
    } else {
        return (
            <>
                {visibleChild === "home" &&
                    <div>
                        <div style={{ background: "#FFFFFF", borderRadius: '20px', padding: '35px' }}>
                            {/* TOP DIV */}
                            
                        </div>
                    </div>
                }
            </>
        );
    }
})

export default AdminReportingHome
