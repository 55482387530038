import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../../../stores/Store'
import { isMobile } from 'is-mobile';
import { SearchIcon } from '../../../../../assets/icons/index'

import './styles.scss';

const AdminBenefitsFlexiblePayClients = observer(({ data, setVisible, refresh }) => {

    let navigate = useNavigate();
    const commonStore = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [clients, setClients] = useState(data.fpClients);
    const [visibleChild, setVisibleChild] = useState("home")
    const [searchField, setSearchField] = useState("")
    const [clientFilterSelected, setClientFilterSelected] = useState('All')
    const [selectedClient, setSelectedClient] = useState(null)
    const [activeStatus, setActiveStatus] = useState(false)

    const reset = () => {
        setActiveStatus(false)
        setVisibleChild("home");
        setSearchField('')
        setClientFilterSelected("All")
        setSelectedClient(null)
    };

    useEffect(() => {
        console.log("Triggered")
        setClients(data.fpClients)
        forceUpdate()
    }, [data])

    const handleOnSearchFieldChange = (e) => {
        setSearchField(e.currentTarget.value)
    }

    const handleClientFilterOnClick = (e) => {
        setClientFilterSelected(e.currentTarget.id)
    }
    const handleOnClickClient = async (object) => {
        setSelectedClient(object)
        setActiveStatus(object.AllowAdvances)
        setVisibleChild("singular-employer")
        forceUpdate()
    }

    const handleCheckClientBlocked = (e) => {
        let checked = e.target.checked
        if (checked) {
            if (!window.confirm("Are you sure you want to unblock this client from using this service?")) {
                return
            }
        }
        if (!checked) {
            if (!window.confirm("Are you sure you want to block this client from using this service?")) {
                return
            }
        }
        setActiveStatus(checked)
        updateFpClient({ AllowAdvances: !activeStatus })
    }

    const updateFpClient = async (data) => {
        console.log(data, selectedClient._id)
        commonStore.setLoading(true)
        try {
            let res = await commonStore.updateFlexiblePayClient(selectedClient._id, data)
            commonStore.setLoading(false)
            console.log(res)
            if (res ==="Success") {
                commonStore.setSnackBar('success', "Client updated")
                reset()
                refresh()
            }
            else {
                commonStore.setSnackBar('error', "Error occured, please try again")
                commonStore.setLoading(false)
                reset()
            }

        } catch (error) {
            commonStore.setSnackBar('error', "Error occured, please try again")
            console.error(error)
            commonStore.setLoading(false)
            reset()
        }
    }

    console.log(selectedClient)
    if (isMobile()) {
        return (
            <div style={{ background: "#F4F5FA", width: '100%' }}>

            </div>
        );
    } else {
        return (
            <>
                {visibleChild === "home" &&
                    <main>
                        <header className='admin-dash-option-header'>{`Clients`}</header>
                        <main style={{ background: "#FFFFFF", borderRadius: '20px', padding: '35px' }}>
                            {/* TOP DIV */}
                            {/* FILTER */}
                            <div style={{ display: 'flex', gap: '20px' }}>
                                <div id='All'
                                    className={`admin-dash-filter-item ${clientFilterSelected === 'All' ? 'selected' : null}`}
                                    onClick={handleClientFilterOnClick}>
                                    All
                                </div>
                                <div id='Active'
                                    className={`admin-dash-filter-item ${clientFilterSelected === 'Active' ? 'selected' : null}`}
                                    onClick={handleClientFilterOnClick}>
                                    Active
                                </div>
                                <div id='Deactivated'
                                    className={`admin-dash-filter-item ${clientFilterSelected === 'Deactivated' ? 'selected' : null}`}
                                    onClick={handleClientFilterOnClick}>
                                    Deactivated
                                </div>
                            </div>
                            {/* LIST */}
                            <div >
                                {/* SEARCH */}
                                <br />
                                <div style={{ display: 'flex', gap: '5px' }}>
                                    <img src={SearchIcon} style={{ width: '20px' }} />
                                    <input className='admin-dash-search-input'
                                        type='search' value={searchField}
                                        onChange={handleOnSearchFieldChange}
                                        placeholder='Search clients by name'
                                    />
                                </div>
                                <br />
                                {/* LIST ITEMS */}
                                <div className='admin-dash-users-table-headers'>
                                    <div className='admin-dash-users-table-headers-text left'>CLIENT NAME</div>
                                    <div style={{ display: 'flex' }}>
                                        <div className='admin-dash-users-table-headers-text right'>STATUS</div>
                                        <div className='admin-dash-users-table-headers-text right'>ACTIVATED DATE</div>
                                    </div>
                                </div>
                                {clients.length === 0 ? null :
                                    clients.filter(cli => {
                                        if (clientFilterSelected === 'Active') {
                                            return cli.AllowAdvances === true && cli.Name.toLowerCase().includes(searchField);
                                        }
                                        else if (clientFilterSelected === 'Deactivated') {
                                            return cli.AllowAdvances === false && cli.Name.toLowerCase().includes(searchField);
                                        }
                                        else {
                                            return cli.Name.toLowerCase().includes(searchField);
                                        }
                                    })
                                        .map((cli) => (
                                            <div className='hover' key={cli._id} id={cli._id} onClick={() => handleOnClickClient(cli)}>
                                                <div className='admin-dash-users-table-headers white'>
                                                    <div className='admin-dash-users-table-headers-text left bold'>{cli.Name}</div>
                                                    <div style={{ display: 'flex' }}>
                                                        <div className='admin-dash-users-table-headers-text right bold'>
                                                            {cli.AllowAdvances === false ?
                                                                <div
                                                                    className={`admin-dash-users-table-headers-text bold ${'deactivated'}`}>
                                                                    {'Advances not allowed'}
                                                                </div>
                                                                :
                                                                <div
                                                                    className={`admin-dash-users-table-headers-text bold ${'active'}`}>
                                                                    {'Advances allowed'}
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className='admin-dash-users-table-headers-text right'>
                                                            <div
                                                                className={`admin-dash-users-table-headers-text`}>
                                                                {cli.CreatedDate ? new Date(cli.CreatedDate).toLocaleDateString() : 'N/A'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        ))
                                }

                            </div>
                        </main>
                    </main>
                }
                {visibleChild === 'singular-employer' &&

                    <main style={{ background: "#F4F5FA", width: '100%' }}>
                        <header className='admin-dash-option-header'>{`Clients`}</header>
                        <main style={{ background: "#FFFFFF", width: '95%', borderRadius: '20px', padding: '35px' }}>
                            {/* TOP DIV */}
                            <div style={{ display: 'flex', marginBottom: '20px', gap: '25px', alignItems: 'center' }}>
                                <button className='admin-dash-back-arrow-btn' onClick={reset}>Back</button>
                                <header className='admin-dash-sub-header' style={{ fontSize: '14px' }}>
                                    <span className='admin-dash-blue-clients-text normal'>
                                        {'Clients '}</span>{` > ${selectedClient.Name}`}
                                </header>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                {/* ACTIVE */}
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                    <header className='admin-dash-client-details-text mediumbold'>Allow advances:</header>
                                    <label className='admin-dash-client-details-text small' style={{ color: `${activeStatus ? "blue" : "red"}` }}>
                                        {`${activeStatus ? 'Allowed' : 'Not allowed'}`}</label>
                                    <div>
                                        <input type="checkbox" checked={activeStatus}
                                            id="toggle-checkbox"
                                            className='admin-dash-toggle-active-status visually-hidden'
                                            onChange={handleCheckClientBlocked}
                                        />
                                        <label className="toggle" htmlFor="toggle-checkbox"></label>
                                    </div>
                                </div>

                            </div>
                            <br />
                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '25%' }}>
                                {/* DETAILS */}
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                    <header className='admin-dash-client-details-text mediumbold'>Company details</header>
                                    <br />
                                    <div className='flex-column' style={{ gap: '17px' }}>
                                        <div className='flex-column'>
                                            <label className='admin-dash-client-details-text small'>Company name</label>
                                            <div className='admin-dash-client-details-text smallbold'>
                                                {selectedClient.Name}
                                            </div>
                                        </div>
                                        <div className='flex-column'>
                                            <label className='admin-dash-client-details-text small'>Payroll pay date</label>
                                            <div className='admin-dash-client-details-text smallbold'>
                                                {selectedClient.PayDate}
                                            </div>
                                        </div>
                                    </div>
                                    <br />

                                </div>

                            </div>
                        </main>
                    </main>
                }
            </>
        );
    }
})

export default AdminBenefitsFlexiblePayClients
