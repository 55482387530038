const BaselineAssessmentQuestions = {
    Demographic: {
        category: "Demographic",
        question: "Which demographic do you fall within",
        answerPrefix: "",
        options: [
            { answer: "Gen Z (1997-2012)", score: null },
            { answer: "Millennial (1981-1996)", score: null },
            { answer: "Gen X (1965-1980)", score: null },
            { answer: "Baby Boomers (1946-1964)", score: null },
        ],
    },

    EmploymentLength: {
        category: "EmploymentLength",
        question: "How long have you been employed?",
        answerPrefix: "",
        options: [
            { answer: "Less than a year", score: null },
            { answer: "1-3 years", score: null },
            { answer: "3-6 years", score: null },
            { answer: "6-10 years", score: null },
            { answer: "10-15 years", score: null },
            { answer: "15+ years", score: null },
        ],
    },

    HouseholdBudget: {
        category: "HouseholdBudget",
        question: "Does your household have a budget?",
        answerPrefix: "",
        options: [
            { answer: "Yes", score: null },
            { answer: "No", score: null },
            { answer: "Maybe", score: null },
        ],
    },

    HouseholdBudgetDiscipline: {
        category: "HouseholdBudgetDiscipline",
        question: "Does your household stick to your budget?",
        answerPrefix: "",
        options: [
            { answer: "Yes", score: null },
            { answer: "No", score: null },
        ],
    },

    Spend: [
        {
            category: "Spend",
            question: "In the past year, did your household spend more or less than what you earned?",
            answerPrefix: "",
            options: [
                { answer: "We spent a lot less than we earned.", score: 100 },
                { answer: "We spent a little less than we earned.", score: 75 },
                { answer: "We spent about the same as we earned.", score: 50 },
                { answer: "We spent a little more than we earned.", score: 25 },
                { answer: "We spent a lot more than we earned.", score: 0 },
            ],
        },
        {
            category: "Spend",
            question: "In the past year, how did your household manage to pay its bills?",
            answerPrefix: "",
            options: [
                { answer: "We paid all our bills on time.", score: 100 },
                { answer: "We paid almost all our bills on time.", score: 60 },
                { answer: "We paid most of our bills on time.", score: 40 },
                { answer: "We paid only some of our bills on time.", score: 20 },
                { answer: "We paid very few of our bills on time.", score: 0 },
            ],
        },
    ],

    Save: [
        {
            category: "Save",
            question: "If you only had the money you have now (without borrowing or using savings for retirement), how long could your household pay for everything?",
            answerPrefix: "",
            options: [
                { answer: "6 months or more.", score: 100 },
                { answer: "3-5 months.", score: 75 },
                { answer: "1-2 months.", score: 50 },
                { answer: "1-3 weeks.", score: 25 },
                { answer: "Less than 1 week.", score: 0 },
            ],
        },
        {
            category: "Save",
            question:
                "How sure are you that your household is doing enough to save money for future needs (like saving for a vacation, a house, or retirement)?",
            answerPrefix: "",
            options: [
                { answer: "Very sure.", score: 100 },
                { answer: "Somewhat sure.", score: 75 },
                { answer: "A little sure.", score: 50 },
                { answer: "Not very sure.", score: 25 },
                { answer: "Not sure at all.", score: 0 },
            ],
        },
    ],

    Borrow: [
        {
            category: "Borrow",
            question:
                "How do you feel about your household’s debt (like loans, medical bills, or money you owe others)?",
            answerPrefix: "",
            options: [
                { answer: "We have no debt.", score: 100 },
                { answer: "We can manage our debt.", score: 85 },
                { answer: "We have a bit more debt than we can manage.", score: 40 },
                { answer: "We have much more debt than we can manage.", score: 0 },
            ],
        },
        {
            category: "Borrow",
            question: "How would you describe your credit score?",
            answerPrefix: "",
            options: [
                { answer: "Excellent.", score: 100 },
                { answer: "Very good.", score: 80 },
                { answer: "Good.", score: 60 },
                { answer: "Fair.", score: 40 },
                { answer: "Poor.", score: 20 },
                { answer: "I don't know what my credit score is.", score: 0 },
            ],
        },
    ],

    Plan: [
        {
            category: "Plan",
            question:
                "How sure are you that the insurance your household has (like health, car, or life insurance) will help in an emergency?",
            answerPrefix: "",
            options: [
                { answer: "Very sure.", score: 100 },
                { answer: "Somewhat sure.", score: 75 },
                { answer: "A little sure.", score: 50 },
                { answer: "Not very sure.", score: 25 },
                { answer: "Not sure at all.", score: 10 },
                { answer: "We don’t have any insurance.", score: 0 },
            ],
        },
        {
            category: "Plan",
            question:
                'Do you agree or disagree with this statement: "My household saves and plans for the future"?',
            answerPrefix: "",
            options: [
                { answer: "Strongly agree.", score: 100 },
                { answer: "Somewhat agree.", score: 65 },
                { answer: "Neither agree nor disagree.", score: 35 },
                { answer: "Somewhat disagree.", score: 15 },
                { answer: "Strongly disagree.", score: 0 },
            ],
        },
    ],

    HouseholdIncome: {
        category: "HouseholdIncome",
        question: "What is your approximate annual household income?",
        answerPrefix: "",
        options: [
            { answer: "Less than 50,000 net per annum", score: null },
            { answer: "50,000 - 150,000 net per annum", score: null },
            { answer: "150,000 - 300,000 net per annum", score: null },
            { answer: "300,000 - 500,000 net per annum", score: null },
            { answer: "500,000+", score: null },
        ],
    },

    FinancialStress: {
        category: "FinancialStress",
        question:
            "Over the last 12 months, how much stress, if any, did your finances cause you?",
        answerPrefix: "",
        options: [
            { answer: "High stress", score: null },
            { answer: "Moderate", score: null },
            { answer: "Some stress", score: null },
            { answer: "None", score: null },
        ],
    },

    FinancialStressImpact: {
        category: "FinancialStressImpact",
        question:
            "Over the last 12 months, how often did financial stress impact your daily life?",
        answerPrefix: "",
        options: [
            { answer: "Very often", score: null },
            { answer: "Often", score: null },
            { answer: "Seldom", score: null },
            { answer: "Never", score: null },
        ],
    },
};

export default BaselineAssessmentQuestions;
