import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStore } from '../../../../stores/Store';
import { isMobile } from 'is-mobile';
import { GenericCustomSelect, ErrorCard, IOSToggle } from '../../../../components/basic';
import FlexiblePayBottomNav from '../../../../components/basic/FlexiblePayBottomNav';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { AutohideSnackbar } from '../../../../components/MUI';
import FormGroup from '@mui/material/FormGroup';
import { FlexiblePayLogo2 } from '../../../../assets/icons';

import './styles.scss';

const FlexiblePayHome = observer(() => {

    let navigate = useNavigate();
    const commonStore = useStore();

    const [error, setError] = useState('')
    const [navSelected, setNavSelected] = useState('Home')

    const [max, setMax] = useState(0)
    const [available, setAvailable] = useState(null)
    const [history, setHistory] = useState([])
    const [userBankAccounts, setUserBankAccounts] = useState([])
    const [requestedAmount, setRequestedAmount] = useState("")
    const [primaryBank, setPrimaryBank] = useState(null)
    const [secondaryBank, setSecondaryBank] = useState(null)
    const [selectedBank, setSelectedBank] = useState({})
    const [selectBankVisibile, setSelectBankVisibile] = useState(false)
    const [enterAmountVisible, setEnterAmountVisible] = useState(false)
    const [withdrawalDetails, setWithdrawalDetails] = useState({})
    const [confirmScreen, setConfirmScreen] = useState(false)
    const [authMethod, setAuthMethod] = useState('webauth')
    const [password, setPassword] = useState('')
    const [snackBarOpen, setSnackBarOpen] = useState(false)
    const [snackBarType, setSnackBarType] = useState("")
    const [snackBarContent, setSnackBarContent] = useState("")
    const [immediatePayment, setImmediatePayment] = useState(false)

    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    useEffect(() => {
        reset()
    }, [])

    useEffect(() => {
        handleAmountChange(requestedAmount)
    }, [immediatePayment])

    const reset = () => {
        setImmediatePayment(false)
        setError("")
        setAuthMethod('webauth')
        setPassword('')
        setConfirmScreen(false)
        setWithdrawalDetails({})
        setNavSelected("Home")
        setAvailable(0)
        setRequestedAmount(0)
        setMax(0)
        setSelectedBank({})
        setSelectBankVisibile(false)
        setEnterAmountVisible(false)
        // let id = document.getElementById("default")
        // id.scrollIntoView({ behavior: 'smooth' })
        getAmountAvailable()
    }

    const cancelAdvance = () => {
        setImmediatePayment(false)
        setError("")
        setAuthMethod('webauth')
        setPassword('')
        setConfirmScreen(false)
        setWithdrawalDetails({})
        setNavSelected("Home")
        setSelectedBank({})
        setSelectBankVisibile(false)
        setEnterAmountVisible(false)
    }

    const handleSetImmediatePayment = (e) => {
        setImmediatePayment(e.currentTarget.checked)
    }

    const handleSelect = (val) => {
        setNavSelected(val)
    }

    const resetSnackBar = () => {
        setSnackBarOpen(false)
        setSnackBarType("")
        setSnackBarContent("")
    }

    const handleAmountChange = (val) => {
        setError("")
        let formated = val.replace(/^0+/, '')
        setRequestedAmount(formated)

        let amount = Number(formated)
        let fee = amount * 0.03
        let transferFee = immediatePayment ? Number(15) : Number(7.50)
        let totalFees = fee + (transferFee * 0.15) + transferFee

        if (!commonStore.user.PrimaryBankAccount && !commonStore.user.SecondaryBankAccount) {
            setError("You have no bank accounts loaded, please head to profile to update")
            setEnterAmountVisible(false)
            setSelectBankVisibile(false)
            setSelectedBank({})
            return
        }

        if (amount > 10 && (amount + totalFees) <= available) {
            handleCheckBank()
            setEnterAmountVisible(true)
            return
        }
        else {
            setEnterAmountVisible(false)
            setSelectBankVisibile(false)
            setSelectedBank({})
            return
        }
        forceUpdate()
    }

    const getStatusColor = (status) => {
        switch (status) {
            case "Failed": return 'red'
            case "Pending": return 'orange'
            case "Paid": return 'green'
        }
    }

    const handleAuthenticate = async (e) => {
        e.preventDefault()
        commonStore.setLoading(true)
        let auth = false
        //authenticate
        if (authMethod === 'webauth') {
            try {
                let authenticate = await commonStore.authenticateTransactionWebauthn()
                if (authenticate === 'Password') {
                    setAuthMethod('password')
                    commonStore.setLoading(false);
                } else if (authenticate === true) {
                    auth = true
                } else {
                    setError('Device authentication Failed');
                    commonStore.setLoading(false);
                    return
                }
            } catch (error) {
                console.error(error)
                commonStore.setLoading(false)
                setError("Error occured, please try again")
                return
            }
        }
        else if (authMethod === "password") {
            let ret = await checkPassword()
            if (ret) {
                auth = true
            }
        }

        if (auth) {
            requestBankAdvance(withdrawalDetails)
        }
    }

    const checkPassword = async () => {
        if (!password || password.length < 5) {
            setError("Invalid password")
            return
        }
        try {
            commonStore.setLoading(true)
            const ret = await commonStore.authenticateTransactionPassword(password)
            if (ret === true) {
                commonStore.setLoading(false);
                return true
            } else {
                setError('Authentication Failed');
                commonStore.setLoading(false);
                return false
            }
        } catch (error) {
            console.error(error)
            setError('Authentication Failed');
            commonStore.setLoading(false);
            return false
        }

    }

    const requestBankAdvance = async (advance) => {
        setError('');
        let res = await commonStore.bankAccountAdvance(commonStore.user.Username, advance)
        if (res.status === true) {
            if (res.data.marketplace && res.data.flexiblePay) {
                if (res.data.ozow.transaction.Status === "Pending") {
                    navigate('/employee/checkout/success')
                    reset()
                    return true
                } else if (typeof res.data === 'string') {
                    setError(res.data)
                } else {
                    setError("Error occured, please try again")
                    return false
                }
            } else {
                setError("Error occured, please try again")
                return false
            }
        } else if (res?.data?.includes("Service closed")) {
            setError("Apologies service is currently closed")
            return false
        } else if (res?.data?.includes("allowAdvances")) {
            setError("Apologies, unable to utilise this service at the moment")
            return false
        } else {
            setError("Error occured, please try again")
            return false
        }
    }

    const getAmountAvailable = async () => {
        setError('');
        let res = await commonStore.calculateAmountAvailable(commonStore.user.Username)
        if (res.status === true) {
            let fpData = res.response
            setAvailable(fpData.allowed)
            setMax(fpData.available)
            let hist = []
            if (fpData.history.length > 0) {
                //descending dates, slice apparently creates a shallow copy to avoid mutation
                //that wouldve been useful a month ago
                hist = fpData.history.slice().sort((a, b) => new Date(b.Date) - new Date(a.Date))
            }
            setHistory(hist)
        }
        else {
            console.log("Amount available get error")
            commonStore.setLoading(false)
            setError("Unable to utilise Flexible pay, please contact admin")
            navigate('/employee/home')
            return
        }
        forceUpdate()
    }

    const handleCheckBank = () => {
        setError('');
        let options = []
        if (commonStore.user) {
            if (commonStore.user.PrimaryBankAccount) {
                setPrimaryBank(commonStore.user.PrimaryBankAccount)
                let bnk = commonStore.user.PrimaryBankAccount
                const option = { ...bnk, ...{ name: `${bnk.BankName} ... ${bnk.AccountNumber.slice(-4)}`, key: 'primary' } }
                options.push(option)
                if (commonStore.user.SecondaryBankAccount) {
                    setSecondaryBank(commonStore.user.SecondaryBankAccount)
                    let bnk = commonStore.user.SecondaryBankAccount
                    const option = { ...bnk, ...{ name: `${bnk.BankName} ... ${bnk.AccountNumber.slice(-4)}`, key: 'secondary' } }
                    options.push(option)
                }
                setEnterAmountVisible(false)
                setUserBankAccounts(options)
                forceUpdate()
                setSelectBankVisibile(true)
                // let id = document.getElementById("banks-ref")
                // id.scrollIntoView({ behavior: 'smooth' })
            }
            else {
                console.log("No primary bank account")
                setError("Error occured, please add a primary bank account to your profile")
                return
                //has no primary bank account
            }

        }
    }

    const handleCreateCartItem = () => {
        setError('');
        let amount = Number(requestedAmount)
        let fee = amount * 0.03
        let transferFee = immediatePayment ? Number(15) : Number(7.50)
        let vat = Number(transferFee * 0.15)
        let total = amount + fee + transferFee + vat
        let nett = commonStore.user.SalaryAmount || 0
        let alreadyWithdrawn = Number(max - available)
        let nextNett = Number(nett) - (alreadyWithdrawn + total)

        const randomNumber = Math.floor(100000 + Math.random() * 900000);

        let cartItem = {
            Id: randomNumber,
            User: commonStore.user._id,
            IDNumber: commonStore.user.Username,
            Username: commonStore.user.Username,
            Client: commonStore.user.Client._id,
            Description: `User bank account flexible pay withdrawal`,
            Product: "Flexible pay",
            Type: 'EWA',
            NextPay: nextNett,
            Date: new Date(),
            Amount: amount,
            Fee: fee,
            TransferFee: transferFee,
            TotalAmount: total,
            VAT: vat,
            BankAccount: selectedBank,
            Immediate: immediatePayment,
            DeviceInfo: navigator.userAgent
        }

        setWithdrawalDetails(cartItem)

        forceUpdate()
        setConfirmScreen(true)

    }

    const handleBankChange = async (type) => {
        setError("")

        let bank = type === "primary" ? primaryBank : secondaryBank

        if (bank.Verified) {
            setSelectedBank(bank)
            forceUpdate()
            return;
        }

        const isVerified = await verifyBank(type, bank)
        if (isVerified) {
            setSelectedBank(bank)
        } else {
            setSelectedBank({})
            setError("Couldn't verify bank account, please update in profile")
            return
        }

        forceUpdate()
    }

    const verifyBank = async (type, bank) => {
        try {
            if (!bank.AccountNumber || !bank.BankName || !bank.BranchCode || !bank.Type) {
                console.log("incomplete bank")
                setError("Bank account incomplete, please go to profile to update banking details")
                return false
            }
            let args = {
                type: type,
                bank: bank,
                verifiedBy: `${commonStore.user.Username} through requesting flexible pay advance`
            }
            commonStore.setLoading(true)
            let res = await commonStore.verifyUserBankAccount(commonStore.user.Username, args)
            commonStore.setLoading(false)
            if (res.success) {
                return true
            }
            else {
                commonStore.setLoading(false)
                setError("Error occured, please go to profile and verify that your banking details are correct")
                reset()
                return false
            }
        } catch (error) {
            console.error(error)
            commonStore.setLoading(false)
            reset()
            setError("Error occured, please try again")
            return false
        }
    }

    const handlePasswordChange = (e) => {
        setError("")
        setPassword(e.currentTarget.value)
    }

    if (isMobile()) {
        return (
            <div>
                <AutohideSnackbar content={snackBarContent} open={snackBarOpen} type={snackBarType} setSnackBarClose={resetSnackBar} />

                <div className='benefit-dash-fixed-top-div' style={{ display: 'flex', padding: '30px 10px', width: '100%', justifyContent: 'space-between' }}>
                    <img src={FlexiblePayLogo2} style={{ width: '200px' }} />
                </div>
                {!confirmScreen ?
                    <main style={{ alignItems: 'center', background: '#cccccc45' }}>
                        {/* SELECT WITHDRAW AMOUNT */}

                        <div>
                            <main style={{ background: '#FFF', borderRadius: '10px' }}>
                                <div id="default">
                                    <p>Access your pay</p>
                                    <hr style={{ width: '100%' }} />

                                    {available !== null && max !== null && (
                                        <svg viewBox="13 0 100 75" xmlns="http://www.w3.org/2000/svg">
                                            <defs>
                                                <linearGradient id="gradient" x1="0" y1="0" x2="1" y2="0">
                                                    <stop offset="0%" stopColor="#3D2DED" />
                                                    <stop offset="100%" stopColor="#B138FB" />
                                                </linearGradient>
                                                <filter
                                                    id="filter0_d_2414_15661"
                                                    x="-10%"
                                                    y="-10%"
                                                    width="120%"
                                                    height="120%"
                                                    filterUnits="userSpaceOnUse"
                                                    colorInterpolationFilters="sRGB"
                                                >
                                                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                                    <feColorMatrix
                                                        in="SourceAlpha"
                                                        type="matrix"
                                                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                        result="hardAlpha"
                                                    />
                                                    <feOffset dx="0" dy="0" />
                                                    <feGaussianBlur stdDeviation="10" />
                                                    <feComposite in2="hardAlpha" operator="out" />
                                                    <feColorMatrix
                                                        type="matrix"
                                                        values="0 0 0 0 0.24 0 0 0 0 0.17 0 0 0 0 0.93 0 0 0 0.7 0"
                                                    />
                                                    <feBlend
                                                        mode="normal"
                                                        in2="BackgroundImageFix"
                                                        result="effect1_dropShadow_2414_15661"
                                                    />
                                                    <feBlend
                                                        mode="normal"
                                                        in="SourceGraphic"
                                                        in2="effect1_dropShadow_2414_15661"
                                                        result="shape"
                                                    />
                                                </filter>
                                            </defs>

                                            {/* Background Grey Path */}
                                            <path className="grey" d="M22 61A40 40 0 11104 61" fill="none" stroke="#e0e0e0" strokeWidth="8" />

                                            {/* Animated Blue Path */}
                                            <g filter="url(#filter0_d_2414_15661)">
                                                <path
                                                    className="blue"
                                                    fill="none"
                                                    stroke="url(#gradient)"
                                                    strokeWidth="8"
                                                    strokeDasharray="130"
                                                    strokeDashoffset="130" // Start fully hidden
                                                    style={{
                                                        transition: "stroke-dashoffset 2s ease-out",
                                                        strokeDashoffset: `${130 - (130 * (parseInt(available) / parseInt(max)))}` // End value
                                                    }}
                                                    d="M22 61A40 40 0 11104 61"
                                                />
                                            </g>

                                            {/* Available Amount */}
                                            <text
                                                className="withdrawTxtHead"
                                                x="64%"
                                                y="65%"
                                                dominantBaseline="middle"
                                                textAnchor="middle"
                                            >
                                                {`R ${parseFloat(available).toLocaleString(undefined, { minimumFractionDigits: 2 })}`}
                                            </text>

                                            {/* Description */}
                                            <text
                                                className="withdrawTxtSub"
                                                x="64%"
                                                y="80%"
                                                dominantBaseline="middle"
                                                textAnchor="middle"
                                            >
                                                Available balance
                                            </text>
                                        </svg>
                                    )}

                                    <p>Enter an amount you would like to withdraw to your bank account</p>
                                    {error && <ErrorCard type='error' message={error} />}
                                </div>
                                <FormControl fullWidth>
                                    <InputLabel style={{ fontFamily: "Inter" }} htmlFor="outlined-adornment-amount">Amount</InputLabel>
                                    <OutlinedInput
                                        style={{ fontFamily: "Inter" }}
                                        id="outlined-adornment-amount"
                                        startAdornment={<InputAdornment position="start">R</InputAdornment>}
                                        label="Amount" value={requestedAmount} onChange={(e) => handleAmountChange(e.target.value)}
                                    />
                                </FormControl>
                                <FormGroup>
                                    <FormControlLabel style={{ fontSize: '13px', fontWeight: 400 }}
                                        control={
                                            <IOSToggle checked={immediatePayment} onChange={handleSetImmediatePayment} sx={{ m: 1 }} />
                                        }
                                        label="Immediate payment"
                                    />
                                </FormGroup>
                                <p className='text-[10px] text-bold'>
                                    {immediatePayment ?
                                        "Immediate* (+15.00 fee): Payments make take up to 48 hours to reflect, and will not be processed on Weekends and Public holidays."
                                        :
                                        "Non-immediate* (+7.50 fee): Payments make take up to 1-3 days to reflect, and will not be processed on Weekends and Public holidays."}
                                </p>
                                {enterAmountVisible &&
                                    <div>
                                        <p>Where would you like us to pay the withdrawal?</p>
                                        <GenericCustomSelect
                                            options={userBankAccounts}
                                            value={selectedBank.BankName ? `${selectedBank.BankName} ... ${selectedBank.AccountNumber.slice(-4)}` : "Select option"}
                                            onChange={(e) => handleBankChange(e.key)}
                                        />
                                        <br />

                                        {selectedBank.BankName &&
                                            <button className='flexible-pay-dash-btn' onClick={handleCreateCartItem}>Withdraw</button>
                                        }
                                    </div>
                                }
                            </main>
                            <br />
                            <div id='banks-ref'>

                            </div>
                            <br />

                            <div>
                                <p>Withdrawal history</p>
                                <br />
                                {history.length > 0 ?
                                    <div className='flex-column' style={{ gap: '6px' }}>
                                        {history.map((adv) => (
                                            <div key={adv._id} className='flexible-pay-history-item-container'>
                                                <div>
                                                    <p style={{ margin: 0 }}>{`Date: ${new Date(adv.Date).toLocaleDateString()}`}</p>
                                                    <p style={{ color: getStatusColor(adv.Status), margin: 0 }}>{`${adv.Status}`}</p>
                                                </div>

                                                <p style={{ fontSize: '15px', margin: 0 }}>{`R ${(adv.Amount + adv.Fee).toLocaleString()}`}</p>
                                            </div>
                                        ))}
                                    </div>

                                    :
                                    <div>No advances to date</div>
                                }
                            </div>
                        </div>
                        <FlexiblePayBottomNav setPage={handleSelect} />
                    </main> :
                    <main style={{ alignItems: 'center', background: '#fff' }}>
                        <p>You're about to use some money from your earned wages.</p>
                        {withdrawalDetails &&
                            <>
                                <main style={{ border: '1px solid #DADADA', borderRadius: '12px', padding: 0 }}>
                                    <div className="withdrawTxtHead" style={{ padding: '15px', fontSize: '24px', fill: 'none', color: '#3734C8', borderBottom: '1px solid #E2E2E2' }} >
                                        {`R ${parseFloat(withdrawalDetails.Amount).toLocaleString(undefined, { minimumFractionDigits: 2 })}`}
                                    </div>
                                    <div className='confirm-advance-table-item'>
                                        <p style={{ color: '#525252' }}>Advance fee</p>
                                        <p style={{ fontWeight: 600 }}>{`R ${withdrawalDetails.Fee.toFixed()}`}</p>
                                    </div>
                                    <div className='confirm-advance-table-item'>
                                        <p style={{ color: '#525252' }}>Transfer fee</p>
                                        <p style={{ fontWeight: 600 }}>{`R ${withdrawalDetails.TransferFee.toFixed(2)}`}</p>
                                    </div>
                                    <div className='confirm-advance-table-item'>
                                        <p style={{ color: '#525252' }}>VAT</p>
                                        <p style={{ fontWeight: 600 }}>{`R ${withdrawalDetails.VAT.toFixed(2)}`}</p>
                                    </div>
                                    <div className='confirm-advance-table-item'>
                                        <p style={{ color: '#525252' }}>Total deduction from next pay</p>
                                        <p style={{ fontWeight: 600 }}>{`R ${withdrawalDetails.TotalAmount.toFixed(2)}`}</p>
                                    </div>
                                    <div className='confirm-advance-table-item'>
                                        <p style={{ color: '#525252' }}>Transfer type</p>
                                        <p style={{ fontWeight: 600 }}>{withdrawalDetails.Immediate ? "Immediate" : "Non-immediate"}</p>
                                    </div>
                                    <div className='confirm-advance-table-item'>
                                        <p style={{ color: '#525252' }}>Nett pay next month</p>
                                        <p style={{ fontWeight: 600 }}>{`R ${withdrawalDetails.NextPay.toFixed(2)}`}</p>
                                    </div>
                                    <div className='confirm-advance-table-item noborder'>
                                        <p style={{ color: '#525252' }}>Date</p>
                                        <p style={{ fontWeight: 600 }}>{withdrawalDetails.Date.toLocaleDateString()}</p>
                                    </div>
                                </main>
                                <br />
                                {authMethod === "password" &&
                                    <>
                                        <p className=''>Authenticate your advance</p>
                                        <form onSubmit={handleAuthenticate} className='w-full'>
                                            <TextField
                                                style={{ fontFamily: "Inter" }}
                                                fullWidth
                                                value={password}
                                                onChange={handlePasswordChange}
                                                id="outlined-password-input"
                                                label="Password"
                                                type="password"
                                            // autoComplete="current-password"
                                            />
                                        </form>
                                    </>
                                }
                                {error && <ErrorCard type='warning' message={error} />}
                                <div className='flex flex-row gap-2'>
                                    <button onClick={handleAuthenticate} className='flexible-pay-dash-btn'>Confirm</button>
                                    <button onClick={() => cancelAdvance()} className='flexible-pay-dash-btn inverted'>Cancel</button>
                                </div>
                            </>
                        }
                    </main>
                }
            </div>
        );
    } else {
        return (
            <div>

            </div>
        );
    }
})

export default FlexiblePayHome
