import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../../stores/Store'
import { isMobile } from 'is-mobile';
import { Loader } from '../../../../components/MUI';
import AdminBenefitsFlexiblePayUsers from './users';
import AdminBenefitsFlexiblePayClients from './clients';
import UserDoughnutChart from '../../../../components/charts/UserDoughnut';

import './styles.scss';

const AdminBenefitsFlexiblePay = observer(({ backHome }) => {

    let navigate = useNavigate();
    const commonStore = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const [filter, setFilter] = useState('home')
    const [fpUsers, setFpUsers] = useState([])
    const [fpClients, setFpClients] = useState([])
    const [mpUsers, setMpUsers] = useState([])
    const [mpClients, setMpClients] = useState([])

    const [fpUsersLoading, setFpUsersLoading] = useState(false)
    const [fpClientsLoading, setFpClientsLoading] = useState(false)
    const [mpUsersLoading, setMpUsersLoading] = useState(false)

    useEffect(() => {
        loadAllData()
    }, [])

    const loadAllData = async () => {
        commonStore.setLoading(true);

        try {
            const [fpUsers, fpClients, mpUsers, mpClients] = await Promise.all([
                commonStore.getFlexiblePayUsersNoPop(),
                commonStore.getFlexiblePayClients(),
                commonStore.getAllUsersNoPop(),
                commonStore.getAllClients(),
            ]);
            setFpClients(fpClients.status === true ? fpClients.response || [] : [])
            setMpClients(mpClients ? mpClients || [] : [])
            await populateFlexiblePayUsers(fpUsers, mpUsers, fpClients, mpClients)
        } catch (error) {
            commonStore.setSnackBar("error", "Error loading reportingdata")
            console.error("Error loading data:", error);
            navigate(-1)
        } finally {
            commonStore.setLoading(false);
        }
    };

    const populateFlexiblePayUsers = async (fpUsers, mpUsers, fpClients, mpClients) => {
        console.log("ran")
        if (fpUsers.response?.length > 0 && mpUsers?.length > 0) {
            let mp = mpUsers || []
            let fp = fpUsers.response || []
            let arr = []
            let fpCli = fpClients.response || []
            setFpClients(fpCli)
            const getClientObj = (id) => {
                let result = mpClients.find(mpCliItem => mpCliItem._id === id);
                return result || {}
            }

            if (fp.length > 0) {
                arr = fp.map((fpItem) => {
                    let result = mp.find(mpItem => fpItem.MarketplaceUserId === mpItem._id);
                    if (result) {
                        console.log("man pop", result)

                        return {
                            ...fpItem,
                            SalaryAmount: result.SalaryAmount,
                            PrimaryBankAccount: result.PrimaryBankAccount,
                            SecondaryBankAccount: result.SecondaryBankAccount,
                            Client: getClientObj(result.Client)
                        }
                    }
                    return null
                }).filter(item => item !== null)
            }

            setMpUsers(mp)
            setFpUsers(arr)
            forceUpdate()
        }
        else {
            commonStore.setSnackBar("error", "Error retrieving flexible pay reporting")
            backHome()
            return
        }

    }

    const handleSelectFilter = (e) => {
        setFilter(e.currentTarget.id)
    }

    if (isMobile()) {
        return (
            <div style={{ background: "#F4F5FA", width: '100%' }}>

            </div>
        );
    } else {
        return (
            <main style={{ background: "#F4F5FA", width: '100%' }}>
                <div className='flex flex-row gap-3'>
                    <button className='admin-dash-back-arrow-btn' id='home' onClick={() => backHome()}>Back</button>
                    <header className='admin-dash-option-header'>{`FlexiblePay`}</header>
                </div>
                <main style={{ background: "#FFFFFF", borderRadius: '20px', padding: '35px' }}>

                    <div className='flex gap-1 margin-2'>
                        <div id="home" onClick={handleSelectFilter} className={`client-benefits-filter-item ${filter === "home" ? 'selected' : null}`}>
                            Home
                        </div>
                        <div id="users" onClick={handleSelectFilter} className={`client-benefits-filter-item ${filter === "users" ? 'selected' : null}`}>
                            Users
                        </div>
                        {/* <div id="advances" onClick={handleSelectFilter} className={`client-benefits-filter-item ${filter === "advances" ? 'selected' : null}`}>
                            Advances
                        </div> */}
                        <div id="clients" onClick={handleSelectFilter} className={`client-benefits-filter-item ${filter === "clients" ? 'selected' : null}`}>
                            Clients
                        </div>
                    </div>

                    {filter === "home" &&
                        <main>
                            <main style={{ background: "#FFFFFF", borderRadius: '20px', padding: '35px' }}>
                                {/* TOP DIV */}
                                <div className='flex flex-row w-full gap-10'>
                                    {mpUsers.length > 0 &&
                                        <div className='benefit-utilisation-graph-container'>
                                            <div className='flex flex-column gap-2 align-center'>
                                                <p>Active EWA users</p>
                                                <div>
                                                    <p style={{ fontSize: '10px', margin: 0 }}>Active users</p>
                                                    <div className='benefit-utilisation-graph-out-off-text'>{`${fpUsers.length}/${mpUsers.length}`}</div>
                                                </div>
                                            </div>
                                            <UserDoughnutChart current={fpUsers} total={mpUsers} currentColor={'#0062FF'} totalColor={'#BED8FF'} />
                                        </div>
                                    }
                                    {fpClients.length > 0 &&
                                        <div className='benefit-utilisation-graph-container'>
                                            <div className='flex flex-column gap-2 align-center'>
                                                <p>Active EWA clients</p>
                                                <div>
                                                    <p style={{ fontSize: '10px', margin: 0 }}>Active clients</p>
                                                    <div style={{ color: '#D77519' }} className='benefit-utilisation-graph-out-off-text'>{`${fpClients.length}/${mpClients.length}`}</div>
                                                </div>
                                            </div>
                                            <UserDoughnutChart current={fpClients} total={mpClients} currentColor={'#D77519'} totalColor={'#FFE4CB'} />
                                        </div>
                                    }

                                </div>

                                <br />
                            </main>
                        </main>
                    }
                    {filter === "users" &&
                        <AdminBenefitsFlexiblePayUsers data={{ fpUsers }} setVisible={handleSelectFilter} refresh={loadAllData} />
                    }
                    {filter === "clients" &&
                        <AdminBenefitsFlexiblePayClients data={{ fpClients }} setVisible={handleSelectFilter} refresh={loadAllData} />
                    }

                </main>
            </main>
        );
    }
})

export default AdminBenefitsFlexiblePay
