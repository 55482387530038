import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store'
import { isMobile } from 'is-mobile';
import { SearchIcon } from '../../../assets/icons/index'
import AdminReportingHome from './home'
import AdminReportingTransactions from './transactions';
import AdminReportingInvoices from './invoices'

import './styles.scss';
import { common } from '@mui/material/colors';
import { Alert, TextField } from '@mui/material';

const AdminReporting = observer(() => {

    let navigate = useNavigate();
    const commonStore = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const [error, setError] = useState('');
    const [filter, setFilter] = useState('home')
    const [reporting, setReports] = useState({})

    const reset = () => {
        setFilter("home")
    }

    useEffect(() => {
        // getBulkReports()
        loadAllData()
    }, [])
    const loadAllData = async () => {
        commonStore.setLoading(true);

        try {
            const [clients, invoices, users] = await Promise.all([
                commonStore.getAllClients(),
                commonStore.getInvoicesNoPop(),
                commonStore.getAllUsersNoPop()
            ]);

            const finalObj = {
                clients,
                invoices,
                payrollLogs: [],
                users
            };

            setReports(finalObj);
        } catch (error) {
            commonStore.setSnackBar("error", "Error loading reportingdata")
            console.error("Error loading data:", error);
            navigate(-1)
        } finally {
            commonStore.setLoading(false);
        }
    };

    const handleSelectFilter = (e) => {
        setFilter(e.currentTarget.id)
    }

    if (isMobile()) {
        return (
            <div style={{ background: "#F4F5FA", width: '100%' }}>

            </div>
        );
    } else {
        return (
            <main style={{ background: "#F4F5FA", width: '100%' }}>
                <header className='admin-dash-option-header'>{`Admin Reporting`}</header>
                <main style={{ background: "#FFFFFF", borderRadius: '20px', padding: '35px' }}>

                    <div className='flex gap-1 margin-2 items-center'>
                        <div id="home" onClick={handleSelectFilter} className={`client-benefits-filter-item ${filter === "home" ? 'selected' : null}`}>
                            Home
                        </div>
                        <div id="transactions" onClick={handleSelectFilter} className={`client-benefits-filter-item ${filter === "transactions" ? 'selected' : null}`}>
                            Transactions
                        </div>
                        <div id="invoices" onClick={handleSelectFilter} className={`client-benefits-filter-item ${filter === "invoices" ? 'selected' : null}`}>
                            Invoices
                        </div>
                        <div id="logs" onClick={handleSelectFilter} className={`client-benefits-filter-item ${filter === "logs" ? 'selected' : null}`}>
                            Logs
                        </div>
                        <div>
                            <i style={{ cursor: 'pointer' }} onClick={() => loadAllData()} class="fa-solid fa-arrow-rotate-right"></i>
                        </div>
                    </div>

                    {filter === "home" &&
                        <AdminReportingHome data={reporting} setVisible={handleSelectFilter} refresh={loadAllData} />
                    }

                    {filter === "transactions" &&
                        <AdminReportingTransactions data={reporting} setVisible={handleSelectFilter} refresh={loadAllData} />
                    }

                    {filter === "invoices" &&
                        <AdminReportingInvoices data={reporting} setVisible={handleSelectFilter} refresh={loadAllData} />
                    }

                </main>
            </main>
        );
    }
})

export default AdminReporting
