import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Button } from '../../../components/basic/index'
import { BackArrow } from '../../../assets/icons/index'

import './styles.scss';

const EmployeeShop = observer(() => {

    let navigate = useNavigate();
    const commonStore = useStore();

    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [visible, setVisible] = useState('home')

    useEffect(() => {
        getUser()
    }, [])

    const getUser = async () => {

    }

    const handleOpenFinHealth = () => {
        setVisible("fin-health")
    }

    if (isMobile()) {
        return (
            <main>
                {visible === 'home' &&
                    <div>
                        <div style={{ marginBottom: '10px' }} className='back-arrow-btn' onClick={() => navigate("/employee/home")}>
                            <img src={BackArrow} />
                            <div>Back</div>
                        </div>
                        <br/>
                        <header style={{margin:0}} className='employee-dash-header'>{'Level finance '}<span className='text-[#EA2199]'>SHOP</span>{' coming soon'}</header>
                    </div>

                }

            </main>
        );
    } else {
        return (
            <main>
                <header className='employee-dash-header'>Shop</header>
            </main>
        );
    }
})

export default EmployeeShop
