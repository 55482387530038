import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../../stores/Store'
import { isMobile } from 'is-mobile';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { DateRange } from '../../../../components/Next UI';
import { getLocalTimeZone } from "@internationalized/date";
import allBenefits from '../../../../components/benefits';

import './styles.scss';
import { Alert, TextField } from '@mui/material';

const AdminReportingTransactions = observer(({ data, setVisible }) => {

    let navigate = useNavigate();
    const commonStore = useStore();
    const [visibleChild, setVisibleChild] = useState('home')
    const [productFilter, setProductFilter] = useState('all')
    const [statusFilter, setStatusFilter] = useState('all')
    const [clientFilter, setClientFilter] = useState('all')
    const [dateRange, setDateRange] = useState([new Date(Date.now() - 25 * 24 * 60 * 60 * 1000), new Date()]);
    const [transactions, setTransactions] = useState([])
    const [filteredTransactions, setFilteredTransactions] = useState([])
    const [clients, setClients] = useState(data.clients || [])
    const [users, setUsers] = useState(data.users || [])
    const [openTransactionActions, setOpenTransactionActions] = useState(null)
    const [userPermissions, setUserPermissions] = useState(commonStore.user.Permissions || [])
    const [getTransactionsButtonVisible, setGetTransactionsButtonVisible] = useState(false)

    // Pagination states
    const [currentPage, setCurrentPage] = useState(1);
    const transactionsPerPage = 50;

    const handleTransactionOpen = (invoiceId) => {
        reset()
        setOpenTransactionActions((prev) => (prev === invoiceId ? null : invoiceId))
    }

    const transactionStatuses = ["Paid", "Pending", "Failed"]

    useEffect(() => {
        handleFilterTransactions()
    }, [clientFilter, productFilter, dateRange, statusFilter])

    useEffect(() => {
        setTransactions(transactions?.sort((a, b) => new Date(b.Date) - new Date(a.Date)) || [])
        setFilteredTransactions(transactions?.sort((a, b) => new Date(b.Date) - new Date(a.Date)) || [])
        setCurrentPage(1); // Reset to first page when transactions change
    }, [transactions])

    const getStatusColor = (status) => {
        switch (status) {
            case "Failed": return 'red'
            case "Pending": return 'orange'
            case "Paid": return 'green'
            default: return 'black'
        }
    }

    const handleSelectProductFilter = (e) => {
        setProductFilter(e.target.value)
        setCurrentPage(1); // Reset to first page when filter changes
    }
    const handleSelectStatusFilter = (e) => {
        setStatusFilter(e.target.value)
        setCurrentPage(1); // Reset to first page when filter changes
    }
    const handleSelectClientFilter = (e) => {
        const selectedClient = e.target.value;
        setClientFilter(selectedClient);
        setCurrentPage(1);
        if (selectedClient !== "all") {
            setGetTransactionsButtonVisible(true);
        } else {
            setGetTransactionsButtonVisible(false);
        }
    }

    const handleDateRangeOnChange = (date) => {
        setDateRange([
            date.start.toDate(getLocalTimeZone()),
            date.end.toDate(getLocalTimeZone())
        ]);
        setCurrentPage(1); // Reset to first page when date range changes
    }

    const handleFilterTransactions = () => {
        let filtered = transactions.filter((trans) => {
            if ((trans.Product === productFilter || productFilter === "all")
                &&
                ((trans.Client) === clientFilter || clientFilter === "all")
                &&
                (trans.Status === statusFilter || statusFilter === "all")
                &&
                (new Date(trans.Date) >= dateRange[0] && new Date(trans.Date) <= dateRange[1])) {
                return true
            }
            else {
                return false
            }

        })
        setFilteredTransactions(filtered)
        setCurrentPage(1); // Reset to first page when transactions are filtered
    }

    const getUserObj = (id) => {
        let user = users.find((user) => user._id === id)
        return user || {}
    }
    const getClientObj = (id) => {
        let client = clients.find((cli) => cli._id === id)
        return client || {}
    }

    const reset = () => {
        setVisibleChild("home")
    }

    // Calculate total pages
    const totalPages = Math.ceil(filteredTransactions.length / transactionsPerPage);

    // Get current transactions
    const indexOfLastTransaction = currentPage * transactionsPerPage;
    const indexOfFirstTransaction = indexOfLastTransaction - transactionsPerPage;
    const currentTransactions = filteredTransactions.slice(indexOfFirstTransaction, indexOfLastTransaction);

    // Handle page change
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handleGetTransactionsByClient = async () => {
        let id = clientFilter;
        if (id === "all") {
            commonStore.setSnackBar("warning", "Select a client to get transactions");
            return;
        } else {
            let res = await commonStore.getAllTransactionsByClient(id);
            if (res) {
                setTransactions(res)
            } else {
                commonStore.setSnackBar("error", "Error fetching transactions");
            }
        }
    }

    if (isMobile()) {
        return (
            <div style={{ background: "#F4F5FA", width: '100%' }}>

            </div>
        );
    } else {
        return (
            <>
                {visibleChild === "home" &&
                    <div>
                        <div style={{ background: "#FFFFFF", borderRadius: '20px' }}>
                            <div className='flex justify-between margin-0.5'>
                                <p style={{ fontSize: '15px', color: 'gray' }}>{`Total: ${filteredTransactions.length} filtered transactions`}</p>
                                <div className='flex flex-row gap-2'>
                                    <p style={{ fontSize: '13px', color: 'red' }} className='font-size-2 cursor-pointer'>{`Generate PDF `}<i className="fa-regular fa-file-pdf"></i></p>
                                    <p style={{ fontSize: '13px', color: 'green' }} className='font-size-2 cursor-pointer'>{`Generate Excel `}<i className="fa-regular fa-file-excel"></i></p>
                                </div>

                            </div>
                            <br />
                            {/* TOP DIV */}
                            <div className='admin-reporting-fitler-container'>
                                <hr />
                                <div className='flex gap-3 items-center flex-wrap gap-1'>
                                    <DateRange value={dateRange} onChange={handleDateRangeOnChange} />
                                    {clients.length > 0 &&
                                        <>
                                            <div style={{ borderLeft: '1px solid #000' }}></div>
                                            <div className='flex gap-2'>
                                                {/* CLIENTS */}
                                                <FormControl style={{ width: '200px' }}>
                                                    <InputLabel id="client-select-label">Clients</InputLabel>
                                                    <Select labelId="client-select-label" id="client-select"
                                                        value={clientFilter} label="Filter" onChange={handleSelectClientFilter} style={{ fontSize: '13px' }}>
                                                        <MenuItem value={'all'}>All clients</MenuItem>
                                                        {clients.map((cli) => (
                                                            <MenuItem value={cli._id} key={cli._id}>{cli.Name}</MenuItem>
                                                        ))
                                                        }
                                                    </Select>
                                                </FormControl>
                                            {getTransactionsButtonVisible && 
                                                <div style={{margin:'auto'}} onClick={handleGetTransactionsByClient} className={`client-benefits-filter-item selected`}>
                                                    Get transactions
                                                </div>
                                            }
                                                {/* STATUS */}
                                                <FormControl style={{ width: '200px' }}>
                                                    <InputLabel id="status-select-label">Status</InputLabel>
                                                    <Select labelId="status-select-label" id="status-select"
                                                        value={statusFilter} label="Filter" onChange={handleSelectStatusFilter} style={{ fontSize: '13px' }}>
                                                        <MenuItem value={'all'}>All statuses</MenuItem>
                                                        {transactionStatuses.map((stat) => (
                                                            <MenuItem value={stat} key={stat}>{stat}</MenuItem>
                                                        ))
                                                        }
                                                    </Select>
                                                </FormControl>
                                                {/* PRODUCTS */}
                                                <FormControl style={{ width: '200px' }}>
                                                    <InputLabel id="product-select-label">Benefits</InputLabel>
                                                    <Select labelId="product-select-label" id="product-select"
                                                        value={productFilter} label="Filter" onChange={handleSelectProductFilter} style={{ fontSize: '13px' }}>
                                                        <MenuItem value={'all'}>All benefits</MenuItem>
                                                        {allBenefits.map((ben) => (
                                                            <MenuItem value={ben.Name} key={ben.Name}>{ben.Name}</MenuItem>
                                                        ))
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                            <br />
                            {transactions.length > 0 &&
                                (<div className='flex-column gap-2'>
                                    {/* Pagination Controls */}
                                    <div className='employee-benefits-assessment-submit-header' style={{ width: '100%', fontSize: '20px', display: 'flex', justifyContent: 'space-evenly', padding: '20px 0px' }}>
                                        <div className='cursor-pointer' onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>{"<"}</div>
                                        <span>Page {currentPage} of {totalPages}</span>
                                        <div className='cursor-pointer' onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages}>{">"}</div>
                                    </div>
                                    {currentTransactions.map((trans, index) => (
                                        <div key={index} className='reports-invoice-item-container'>
                                            <div className='reports-invoice-item-container-section-1'>
                                                <div>
                                                    <p>{`${getUserObj(trans.User)?.FirstName || "----"} ${getUserObj(trans.User)?.LastName || "----"}`}</p>
                                                    <p>{`${getUserObj(trans.User)?.Username || "No username"}`}</p>
                                                    <p>{`${getClientObj(trans.Client).Name || "No client"}`}</p>
                                                </div>

                                                <p style={{ color: `${getStatusColor(trans.Status)}` }}>{trans.Status || "No status"}</p>
                                                <p>{`R ${trans.TotalAmount?.toLocaleString()}` || "No total amount"}</p>
                                                <p>{new Date(trans.Date)?.toLocaleDateString() || "No date"}</p>
                                                <p id={trans._id} onClick={() => handleTransactionOpen(trans._id)} className='text-[15px] cursor-pointer'>{"..."}</p>
                                            </div>
                                            {openTransactionActions === trans._id && (
                                                <>
                                                    <div className='invoice-actions-section'>
                                                        {trans.Notes?.length > 0 &&
                                                            <div className='flex gap-1'>
                                                                <div className='flex gap-1 flex-column'>
                                                                    <p>Notes: </p>
                                                                    {trans.Notes.map((nt, idx) => (
                                                                        <p key={idx} className='text-[11px] font-light'>{`${new Date(nt.date).toLocaleDateString()} - ${nt.user} - ${nt.note}`}</p>
                                                                    ))}
                                                                </div>
                                                            </div>}
                                                        {trans.PayslipStatus ?
                                                            <p>{`Payslip status: `}<span className='text-[11px] font-light'>{trans.PayslipStatus.status}</span></p>
                                                            :
                                                            <p>{`Payslip status: `}<span className='text-[11px] font-light'>Not written</span></p>
                                                        }
                                                        {trans.InvoiceStatus?.appended === true ?
                                                            <p>{`Invoice status: `}<span className='text-[11px] font-light'>Added to invoice</span></p>
                                                            :
                                                            <p>{`Invoice status: `}<span className='text-[11px] font-light'>Not in invoice</span></p>
                                                        }
                                                        <p>{`Description: `}<span className='text-[11px] font-light'>{trans.Description || ""}</span></p>
                                                        <p>{`Message: `}<span className='text-[11px] font-light'>{trans.Message || ""}</span></p>
                                                        <p>{`Transaction ID: `}<span className='text-[11px] font-light'>{trans.TransactionID || ""}</span></p>
                                                        <p>{`Reference: `}<span className='text-[11px] font-light'>{trans.Reference || ""}</span></p>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    ))
                                    }

                                </div>)
                            }
                            <br />
                        </div>
                    </div>
                }
            </>
        );
    }
})

export default AdminReportingTransactions
