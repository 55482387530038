import React from "react";
import { Page, Text, Image, Document, StyleSheet, View, Font } from "@react-pdf/renderer";
import { LevelBlueWhiteLogoPng } from "../../assets/icons";

// Register the Roboto font
Font.register({
    family: 'Roboto',
    fonts: [
        {
            src: 'https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Mu4mxP.ttf', // Roboto Regular
            fontStyle: 'normal',
            fontWeight: 400,
        },
        {
            src: 'https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmWUlfBBc9.ttf', // Roboto Bold
            fontWeight: 700,
        },
    ],
});

const BORDER_COLOR = '#bfbfbf';
const BORDER_STYLE = 'solid';
const COLN_WIDTH = 100 / 6;

const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
        fontFamily: 'Roboto',  // Set fontFamily here for the entire document
    },
    HeaderText: {
        fontSize: 11,
        fontFamily: 'Roboto', // Ensure this is applied to all text styles
    },
    ClientHeaderText: {
        fontSize: 11,
        fontFamily: 'Roboto',
        // textDecoration: "underline",
    },
    HeaderTextRight: {
        fontSize: 11,
        textAlign: 'right',
        fontFamily: 'Roboto',
    },
    SubHeaderText: {
        fontWeight: 700,
        fontSize: 11,
        fontFamily: 'Roboto',
    },
    logo: {
        width: 100,
    },
    table: {
        display: "table",
        width: "auto",
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        marginTop: 15,
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row",
    },
    tableColHeader: {
        backgroundColor: "#010143",
        width: COLN_WIDTH + "%",
        borderStyle: BORDER_STYLE,
        borderColor: "#010143",
        borderBottomColor: "#010143",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        fontFamily: 'Roboto', // Apply the font family to ensure it takes effect
    },
    tableCol: {
        width: COLN_WIDTH + "%",
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        fontFamily: 'Roboto',
    },
    totalTableCol: {
        width: ((100 / 6) * 5) + "%",
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        fontFamily: 'Roboto',
    },
    totalTableColAmount: {
        backgroundColor: "#010143",
        borderColor: "#010143",
        borderBottomColor: "#010143",
        width: COLN_WIDTH + "%",
        borderStyle: BORDER_STYLE,
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        fontFamily: 'Roboto',
    },
    tableCellHeader: {
        color: '#FFF',
        margin: 5,
        fontSize: 11,
        fontWeight: 700,
        fontFamily: 'Roboto',
    },
    tableCell: {
        margin: 5,
        fontSize: 11,
        fontFamily: 'Roboto',
    },
    tableCellTotal: {
        margin: 5,
        fontWeight: 700,
        fontSize: 11,
        fontFamily: 'Roboto',
    },
    tableCellTotalWhite: {
        margin: 5,
        fontWeight: 700,
        fontSize: 11,
        fontFamily: 'Roboto',
        color: '#fff'
    },
    BankingDetailsView: {
        alignSelf: 'left',
        marginTop: 15,
        borderRadius: 5,
        width: 60 + '%',
    },
    BankingDetailSection: {
        flexDirection: 'row',
        fontFamily: 'Roboto',
    },
});

const FlexiblePayInvoice = ({ invoice, transactions, client }) => {
    let invoiceDate = (new Date(invoice.InvoiceDate)).toLocaleDateString();
    let clientAddress = client.Address || {};
    let address = ""
    if (Object.keys(clientAddress).length > 0) {
        Object.keys(clientAddress).map((key) => {
            address += clientAddress[key] + ","
        })
    }
    address = address.trim(",")
    let transferFeeVat = invoice.TotalTransactionVAT || 0
    let totalIncl = invoice.TotalIncl || 0

    return (
        <Document>
            <Page style={styles.body}>
                <View>
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                        <View>
                            <Text style={styles.HeaderText}>Benefit being invoiced: <Text style={styles.SubHeaderText}>{`${invoice.Product}`}</Text></Text>
                            <Text style={styles.HeaderText}>Invoice No: <Text style={styles.SubHeaderText}>{invoice.InvoiceNumber}</Text></Text>
                            <Text style={styles.HeaderText}>Invoice date: <Text style={styles.SubHeaderText}>{invoiceDate}</Text></Text>
                            <Text> </Text>
                            <Text style={styles.HeaderText}>Billed to:</Text>
                            <Text style={styles.SubHeaderText}>{client.Name}</Text>
                            <Text style={styles.SubHeaderText}>{`VAT: ${client.VATNo || ""}`}</Text>
                            <Text style={styles.SubHeaderText}>{`Reg: ${client.RegCode || ""}`}</Text>
                            <Text style={styles.SubHeaderText}>{address}</Text>
                        </View>
                        <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'right', alignItems: 'flex-end' }}>
                            <Image style={styles.logo} src={LevelBlueWhiteLogoPng} />
                            <Text> </Text>
                            <Text style={styles.HeaderTextRight}>Level Finance (PTY) LTD</Text>
                            <Text style={styles.HeaderTextRight}>Reg: 2018/579476/07</Text>
                            <Text style={styles.HeaderTextRight}>VAT: 4700306949</Text>
                            <Text style={styles.HeaderTextRight}>1st Floor, Building 4</Text>
                            <Text style={styles.HeaderTextRight}>Clearwater Office Park</Text>
                            <Text style={styles.HeaderTextRight}>Cnr Christian de Wet</Text>
                            <Text style={styles.HeaderTextRight}>and Millenium Road</Text>
                            <Text style={styles.HeaderTextRight}>Strubensvalley, 1735</Text>
                        </View>
                    </View>
                </View>

                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Date</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Full name</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Amount</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Service fees (3%)</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Transfer fees (VAT excl)</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Total (VAT incl)</Text>
                        </View>
                    </View>

                    {transactions.map((transaction) => {

                        let transactionDate = (new Date(transaction.Date)).toLocaleDateString();
                        return (
                            <>
                                <View key={transaction._id} style={styles.tableRow}>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>{transactionDate}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>{`${transaction.FirstName} ${transaction.LastName}`}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>{`R ${transaction.TotalAdvanceAmount.toFixed(2)}`}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>{`R ${transaction.TotalAdvanceFees.toFixed(2)}`}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>{`R ${Number(transaction.TotalAdvanceTransferFees).toFixed(2)}`}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>{`R ${transaction.TotalDeduction.toFixed(2)}`}</Text>
                                    </View>
                                </View>
                            </>

                        );
                    })}
                    <View style={styles.tableRow}>
                        <View style={styles.totalTableCol}>
                            <Text style={styles.tableCellTotal}>Grand Total (Incl):</Text>
                        </View>
                        <View style={styles.totalTableColAmount}>
                            <Text style={styles.tableCellTotalWhite}>{`R ${totalIncl.toFixed(2)}`}</Text>
                        </View>
                    </View>
                </View>

                <View style={styles.BankingDetailsView}>
                    <Text style={styles.ClientHeaderText}>Banking Details</Text>
                    <Text> </Text>
                    <View style={styles.BankingDetailSection}>
                        <Text style={styles.HeaderText}>Bank Name: </Text>
                        <Text style={styles.HeaderText}>First National Bank</Text>
                    </View>
                    <View style={styles.BankingDetailSection}>
                        <Text style={styles.HeaderText}>Branch Account: </Text>
                        <Text style={styles.HeaderText}>62899294872</Text>
                    </View>
                    <View style={styles.BankingDetailSection}>
                        <Text style={styles.HeaderText}>Branch Code: </Text>
                        <Text style={styles.HeaderText}>250655</Text>
                    </View>
                    <View style={styles.BankingDetailSection}>
                        <Text style={styles.HeaderText}>Swift Code: </Text>
                        <Text style={styles.HeaderText}>FIRNZAJJ</Text>
                    </View>
                    <View style={styles.BankingDetailSection}>
                        <Text style={styles.HeaderText}>Reference: </Text>
                        <Text style={styles.HeaderText}>{invoice.InvoiceNumber}</Text>
                    </View>
                    <Text> </Text>
                    <Text style={styles.ClientHeaderText}>Please email proof of payment to alex@levelfinance.co.za</Text>
                    <Text style={styles.ClientHeaderText}>Overdue amount may be subject to interest</Text>
                </View>
            </Page>
        </Document>
    );
};

export default FlexiblePayInvoice;
