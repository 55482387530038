import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Button, TopBar, Input, ErrorCard, Navbar } from '../../../components/basic/index'
import { SuccessIcon, AssessmentIcon, PinkXIcon } from '../../../assets/icons/index'
import allBenefits from '../../../components/benefits';
import EmployeeBaseLineAssessment from '../../../components/assessments/baseline';

import './styles.scss';

const EmployeeBaselineAssessment = observer(() => {

    let navigate = useNavigate();
    const commonStore = useStore();
    const location = useLocation();

    const [selectedOption, setSelectedOption] = useState(null);
    const [questionNumber, setQuestionNumber] = useState(1);
    const [answersArray, setAnswersArray] = useState([])
    const [assessmentFinished, setAssessmentFinished] = useState(false)
    const [spendScore, setSpendScore] = useState(null)
    const [saveScore, setSaveScore] = useState(null)
    const [borrowScore, setBorrowScore] = useState(null)
    const [planScore, setPlanScore] = useState(null)
    const [overallScore, setOverallScore] = useState(null)
    const [beginAssessment, setBeginAssessment] = useState(false)
    const [baselineData, setBaselineData] = useState(null)

    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const [assessmentType, setAssessmentType] = useState("")

    const handleOptionChange = (value) => {
        setSelectedOption(value);
    };

    const handleNextQuestion = (currentQuestion) => {

        if (selectedOption === null || selectedOption === '') {
            setSelectedOption(null)
            commonStore.setSnackBar("warning", "Please select an option")
            return
        }

        setAnswersArray((prevAnswers) => [...prevAnswers, selectedOption]);

        if (currentQuestion > 0 && currentQuestion < 15) {
            setQuestionNumber((prevQuestion) => prevQuestion + 1);
            setSelectedOption(null)
        }

        if (currentQuestion === 15) {
            setSelectedOption(null)
            setAssessmentFinished(true)
            return
        }
        setSelectedOption(null)
        forceUpdate()
    }

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const id = queryParams.get('id');

        if (!id) {
            navigate('/employee/home');
        } else {
            setAssessmentType(id)
            setSelectedOption('')
        }
    }, []);

    useEffect(() => {
        if (assessmentFinished) {
            const saveData = answersArray.filter(item => item.category === 'Save');
            const saveAverage = calculateAverage(saveData);
            setSaveScore(saveAverage);

            const spendData = answersArray.filter(item => item.category === 'Spend');
            const spendAverage = calculateAverage(spendData);
            setSpendScore(spendAverage);

            const borrowData = answersArray.filter(item => item.category === 'Borrow');
            const borrowAverage = calculateAverage(borrowData);
            setBorrowScore(borrowAverage);

            const planData = answersArray.filter(item => item.category === 'Plan');
            const planAverage = calculateAverage(planData);
            setPlanScore(planAverage);

        }
        forceUpdate()

    }, [assessmentFinished]);

    useEffect(() => {
        if (baselineData === null) {
            return
        }

        if (baselineData.BorrowScore !== null &&
            baselineData.SpendData !== null &&
            baselineData.SaveData !== null &&
            baselineData.PlanScore !== null
        ) {
            let args = {}
            if (assessmentType == 'pre') {
                args.FinancialHealth = {}
                args.FinancialHealth.PreBaselineAssessment = baselineData
            }
            if (assessmentType == 'post') {
                args.FinancialHealth = {}
                args.FinancialHealth.PostBaselineAssessment = baselineData
            }

            commonStore.setLoading(true);
            commonStore.updateUserByUsername(commonStore.user.Username, args).then((ret) => {
                commonStore.setLoading(false);
                if (ret.FinancialHealth) {
                    navigate('/employee/home', { state: { refresh: true } })
                }
                else {
                    commonStore.setSnackBar("error", "Error occured, please try again")
                    navigate('/employee/home')
                }
            });
        }

    }, [baselineData]);

    useEffect(() => {
        if (assessmentFinished) {
            const overall = (spendScore + saveScore + borrowScore + planScore) / 4;
            setOverallScore(overall);

            const baselineData = {
                DateTaken: new Date(),
                QuestionAnswerPairs: answersArray,
                SpendScore: spendScore,
                SaveScore: saveScore,
                BorrowScore: borrowScore,
                PlanScore: planScore,
                OverallScore: overall
            };

            setBaselineData(baselineData)

        }
        forceUpdate()
    }, [answersArray, spendScore, saveScore, borrowScore, planScore, assessmentFinished]);

    const calculateAverage = (categoryData) => {
        const totalScore = categoryData.reduce((acc, item) => acc + (item.score || 0), 0);
        const average = totalScore / categoryData.length;
        return isNaN(average) ? null : average;
    };

    const getScoreColor = (score) => {
        if (score >= 80) {
            return 'green';
        } else if (score >= 40) {
            return 'orange';
        } else {
            return 'red';
        }
    };

    if (isMobile()) {
        return (
            <main>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <header className='employee-dash-header'>Benefits</header>
                    <img onClick={() => navigate('/employee/home')} src={PinkXIcon} />
                </div>
                {
                    !beginAssessment &&
                    <div className='flex-column'>
                        <img src={AssessmentIcon} style={{ height: '140px', width: '140px', margin: 'auto' }} alt='icon' />
                        <p style={{ fontSize: '15px', color: '#010143', fontWeight: 600 }}>{`Financial ${assessmentType.toUpperCase()} Baseline Assessment`}</p>
                        <p style={{ fontSize: '12px', color: '#737373' }}><i style={{ paddingRight: '5px', fontWeight: 'bold', textAlign: 'center' }} className="fa fa-wallet"></i>
                            Take a quick baseline assesment to help us better understand your financial circumstances and personalise our offerings better for you.
                        </p>
                        <br />
                        <Button onClick={() => (setBeginAssessment(true))} style={{ marginTop: '10px' }}>Begin Assessment</Button>
                    </div>
                }
                {beginAssessment &&
                    <div className='flex-column' style={{ alignItems: 'center' }}>
                        {!assessmentFinished && beginAssessment && (
                            <>
                                <p style={{ fontSize: '15px', fontWeight: 600 }}>Financial Baseline Assessment</p>
                                <EmployeeBaseLineAssessment questionNumber={questionNumber} onOptionChange={handleOptionChange} />
                                <br />
                                <Button onClick={() => handleNextQuestion(questionNumber)} width='90%'>
                                    {questionNumber === 15 ? 'Submit' : 'Next'}
                                </Button>
                            </>
                        )}
                    </div>}
            </main>
        );
    } else {
        return (
            <main>
                <header className='employee-dash-header'>Home</header>
            </main>
        );
    }
})

export default EmployeeBaselineAssessment
