import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Button, Navbar } from '../../../../components/basic/index'
import { EditPenBlueIcon, TCsPayroll2, TCsPayroll3, UploadCloudIcon } from '../../../../assets/icons/index'
import { AutohideSnackbar } from '../../../../components/MUI';

import './styles.scss';

const UploadPayrollFile = observer(() => {

    let navigate = useNavigate();
    const commonStore = useStore();

    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const [clicked, setClicked] = useState('');

    const [nextButtonEnabled, setNextButtonEnabled] = useState(false);

    const [displayImportResults, setDisplayImportResults] = useState(false)
    const [importResults, setImportResults] = useState({})

    const [error, setError] = useState('');
    const [errorType, setErrorType] = useState('');

    const [visible, setVisible] = useState("credentials")

    const [formattedEmployees, setFormattedEmployees] = useState([])
    const [tempFormattedEmployees, setTempFormattedEmployees] = useState([])
    const [formattedEmployeesPage, setFormattedEmployeesPage] = useState(1)
    const [editEmployee, setEditEmployee] = useState(false)
    const [currentEmployee, setCurrentEmployee] = useState({})
    const [currentEmployeeIndex, setCurrentEmployeeIndex] = useState(null)
    const [currentEmployeeChanged, setCurrentEmployeeChanged] = useState(false)

    const reset = () => {
        setFile(null)
        // Reset clicked state
        setClicked('');

        // Reset button and import result states
        setNextButtonEnabled(false);
        setDisplayImportResults(false);
        setImportResults({});

        // Reset error handling states
        setError('');
        setErrorType('');

        // Reset visibility and employee data
        setVisible('credentials');
        setFormattedEmployees([]);
        setTempFormattedEmployees([]);
        setFormattedEmployeesPage(1);

        // Reset employee editing states
        setEditEmployee(false);
        setCurrentEmployee({});
        setCurrentEmployeeIndex(null);
        setCurrentEmployeeChanged(false);
    };

    const [file, setFile] = useState(null);
    const [csv, setCsv] = useState(null)
    const [isDragging, setIsDragging] = useState(false);

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);

        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            const droppedFile = e.dataTransfer.files[0];

            // Check file type
            const validFileTypes = ['text/csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
            if (!validFileTypes.includes(droppedFile.type)) {
                commonStore.setSnackBar("warning", "Invalid file type. Please upload a CSV or XLS file.")
                return;
            }

            // Check file size (10MB limit)
            // const maxSizeInMB = 10;
            // const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
            // if (droppedFile.size > maxSizeInBytes) {
            //     alert('File size exceeds the 10MB limit. Please upload a smaller file.');
            //     return;
            // }

            setFile(droppedFile);
            // handleFileConversion(droppedFile);
            e.dataTransfer.clearData();
        }
    };

    const handleFileChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const selectedFile = e.target.files[0];

            // Check file type
            const validFileTypes = ['text/csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
            if (!validFileTypes.includes(selectedFile.type)) {
                commonStore.setSnackBar("warning", "Invalid file type. Please upload a CSV or XLS file.")
                return;
            }

            // Check file size (10MB limit)
            // const maxSizeInMB = 10;
            // const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
            // if (selectedFile.size > maxSizeInBytes) {
            //     alert('File size exceeds the 10MB limit. Please upload a smaller file.');
            //     return;
            // }

            setFile(selectedFile);
            // handleFileConversion(selectedFile);
        }
    };

    const handleDownloadTemplate = () => {
        const headers = [
            { id_passport_number: "", first_name: "", last_name: "", net_salary_amount: "", email: "", job_title: "", employment_status: "", employment_date: "", termination_reason: "", bank_account_bank_name: "", bank_account_account_number: "" }
        ];

        // Create a new workbook
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(headers); // Convert the headers into a worksheet

        // Append worksheet to workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, "Template");

        // Generate Excel file and download it
        XLSX.writeFile(workbook, 'payroll_template.xlsx');
    };

    const formatBankName = (bankName) => {
        let bank = bankName.replace(/\s+/g, '').toLocaleLowerCase();
        var ozowBankName;
        var branchCode;

        switch (bank) {
            case 'absa': ozowBankName = 'ABSA'; branchCode = '632005'; break;
            case 'absa-absa': ozowBankName = 'ABSA'; branchCode = '632005'; break;
            case 'absabank': ozowBankName = 'ABSA'; branchCode = '632005'; break;
            case 'accessbank': ozowBankName = 'Access Bank'; branchCode = ''; break;
            case 'access': ozowBankName = 'Access Bank'; branchCode = ''; break;
            case 'africanbank': ozowBankName = 'African Bank'; branchCode = '430000'; break;
            case 'african': ozowBankName = 'African Bank'; branchCode = '430000'; break;
            case 'albarakabank': ozowBankName = 'Albaraka Bank'; branchCode = '800000'; break;
            case 'albaraka': ozowBankName = 'Albaraka Bank'; branchCode = '800000'; break;
            case 'bankofathens': ozowBankName = 'Bank Of Athens'; branchCode = '410506'; break;
            case 'athensbank': ozowBankName = 'Bank Of Athens'; branchCode = '410506'; break;
            case 'bankathens': ozowBankName = 'Bank Of Athens'; branchCode = '410506'; break;
            case 'athens': ozowBankName = 'Bank Of Athens'; branchCode = '410506'; break;
            case 'bankwindhoek': ozowBankName = 'Bank Windhoek'; branchCode = ''; break;
            case 'windhoekbank': ozowBankName = 'Bank Windhoek'; branchCode = ''; break;
            case 'bankofwindhoek': ozowBankName = 'Bank Windhoek'; branchCode = ''; break;
            case 'bidvestbank': ozowBankName = 'Bidvest Bank'; branchCode = '462005'; break;
            case 'bidvest': ozowBankName = 'Bidvest Bank'; branchCode = '462005'; break;
            case 'capitecbank': ozowBankName = 'Capitec Bank'; branchCode = '470010'; break;
            case 'capitec-capitecbank': ozowBankName = 'Capitec Bank'; branchCode = '470010'; break;
            case 'capitec': ozowBankName = 'Capitec Bank'; branchCode = '470010'; break;
            case 'citibank': ozowBankName = 'CitiBank'; branchCode = '350005'; break;
            case 'citi': ozowBankName = 'CitiBank'; branchCode = '350005'; break;
            case 'diamondbank': ozowBankName = 'Diamond Bank'; branchCode = ''; break;
            case 'diamond': ozowBankName = 'Diamond Bank'; branchCode = ''; break;
            case 'discoverybank': ozowBankName = 'Discovery Bank'; branchCode = '679000'; break;
            case 'discovery': ozowBankName = 'Discovery Bank'; branchCode = '679000'; break;
            case 'firstbank': ozowBankName = 'FirstBank'; branchCode = ''; break;
            case 'fnbnamibia': ozowBankName = 'FNB Namibia'; branchCode = ''; break;
            case 'firstnationalbanknamibia': ozowBankName = 'FNB Namibia'; branchCode = ''; break;
            case 'fnb': ozowBankName = 'FNB'; branchCode = '250655'; break;
            case 'fnb-firstnationalbank': ozowBankName = 'FNB'; branchCode = '250655'; break;
            case 'firstnationalbank': ozowBankName = 'FNB'; branchCode = '250655'; break;
            case 'gtbank': ozowBankName = 'GTBank'; branchCode = ''; break;
            case 'gt': ozowBankName = 'GTBank'; branchCode = ''; break;
            case 'hsbcbank': ozowBankName = 'HSBC Bank'; branchCode = '587000'; break;
            case 'hsbc': ozowBankName = 'HSBC Bank'; branchCode = '587000'; break;
            case 'investec': ozowBankName = 'Investec'; branchCode = '580105'; break;
            case 'investec-investecbank': ozowBankName = 'Investec'; branchCode = '580105'; break;
            case 'mercantilebank': ozowBankName = 'Mercantile Bank'; branchCode = '450105'; break;
            case 'mercantile': ozowBankName = 'Mercantile Bank'; branchCode = '450105'; break;
            case 'nedbank': ozowBankName = 'Nedbank'; branchCode = '198765'; break;
            case 'nedbank-nedbank': ozowBankName = 'Nedbank'; branchCode = '198765'; break;
            case 'nedbankbank': ozowBankName = 'Nedbank'; branchCode = '198765'; break;
            case 'olympusmobile': ozowBankName = 'Olympus Mobile'; branchCode = '585001'; break;
            case 'olympus': ozowBankName = 'Olympus Mobile'; branchCode = '585001'; break;
            case 'rmb': ozowBankName = 'RMB'; branchCode = '250655'; break;
            case 'rmbbank': ozowBankName = 'RMB'; branchCode = '250655'; break;
            case 'sasfinbank': ozowBankName = 'Sasfin Bank'; branchCode = '683000'; break;
            case 'sasfin': ozowBankName = 'Sasfin Bank'; branchCode = '683000'; break;
            case 'standardbank': ozowBankName = 'Standard Bank'; branchCode = '051001'; break;
            case 'stdbank-standardbanksa': ozowBankName = 'Standard Bank'; branchCode = '051001'; break;
            case 'standard': ozowBankName = 'Standard Bank'; branchCode = '051001'; break;
            case 'standardbanknamibia': ozowBankName = 'Standard Bank Namibia'; branchCode = ''; break;
            case 'standardnamibia': ozowBankName = 'Standard Bank Namibia'; branchCode = ''; break;
            case 'standardcharteredbank': ozowBankName = 'Standard Chartered Bank'; branchCode = '730020'; break;
            case 'standardchartered': ozowBankName = 'Standard Chartered Bank'; branchCode = '730020'; break;
            case 'tymebank': ozowBankName = 'TymeBank'; branchCode = '678910'; break;
            case 'tyme': ozowBankName = 'TymeBank'; branchCode = '678910'; break;
            case 'thymebank': ozowBankName = 'TymeBank'; branchCode = '678910'; break;
            case 'thyme': ozowBankName = 'TymeBank'; branchCode = '678910'; break;
            case 'unitedbankforafrica': ozowBankName = 'United Bank for Africa'; branchCode = ''; break;
            case 'unitedbankafrica': ozowBankName = 'United Bank for Africa'; branchCode = ''; break;
            case 'unitedbank': ozowBankName = 'United Bank for Africa'; branchCode = ''; break;
            case 'ubanklimited': ozowBankName = 'Ubank Limited'; branchCode = '431010'; break;
            case 'ubank': ozowBankName = 'Ubank Limited'; branchCode = '431010'; break;
            case 'zenithbank': ozowBankName = 'Zenith Bank'; branchCode = ''; break;
            case 'zenith': ozowBankName = 'Zenith Bank'; branchCode = ''; break;
            default: return false
        }

        return {
            bankname: ozowBankName,
            branchcode: branchCode
        }
    }

    const handleFileConversion = (file) => {
        const reader = new FileReader();

        // Set up the onload handler
        reader.onload = (e) => {
            console.log("ran");
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0].trim();
            const sheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(sheet);

            let requiredFields = ["id_passport_number", "first_name", "net_salary_amount", "email"];
            let keys = Object.keys(jsonData[0]);
            console.log("Excel file headers", keys);

            const allItemsIncluded = requiredFields.every(item => keys.includes(item));

            if (!allItemsIncluded) {
                commonStore.setSnackBar("warning", "Uploaded excel file does not have one of the following fields: id_passport_number, first_name, net_salary_amount, email")
            } else {
                // Format data
                const formattedEmployees = jsonData.map((emp) => {
                    if (!emp.id_passport_number) {
                        return null;
                    }

                    let employee = {};

                    employee.FirstName = emp.first_name?.toString() || "";
                    employee.IDNumber = emp.id_passport_number?.toString();
                    employee.Username = emp.id_passport_number?.toString();
                    emp.Username = employee.IDNumber
                    employee.LastName = emp.last_name?.toString() || "";
                    employee.Mobile = emp.cell_no?.toString() || "";
                    employee.WorkEmail = emp.email?.toString() || "";
                    employee.Client = commonStore.client._id
                    employee.JobTitle = emp.job_title?.toString() || ""
                    if (emp.employment_status?.toString() !== "Employed" || emp.employment_status?.toString() !== "Terminated") {
                        employee.EmploymentStatus = "Employed"
                    }
                    else {
                        employee.EmploymentStatus = emp.employment_status?.toString()
                    }
                    
                    employee.EmploymentDate = new Date(emp.employment_date) || null
                    employee.TerminationReason = emp.termination_reason?.toString() || null

                    // Bank details
                    if (emp.bank_account_bank_name) {
                        let format = formatBankName(emp.bank_account_bank_name);
                        employee.PrimaryBankAccount = {
                            BankName: format?.bankname?.toString() || "",
                            AccountNumber: emp.bank_account_account_number?.toString() || "",
                            BranchCode: format?.branchcode?.toString() || "",
                            Type: emp.bank_account_account_type?.toString() || "",
                        };
                    }
                    // Salary
                    employee.SalaryAmount = Number(emp.net_salary_amount) || 0;

                    return employee;
                }).filter(emp => emp !== null); // Filter out null employees
                if (Array.isArray(formattedEmployees) && formattedEmployees.length > 0) {
                    let list = formattedEmployees
                    setFormattedEmployees(JSON.parse(JSON.stringify(list)))
                    setTempFormattedEmployees(JSON.parse(JSON.stringify(list)))
                    setVisible('approve')
                }
                else {
                    setFile(null)
                    commonStore.setSnackBar("error", "Error occured, please ensure the your file is correctly formatted")
                }
            }
        };

        // Set up the onerror handler
        reader.onerror = (error) => {
            console.error('Error reading file:', error);
        };

        // Read the file based on its type
        if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/vnd.ms-excel') {
            reader.readAsArrayBuffer(file);
        } else {
            reader.readAsText(file);
        }
    };

    const handleNextPage = () => {
        if (formattedEmployeesPage * 10 < formattedEmployees.length) {
            setFormattedEmployeesPage(formattedEmployeesPage + 1);
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            })
        }
    }

    const handlePrevPage = () => {
        if (formattedEmployeesPage > 1) {
            setFormattedEmployeesPage(formattedEmployeesPage - 1);
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            })
        }
    }

    const handleEditEmployee = (emp, index) => {
        let ind = ((formattedEmployeesPage - 1) * 10) + index
        setCurrentEmployee(emp)
        setCurrentEmployeeIndex(ind)
        setEditEmployee(true)
        forceUpdate()
    }

    const handleRemoveEmployee = (emp, index) => {
        if (window.confirm(`Are you sure you want to remove ${emp.FirstName} ${emp.LastName}`)) {
            let temp1 = formattedEmployees
            temp1.splice(index, 1)
            setFormattedEmployees(temp1)

            let temp2 = tempFormattedEmployees
            temp2.splice(index, 1)
            setTempFormattedEmployees(temp2)

            forceUpdate()
        }
        else {
            console.log("NO")
        }

    }

    const handleEditEmployeeOnChange = (e) => {
        let val = e.currentTarget.value
        let key = e.currentTarget.id

        if (key === "SalaryAmount" && val) {
            val = parseInt(val)
        }
        //set current changes
        let curr = tempFormattedEmployees[currentEmployeeIndex]
        curr = { ...curr, ... { [key]: val } }

        //compare
        if (JSON.stringify(formattedEmployees[currentEmployeeIndex]) !== JSON.stringify(curr)) {
            setCurrentEmployeeChanged(true)
        }
        else {
            setCurrentEmployeeChanged(false)
        }

        //update temp array
        let temp = tempFormattedEmployees
        temp[currentEmployeeIndex] = curr
        setTempFormattedEmployees(temp)

        forceUpdate()
    }

    const updateEmployee = () => {
        //update og array
        let originalObject = formattedEmployees
        let temp = tempFormattedEmployees[currentEmployeeIndex]
        originalObject[currentEmployeeIndex] = temp
        setFormattedEmployees(originalObject)
        forceUpdate()

        setEditEmployee(false)
        setCurrentEmployee({})
        setCurrentEmployeeChanged(false)
        setCurrentEmployeeIndex(null)

    }

    const handleCancelEditEmployee = () => {
        //reset array
        let temp = tempFormattedEmployees
        let originalObject = formattedEmployees[currentEmployeeIndex]
        temp[currentEmployeeIndex] = originalObject
        setTempFormattedEmployees(temp)

        setEditEmployee(false)
        setCurrentEmployee({})
        setCurrentEmployeeChanged(false)
        setCurrentEmployeeIndex(null)

        forceUpdate()
    }

    const handleApprove = async () => {
        let args = {
            employees: formattedEmployees
        }
        try {
            commonStore.setLoading(true)
            let res = await commonStore.bulkUploadEmployees(commonStore.user.Client._id, args)
            commonStore.setLoading(false)
            if (res.status === true) {
                let data = res.data
                let results = {
                    initialPayrollCount: data.initialPayrollCount,
                    clientInitialEmployeeCount: data.clientInitialEmployeeCount,
                    foundAndTerminated: data.foundAndTerminated.length,
                    foundAndEmployed: data.foundAndEmployed.length,
                    notFoundAndEmployed: data.notFoundAndEmployed.length,
                    notfoundAndTerminated: data.notfoundAndTerminated.length,
                    failedImports: data.failedImports.length
                }
                setImportResults(results)
                setDisplayImportResults(true)
                setVisible('displayImportResults')
                forceUpdate()
                commonStore.setLoading(false)
            }
            else {
                commonStore.setLoading(false)
                commonStore.setSnackBar("error", "Error occured, please try again") 
            }

        } catch (error) {
            console.log(error)
            commonStore.setLoading(false)
            commonStore.setSnackBar("error", "Error occured, please try again")
        }

    }

    if (isMobile()) {
        return (
            <div>
                <Navbar />
                
            </div >
        );
    } else {
        return (
            <div>
                <Navbar />
                <main style={{ alignItems: 'center' }}>
                    <AutohideSnackbar content={commonStore.snackBarDetails.content} open={commonStore.snackBarDetails.open} type={commonStore.snackBarDetails.type} setSnackBarClose={commonStore.resetSnackBar} />
                    {!displayImportResults && <div className='confirm-employee-benefits-progress-container'>
                        <img src={TCsPayroll2} />
                    </div>}
                    {displayImportResults && <div className='confirm-employee-benefits-progress-container'>
                        <img src={TCsPayroll3} />
                    </div>}
                    {visible === "credentials" &&
                        <>
                            <header className='employee-benefits-assessment-submit-header'>Upload payroll file</header>
                            <div style={{ width: '75%' }}>
                                <br />
                            <Button onClick={handleDownloadTemplate}>Download Template</Button>
                                <hr style={{ width: '100%', border: '1px solid rgb(232, 220, 235)' }} />
                                <br />
                            </div>
                            <div style={{ width: '75%' }}>
                                <p className="text-left text-[15px] text-500">
                                Please specify whether or not the employee is employed or not under the employment_status field. ("Employed" / "Terminated")
                                </p>
                                <br />
                                <br />
                                {clicked === "" &&
                                    <div style={{ width: '100%' }}>
                                        <p style={{ textAlign: 'left', fontSize: '18px' }} className='employee-benefits-assessment-submit-subheader'>
                                            Upload payroll file
                                        </p>
                                        <br />
                                        <div
                                            className={`upload-payroll-file-container ${isDragging ? 'dragging' : ''}`}
                                            onDragOver={handleDragOver}
                                            onDragLeave={handleDragLeave}
                                            onDrop={handleDrop}
                                        >
                                            <img src={UploadCloudIcon} alt="Upload" />
                                            <div style={{ fontSize: '14px' }}><span style={{ color: '#ED3DA6' }}>Select a file</span> or drag and drop header</div>
                                            <div style={{ fontSize: '14px', color: '#00000066' }}>CSV or XLS, file size no more than 10MB</div>
                                            <input
                                                type='file'
                                                accept='.csv, .xls, .xlsx'
                                                className='upload-payroll-file-select-file-btn'
                                                onChange={handleFileChange}
                                            />
                                            {file && <div style={{ fontSize: '14px' }}>File selected: <span style={{ color: '#ED3DA6' }}>{file.name}</span></div>}
                                        </div>
                                    </div>
                                }
                                <br />
                                <br />
                                <div className='two-buttons-container-pri-purinv' style={{ alignItems: 'center' }}>
                                    <div onClick={() => navigate('/client/home/Overview')} style={{ color: '#5D0070', fontWeight: '500', cursor: 'pointer' }}>
                                        Skip
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                                        <Button onClick={() => handleFileConversion(file)}>Next</Button>
                                    </div>

                                </div>
                            </div>
                        </>
                    }

                    {visible === "approve" &&
                        <>
                            <header className='employee-benefits-assessment-submit-header'>Approve</header>
                            <div style={{ width: '75%' }}>
                                <p className='employee-benefits-assessment-submit-subheader'>
                                    Please carefully approve, disapprove or edit to ensure the correct information is populated.
                                </p>
                            <p className="text-center text-[15px] text-500-red">
                                NOTE: Any employees not included here will deactivated from our system.
                            </p>
                                <br />
                                <hr style={{ width: '100%', border: '1px solid rgb(232, 220, 235)' }} />
                                <br />
                            </div>
                            <div className='payroll-approve-table-container'>
                                {
                                    tempFormattedEmployees
                                        .filter((_, index) => {
                                            const start = (formattedEmployeesPage - 1) * 10;
                                            const end = formattedEmployeesPage * 10;
                                            return index >= start && index < end;
                                        })
                                        .map((item, index) => (
                                            <div className='payroll-approve-table-item' key={index}>
                                                <div className='employee-benefits-assessment-submit-header' style={{ fontSize: '20px' }}>{`${index + 1 + (formattedEmployeesPage - 1) * 10}.`}</div>
                                                <div className="flex-column" style={{ alignItems: 'flex-start' }}>
                                                    <label className="signin-input-label">
                                                        ID number or passport
                                                    </label>
                                                    <hr style={{ width: '100%', border: '0.5px solid #00000030' }} />
                                                    <div className='employee-benefits-assessment-submit-subheader' placeholder="ID Number / Email">
                                                        {item.IDNumber}
                                                    </div>
                                                </div>
                                                <div className="flex-column" style={{ alignItems: 'flex-start' }}>
                                                    <label className="signin-input-label">
                                                        First name
                                                    </label>
                                                    <hr style={{ width: '100%', border: '0.5px solid #00000030' }} />
                                                    <div className='employee-benefits-assessment-submit-subheader' placeholder="First name">
                                                        {item.FirstName}
                                                    </div>
                                                </div>
                                                <div className="flex-column" style={{ alignItems: 'flex-start' }}>
                                                    <label className="signin-input-label">
                                                        Last name
                                                    </label>
                                                    <hr style={{ width: '100%', border: '0.5px solid #00000030' }} />
                                                    <div className='employee-benefits-assessment-submit-subheader' placeholder="Last name">
                                                        {item.LastName}
                                                    </div>
                                                </div>
                                                <div className="flex-column" style={{ alignItems: 'flex-start' }}>
                                                    <label className="signin-input-label">
                                                        Nett salary
                                                    </label>
                                                    <hr style={{ width: '100%', border: '0.5px solid #00000030' }} />
                                                    <div className='employee-benefits-assessment-submit-subheader' placeholder="Nett salary">
                                                        {Number(item.SalaryAmount).toLocaleString()}
                                                    </div>
                                                </div>
                                                <div className="flex-column" style={{ alignItems: 'flex-start' }}>
                                                    <label className="signin-input-label">
                                                        Bank name
                                                    </label>
                                                    <hr style={{ width: '100%', border: '0.5px solid #00000030' }} />
                                                    <div className='employee-benefits-assessment-submit-subheader' placeholder="Bank name">
                                                        {item.PrimaryBankAccount?.BankName || ''}
                                                    </div>
                                                </div>
                                                <div className="flex-column" style={{ alignItems: 'flex-start' }}>
                                                    <label className="signin-input-label">
                                                        Account Number
                                                    </label>
                                                    <hr style={{ width: '100%', border: '0.5px solid #00000030' }} />
                                                    <div className='employee-benefits-assessment-submit-subheader' placeholder="Account number">
                                                        {item.PrimaryBankAccount?.AccountNumber || ''}
                                                    </div>
                                                </div>

                                                <br />
                                                <img onClick={() => handleEditEmployee(item, index)} src={EditPenBlueIcon} style={{ height: '20px', cursor: 'pointer' }} />
                                                <div onClick={() => handleRemoveEmployee(item, index)} style={{ color: 'red', cursor: 'pointer' }}><i class="fa-regular fa-trash-can"></i></div>
                                            </div>
                                        ))
                                }
                                <div className='employee-benefits-assessment-submit-header' style={{ width: '100%', fontSize: '20px', display: 'flex', justifyContent: 'space-evenly', padding: '20px 0px' }}>
                                    <div onClick={handlePrevPage} style={{ cursor: 'pointer' }}>Previous</div>
                                    {`${(formattedEmployeesPage - 1) * 10 + 1} - ${formattedEmployeesPage * 10 <= formattedEmployees.length ? formattedEmployeesPage * 10 : formattedEmployees.length} of ${formattedEmployees.length}`}
                                    <div onClick={handleNextPage} style={{ cursor: 'pointer' }}>Next</div>
                                </div>
                                <div className='flex flex-row gap-1'>
                                    <Button width='100%' onClick={() => handleApprove()}>Approve</Button>
                                    <Button type="purpleInvis" width='100%' onClick={() => reset()}>Cancel</Button>
                                </div>
                            </div>

                            {editEmployee &&
                                <main style={{ position: 'fixed', background: '#808080ba' }}>
                                    <div className='admin-dash-edit-inner-container'>
                                        <header className='admin-dash-client-details-text mediumbold' style={{ fontSize: '18px' }}>
                                            Edit employee details
                                        </header>
                                        <br />
                                        <div className='two-by-two-grid'>
                                            <div className='flex-column'>
                                                <label className='admin-dash-client-details-text small'>First name</label>
                                                <input value={tempFormattedEmployees[currentEmployeeIndex].FirstName}
                                                    id='FirstName'
                                                    onChange={handleEditEmployeeOnChange}
                                                    className='admin-dash-assess-input'
                                                    placeholder='First name'
                                                />
                                            </div>
                                            <div className='flex-column'>
                                                <label className='admin-dash-client-details-text small'>Last name</label>
                                                <input value={tempFormattedEmployees[currentEmployeeIndex].LastName}
                                                    id='LastName'
                                                    onChange={handleEditEmployeeOnChange}
                                                    className='admin-dash-assess-input'
                                                    placeholder='Last name'
                                                />
                                            </div>
                                            <div className='flex-column'>
                                                <label className='admin-dash-client-details-text small'>Nett salary</label>
                                                <input value={tempFormattedEmployees[currentEmployeeIndex].SalaryAmount}
                                                    id='SalaryAmount'
                                                    onChange={handleEditEmployeeOnChange}
                                                    className='admin-dash-assess-input'
                                                    placeholder='Nett salary'
                                                />
                                            </div>
                                            <div className='flex-column'>
                                                <label className='admin-dash-client-details-text small'>ID number/passport</label>
                                                <input value={tempFormattedEmployees[currentEmployeeIndex].IDNumber}
                                                    id='IDNumber'
                                                    onChange={handleEditEmployeeOnChange}
                                                    className='admin-dash-assess-input'
                                                    placeholder='ID number/passport'
                                                />
                                            </div>
                                            <div className='flex-column'>
                                                <label className='admin-dash-client-details-text small'>Bank name</label>
                                                <input value={tempFormattedEmployees[currentEmployeeIndex].PrimaryBankAccount?.BankName || ""}
                                                    id='BankName'
                                                    onChange={handleEditEmployeeOnChange}
                                                    className='admin-dash-assess-input'
                                                    placeholder='Bank name'
                                                />
                                            </div>
                                            <div className='flex-column'>
                                                <label className='admin-dash-client-details-text small'>Account number</label>
                                                <input value={tempFormattedEmployees[currentEmployeeIndex].PrimaryBankAccount?.AccountNumber || ""}
                                                    id='AccountNumber'
                                                    onChange={handleEditEmployeeOnChange}
                                                    className='admin-dash-assess-input'
                                                    placeholder='Account number'
                                                />
                                            </div>
                                            <button onClick={handleCancelEditEmployee} className='admin-dash-back-arrow-btn'>Cancel</button>
                                            {
                                                currentEmployeeChanged &&
                                                <button className='admin-dash-back-arrow-btn'
                                                    style={{ background: 'black', color: 'white' }}
                                                    onClick={updateEmployee}
                                                >
                                                    Update
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </main>
                            }
                        </>

                    }

                    {visible === 'displayImportResults' &&
                        <div style={{ width: '75%' }}>
                            <header className='employee-benefits-assessment-submit-header'>Payroll connected!</header>
                            <div >
                                <p className='employee-benefits-assessment-submit-subheader'>
                                    See results
                                </p>
                                <br />
                                <br />
                                    <div className="">
                                        {/* <p style={{ textAlign: 'left', fontSize: '18px' }} className='employee-benefits-assessment-submit-subheader'>
                                            {`Initial employee count: ${importResults.clientInitialEmployeeCount}`}
                                        </p> */}
                                        {/* <hr style={{ width: '100%', border: '1px solid rgb(232, 220, 235)' }} /> */}
                                        <p style={{ textAlign: 'left', fontSize: '18px' }} className='employee-benefits-assessment-submit-subheader'>
                                            {`Employees in excel file: ${importResults.initialPayrollCount}`}
                                        </p>
                                        <hr style={{ width: '100%', border: '1px solid rgb(232, 220, 235)' }} />
                                        <p style={{ textAlign: 'left', fontSize: '18px' }} className='employee-benefits-assessment-submit-subheader'>
                                            {`New employees added: ${importResults.notFoundAndEmployed}`}
                                        </p>
                                        <hr style={{ width: '100%', border: '1px solid rgb(232, 220, 235)' }} />
                                        <p style={{ textAlign: 'left', fontSize: '18px' }} className='employee-benefits-assessment-submit-subheader'>
                                            {`Updated employees: ${importResults.foundAndEmployed}`}
                                        </p>
                                        <hr style={{ width: '100%', border: '1px solid rgb(232, 220, 235)' }} />
                                        <p style={{ textAlign: 'left', fontSize: '18px' }} className='employee-benefits-assessment-submit-subheader'>
                                            {`Terminated employees (Found in Level): ${importResults.foundAndTerminated}`}
                                        </p>
                                        <hr style={{ width: '100%', border: '1px solid rgb(232, 220, 235)' }} />
                                        <p style={{ textAlign: 'left', fontSize: '18px' }} className='employee-benefits-assessment-submit-subheader'>
                                            {`Terminated employees (Not found in Level): ${importResults.notfoundAndTerminated}`}
                                        </p>
                                        <hr style={{ width: '100%', border: '1px solid rgb(232, 220, 235)' }} />
                                        <p style={{ textAlign: 'left', fontSize: '18px' }} className='employee-benefits-assessment-submit-subheader'>
                                            {`Failed imports: ${importResults.failedImports}`}
                                        </p>
                                    </div>
                                <br />
                                <Button onClick={() => navigate('/client/home/Overview')}>Go to dashboard</Button>
                            </div>
                        </div>
                    }

                </main>
            </div >
        );
    }
})

export default UploadPayrollFile
