import FlexiblePayLogo2 from './FlexiblePayLogo2.svg'

const LevelBlueWhiteLogo = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/Level Logo Blue White.svg'
const LevelBlueWhiteLogoPng = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/LevelLogoBlueWhite.png'
const GradHatCircleIcon = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/grad-hat-circle.svg'
const DropDownArrowDown = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/dropdown_arrow_down.svg'
const ConfirmBenefitsPending = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/confirm-benefits-pending.svg'
const ConfirmBenefitsDone = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/confirm-benefits-done.svg'
const ConnectPayrollDone = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/connect-payroll-done.svg'
const tsAndcsDone = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/tcs-done.svg'
const GreenCheckCircle = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/green-check-circle.svg'
const SageIcon = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/sage-icon.svg'
const SimplePayIcon = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/simple-pay-icon.svg'
const LabourNetIcon = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/labour-net-icon.svg'
const PaySpaceIcon = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/pay-space-icon.svg'
const PinkCheckCircle = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/pink-check-circle.svg'
const GreyProfileIcon = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/grey-profile-icon.svg'
const SideBarOverviewIcon = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/side-bar-overview-icon.svg'
const SideBarBenefitsIcon = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/side-bar-benefits-icon.svg'
const SideBarClientsIcon = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/side-bar-users-icon.svg'
const SideBarReportsIcon = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/side-bar-reports-icon.svg'
const SideBarSettingsIcon = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/side-bar-settings-icon.svg'
const LandingImage = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/landingImage.png'
const SearchIcon = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/search-icon.svg'
const BlueAddIcon = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/blue-add-icon.svg'
const EditPenBlueIcon = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/edit-pen-icon.svg'
const EditPenPinkIcon = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/edit-pen-pink-icon.svg'
// const BenefitsReportHeaderImage = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/benefits-report-header-images.png'
const BenefitsReportHeaderImage = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/benefits-report-header-images.svg'
const LogoutIcon = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/logout-icon.svg'
const SideBarUsersIcon = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/users-side-bar-icon.svg'
const ProfileIconWhite = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/profile-icon-white.svg'
const LogoutIconWhite = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/logout-icon-white.svg'
const TCsPayroll1 = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/doingTCs-icon.svg'
const TCsPayroll2 = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/doingPayroll-icon.svg'
const TCsPayroll3 = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/doneTcs-and-payroll-icon.svg'
const OrangeHourGlass = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/orange-hourglass-icon.svg'
const UploadCloudIcon = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/upload-cloud-icon.svg'
const BigLockIcon = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/big-lock-icon.svg'
const ScanFaceIcon = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/scan-face-icon.svg'
const IdVerified = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/id-verified-icon.svg'
const AssessmentIcon = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/assessment-icon.svg'
const SuccessIcon = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/success-icon.svg'
const BackArrow = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/back-arrow-icon.svg'
const PinkXIcon = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/pink-x-icon.svg'
const PasswordIcon = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/password-icon.svg'
const FlexiblePayLogo = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/flexible-pay-logo.svg'
const BottomNavHomeOn = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/employee-nav-home-on.svg'
const BottomNavHomeOff = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/employee-nav-home-off.svg'
const BottomNavStoreOff = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/employee-nav-store-off.svg'
const BottomNavStoreOn = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/employee-nav-store-on.svg'
const BottomNavBenefitsOff = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/employee-nav-benefits-off.svg'
const BottomNavBenefitsOn = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/employee-nav-benefits-on.svg'
const BottomNavMore = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/employee-nav-more.svg'
const GreenCash = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/cash-icon.svg'
const BlackGirlShopping = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/black-girl-shopping.png'
const WhiteXIcon = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/exit-white-icon.svg'
const FlexNavManageOn = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/flexible-pay-nav-manage-on.svg'
const FlexNavManageOff = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/flexible-pay-nav-manage-off.svg'
const FlexNavHomeOn = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/flexible-pay-nav-home-on.svg'
const FlexNavHomeOff = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/flexible-pay-nav-home-off.svg'
const SavingsClubBlueVertical = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/savings-club-logo-vertical-blue.svg'
const CareFlexLogoOrange = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/careflex-logo.svg'
const BlankLogoIcon = 'https://d2xts8p0gw7i1c.cloudfront.net/global_assets/icons/blank-logo-icon.svg'

export {
    LevelBlueWhiteLogo,
    LandingImage,
    GradHatCircleIcon,
    DropDownArrowDown,
    ConfirmBenefitsPending,
    ConfirmBenefitsDone,
    ConnectPayrollDone,
    tsAndcsDone,
    GreenCheckCircle,
    SageIcon,
    SimplePayIcon,
    LabourNetIcon,
    PaySpaceIcon,
    PinkCheckCircle,
    GreyProfileIcon,
    SideBarOverviewIcon,
    SideBarBenefitsIcon,
    SideBarClientsIcon,
    SideBarUsersIcon,
    SideBarReportsIcon,
    SideBarSettingsIcon,
    SearchIcon,
    BlueAddIcon,
    EditPenBlueIcon,
    EditPenPinkIcon,
    BenefitsReportHeaderImage,
    LogoutIcon,
    LogoutIconWhite,
    ProfileIconWhite,
    TCsPayroll1,
    TCsPayroll2,
    TCsPayroll3,
    OrangeHourGlass,
    UploadCloudIcon,
    BigLockIcon,
    ScanFaceIcon,
    IdVerified,
    AssessmentIcon,
    SuccessIcon,
    BackArrow,
    PinkXIcon,
    PasswordIcon,
    FlexiblePayLogo,
    BottomNavHomeOn,
    BottomNavHomeOff,
    BottomNavStoreOff,
    BottomNavStoreOn,
    BottomNavBenefitsOff,
    BottomNavBenefitsOn,
    BottomNavMore,
    GreenCash,
    BlackGirlShopping,
    WhiteXIcon,
    FlexNavHomeOn,
    FlexNavManageOff,
    FlexNavManageOn,
    FlexNavHomeOff,
    SavingsClubBlueVertical,
    CareFlexLogoOrange,
    BlankLogoIcon,
    LevelBlueWhiteLogoPng,
    FlexiblePayLogo2
}