import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../../../../stores/Store';
import { isMobile } from 'is-mobile';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { SearchIcon } from '../../../../../../assets/icons';
import { InputSlider, IOSToggle } from '../../../../../../components/basic';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import './styles.scss';

const ClientBenefitsFlexiblePayUsers = observer(({ data, refresh }) => {

    let navigate = useNavigate();
    const commonStore = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const [error, setError] = useState('');
    const [updateUsers, setUpdateUsers] = useState(false)
    const [fpUsers, setFpUsers] = useState(data.fpUsers)
    const [currentUser, setCurrentUser] = useState({})
    const [visibleChild, setVisibleChild] = useState("Users")
    const [activeFilter, setActiveFilter] = useState("All")
    const [searchField, setSearchField] = useState("")
    const [searchFilter, setSearchFilter] = useState("FirstName")
    const [newPerc, setNewPerc] = useState(0)
    const [userBlocked, setUserBlocked] = useState(false)

    const [max, setMax] = useState(0)
    const [available, setAvailable] = useState(null)
    const [history, setHistory] = useState([])

    const [open, setOpen] = React.useState(false);

    const reset = () => {
        setError('');
        resetAdvanceSections()
        setVisibleChild("Users");
        refresh()
    };

    const resetAdvanceSections = () => {
        setUpdateUsers(false);
        setOpen(false);
    }

    useEffect(() => {
        resetAdvanceSections()
        setFpUsers(data.fpUsers)
        forceUpdate()
    }, [data])

    console.log(fpUsers)

    const getAmountAvailable = async (user) => {
        
        let res = await commonStore.calculateAmountAvailable(user.Username)
        console.log(res)
        if (res.status === true) {
            let fpData = res.response
            setAvailable(fpData.allowed)
            setMax(fpData.available)
            let hist = []
            if (fpData.history.length > 0) {
                //descending dates, slice apparently creates a shallow copy to avoid mutation
                //that wouldve been useful a month ago
                hist = fpData.history.slice().sort((a, b) => new Date(b.Date) - new Date(a.Date))
            }
            setHistory(hist)
            setCurrentUser(user)
            setNewPerc(fpData.electedAdvancePerc)
            setUserBlocked(user.Blocked)
            forceUpdate()
            setVisibleChild("singular-user")
        }
        else {
            commonStore.setSnackBar('error', "Error retrieving flexible pay info")
            return
        }
    }

    const handleOnClickUser = async (user) => {
        resetAdvanceSections()
        console.log("Clicked user", user)
        getAmountAvailable(user)
    }

    const handleActiveFilterClick = (e) => {
        setActiveFilter(e.currentTarget.id)
    }

    const handleOnSearchFieldChange = (e) => {
        setSearchField(e.currentTarget.value)
    }

    const handleFilterChange = (e) => {
        setSearchFilter(e.target.value)
    }

    const getStatusColor = (status) => {
        switch (status) {
            case "Failed": return 'red'
            case "Pending": return 'orange'
            case "Paid": return 'green'
        }
    }

    const handleSetPercValue = (val) => {
        setNewPerc(val)
    }

    const updateFpUser = async (data) => {
        console.log(data)
        commonStore.setLoading(true)
        try {
            let res = await commonStore.updateFlexiblePayUserByIDNumber(currentUser.Username, data)
            console.log(res)
            if (res) {
                commonStore.setSnackBar('success', "User updated")
                setCurrentUser({ ...currentUser, ...res })
                reset()
            }
            else {
                commonStore.setSnackBar('error', "Error occured, please try again")
                commonStore.setLoading(false)
            }
        } catch (error) {
            commonStore.setSnackBar('error', "Error occured, please try again")
            console.error(error)
            commonStore.setLoading(false)
        }
    }

    const handleCheckUserBlocked = (e) => {
        let checked = e.target.checked
        if (checked) {
            if (!window.confirm("Are you sure you want to unblock this user")) {
                return
            }
        }
        if (!checked) {
            if (!window.confirm("Are you sure you want to block this user")) {
                return
            }
        }
        setUserBlocked(e.target.checked)
        updateFpUser({ Blocked: !userBlocked })

    }

    if (isMobile()) {
        return (
            <div style={{ background: "#F4F5FA", width: '100%' }}>

            </div>
        );
    } else {
        return (
            <>
                {visibleChild === "Users" &&
                    <div>
                        <div style={{ background: "#FFFFFF", borderRadius: '20px', padding: '35px' }}>
                            {/* TOP DIV */}
                            <div style={{ display: 'flex', marginBottom: '20px', gap: '25px', alignItems: 'center' }}>
                                <header className='admin-dash-sub-header' style={{ fontSize: '14px' }}>
                                    <span className='admin-dash-blue-clients-text normal'>
                                        {'Flexible pay'}</span>{` > Active users`}
                                </header>
                            </div>
                            <div style={{ display: 'flex', gap: '5px' }}>
                                <img src={SearchIcon} style={{ width: '20px' }} />
                                <FormControl style={{ width: '200px' }}>
                                    <InputLabel id="demo-simple-select-label">Filter</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={searchFilter}
                                        label="Filter"
                                        onChange={handleFilterChange}
                                        style={{ fontSize: '13px' }}
                                    >
                                        <MenuItem value={"FirstName"}>First name</MenuItem>
                                        <MenuItem value={"LastName"}>Last name</MenuItem>
                                        <MenuItem value={"IDNumber"}>ID number</MenuItem>
                                    </Select>
                                </FormControl>
                                <input className='admin-dash-search-input'
                                    type='search' value={searchField}
                                    onChange={handleOnSearchFieldChange}
                                    placeholder='Search users by ID number/Full name'
                                />
                            </div>
                            <br />
                            {/* FILTER */}
                            <div style={{ display: 'flex', gap: '20px' }}>
                                <div id='All'
                                    className={`admin-dash-filter-item ${activeFilter === 'All' ? 'selected' : null}`}
                                    onClick={handleActiveFilterClick}>
                                    All
                                </div>
                                <div id='Active'
                                    className={`admin-dash-filter-item ${activeFilter === 'Active' ? 'selected' : null}`}
                                    onClick={handleActiveFilterClick}>
                                    Active
                                </div>
                                <div id='Pending'
                                    className={`admin-dash-filter-item ${activeFilter === 'Pending' ? 'selected' : null}`}
                                    onClick={handleActiveFilterClick}>
                                    Pending
                                </div>
                                <div id='Deactivated'
                                    className={`admin-dash-filter-item ${activeFilter === 'Deactivated' ? 'selected' : null}`}
                                    onClick={handleActiveFilterClick}>
                                    Deactivated
                                </div>
                            </div>
                            {/* LIST */}
                            <div >
                                {/* SEARCH */}
                                <br />
                                {/* LIST ITEMS */}
                                <div className='admin-dash-users-table-headers'>
                                    <div className='admin-dash-users-table-headers-text left'>FULL NAME</div>
                                    <div style={{ display: 'flex' }}>
                                        <div className='admin-dash-users-table-headers-text right'>
                                            USERNAME
                                        </div>
                                        <div className='admin-dash-users-table-headers-text right'>
                                            STATUS
                                        </div>
                                        <div className='admin-dash-users-table-headers-text right'>
                                            ACTIVATION DATE
                                        </div>
                                    </div>
                                </div>
                                {fpUsers.length === 0 ? null :
                                    fpUsers.filter(usr => {
                                        if (activeFilter === 'Active' && usr[searchFilter] !== undefined) {
                                            return usr.Blocked === false && usr.ActivatedDate && usr[searchFilter].toLowerCase().includes(searchField);
                                        }
                                        else if (activeFilter === 'Pending' && usr[searchFilter] !== undefined) {
                                            return usr.Blocked === false && !usr.ActivatedDate && usr[searchFilter].toLowerCase().includes(searchField);
                                        }
                                        else if (activeFilter === 'Deactivated' && usr[searchFilter] !== undefined) {
                                            return usr.Blocked === true && usr[searchFilter].toLowerCase().includes(searchField);
                                        }
                                        else {
                                            if (usr[searchFilter] !== undefined) {
                                                return usr[searchFilter].toLowerCase().includes(searchField);
                                            }
                                        }
                                    })
                                        .map((usr, index) => (
                                            <div className='hover' key={usr._id} id={usr._id} onClick={() => handleOnClickUser(usr)}>
                                                <div className='admin-dash-users-table-headers white'>
                                                    <div className='admin-dash-users-table-headers-text left bold'>{usr.FirstName} {usr.LastName}</div>
                                                    <div style={{ display: 'flex' }}>
                                                        <div className='admin-dash-users-table-headers-text right'>
                                                            <div
                                                                className={`admin-dash-users-table-headers-text`}>
                                                                {usr.IDNumber ? usr.IDNumber : usr.Username}
                                                            </div>
                                                        </div>
                                                        <div className='admin-dash-users-table-headers-text right bold'>
                                                            {usr.Blocked === true &&
                                                                <div
                                                                    className={`admin-dash-users-table-headers-text bold ${'deactivated'}`}>
                                                                    {'Deactivated'}
                                                                </div>
                                                            }
                                                            {usr.Blocked === false && usr.ActivatedDate &&
                                                                <div
                                                                    className={`admin-dash-users-table-headers-text bold ${'active'}`}>
                                                                    {'Active'}
                                                                </div>
                                                            }
                                                            {usr.Blocked === false && !usr.ActivatedDate &&
                                                                <div
                                                                    className={`admin-dash-users-table-headers-text bold ${'pending'}`}>
                                                                    {'Pending'}
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className='admin-dash-users-table-headers-text right'>
                                                            <div
                                                                className={`admin-dash-users-table-headers-text`}>
                                                                {usr.ActivatedDate ? new Date(usr.ActivatedDate).toLocaleDateString() : 'N/A'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        ))
                                }

                            </div>
                        </div>
                    </div>
                }
                {visibleChild === 'singular-user' &&
                    <div style={{ width: '100%' }}>

                        <div style={{ background: "#FFFFFF", width: '95%', borderRadius: '20px', padding: '35px' }}>
                            {/* TOP DIV */}
                            <div style={{ display: 'flex', marginBottom: '20px', gap: '25px', alignItems: 'center' }}>
                                <button className='admin-dash-back-arrow-btn' onClick={() => setVisibleChild("Users")}>Back</button>
                                <header className='admin-dash-sub-header' style={{ fontSize: '13px' }}>
                                    <span className='admin-dash-blue-clients-text normal'>
                                        {'User > Active users '}</span>{` > ${currentUser.FirstName} ${currentUser.LastName}`}
                                </header>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', gap: '50px' }}>
                                <div>
                                    <p className="text-[15px] font-bold">Personal details</p>
                                    <br />
                                    <div class="flex gap-[17px] justify-start flex-row">
                                        <div className='flex flex-col justify-start'>
                                            <label className='admin-dash-client-details-text small'>First name</label>
                                            <div className='admin-dash-client-details-text smallbold'>
                                                {currentUser.FirstName}
                                            </div>
                                        </div>
                                        <div className='flex flex-col justify-start'>
                                            <label className='admin-dash-client-details-text small'>Last name</label>
                                            <div className='admin-dash-client-details-text smallbold'>
                                                {currentUser.LastName}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr style={{ width: '100%' }} />
                            <br />
                            <p className="text-[15px] font-bold">Controls</p>
                            <br />
                            <div className='flex gap-6'>
                                {/* ACTIVE */}
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '8px' }}>
                                    <p>
                                        {currentUser.ActivatedDate ?
                                            `User activated ${new Date(currentUser.ActivatedDate).toLocaleString()}` :
                                            `User hasn't activated benefit`}
                                    </p>
                                    {currentUser.Blocked === true ?
                                        <p className='admin-dash-blue-clients-text' style={{ color: 'red' }}>User blocked</p> :
                                        <p className='admin-dash-blue-clients-text'>User active</p>
                                    }
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <IOSToggle checked={!userBlocked} onChange={handleCheckUserBlocked} sx={{ m: 1 }} />
                                            }
                                            label=""
                                        />
                                    </FormGroup>
                                </div>
                                <div>
                                    <p>Elected advance percentage</p>
                                    <InputSlider
                                        value={newPerc}
                                        min={0}
                                        max={25}
                                        onChange={handleSetPercValue}
                                    />

                                    {newPerc !== currentUser.ElectedAdvancePerc &&
                                        <p onClick={() => updateFpUser({ ElectedAdvancePerc: newPerc })}
                                            className='admin-dash-blue-clients-text'>
                                            Update percentage
                                        </p>
                                    }
                                </div>

                            </div>
                            <br />
                            <hr style={{ width: '100%' }} />
                            <br />
                            <div >
                                <p className="text-[15px] font-bold">Amount available</p>
                                <br />
                                <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                                    <p>{`R ${available.toLocaleString()}`}</p>
                                    <svg width="400" height="40" margin="0" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="10" y="10" width="380" height="20" fill="#e0e0e0" rx="10" ry="10" />

                                        <rect x="10" y="10" width={`${((available / max)) * 380}`} height="20" fill="#0156DE" rx="10" ry="10" />
                                    </svg>
                                    <p>{`out of R ${max.toLocaleString()}`}</p>
                                </div>

                            </div>
                            <br />
                            <hr style={{ width: '100%' }} />
                            <br />
                            <div>
                                {/* DETAILS */}
                                <div>
                                    <p className="text-[15px] font-bold">Withdrawal history</p>
                                    <br />
                                    {history.length > 0 ?
                                        <div className='flex-column' style={{ gap: '6px' }}>
                                            {history.map((adv) => (
                                                <>
                                                    <div key={adv._id} className='flexible-pay-history-item-container'>
                                                        <div>
                                                            <p style={{ margin: 0 }}>{`Date: ${new Date(adv.Date).toLocaleDateString()} at ${new Date(adv.Date).toLocaleTimeString()}`}</p>
                                                            <p style={{ color: getStatusColor(adv.Status), margin: 0 }}>{`${adv.Status}`}</p>
                                                        </div>
                                                        <div style={{ display: 'flex', gap: '10px' }}>
                                                            <p style={{ fontSize: '15px', margin: 0 }}>{`R ${(adv.Amount + adv.Fee).toLocaleString()}`}</p>
                                                        </div>
                                                    </div>
                                                </>


                                            ))}
                                        </div>

                                        :
                                        <div>No advances to date</div>
                                    }
                                </div>

                            </div>

                        </div>
                    </div>
                }
            </>
        );
    }
})

export default ClientBenefitsFlexiblePayUsers
