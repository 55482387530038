import React, { useEffect } from 'react';
//React Router
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Navigate
} from "react-router-dom";
import './App.scss';
import { observer } from 'mobx-react';
import { useStore } from './stores/Store';

import PrivateRouter from './privateRouter';
import AdminRouter from './adminRouter';

import { LoadingScreen } from './components';
import { AutohideSnackbar } from './components/MUI';

import EmployerBenefitsAssessment from './pages/onboarding/employerBenefitsAssessment'
import ConfirmClientAccount from './pages/onboarding/confirmClientAccount';
import ConnectPayroll from './pages/onboarding/connectPayroll';
import EmployerTerms from './pages/onboarding/employerAgreement';
import ErrorPage from './pages/errorPage';
import AdminHome from './pages/admin';
import SignIn from './pages/signin';
import SignUp from './pages/signup';
import ClientAdminHome from './pages/client';
import AddEmployeesForBenefitsAssessment from './pages/onboarding/addEmployeesForAssessment';
import EmployeeBenefitsAssessment from './pages/onboarding/employeeBenefitsAssessment';
import EmployeeTerms from './pages/signup/agreement';
import VerifyId from './pages/signup/verifyIdQuestions';
import SecureAccount from './pages/signup/idsuccess';
import FaceRec from './pages/signup/faceRecognition';
import AuthSuccess from './pages/signup/authSuccessful';
import EmployeeDash from './pages/employee';
import PasswordAuth from './pages/signup/passwordAuth';
import Profile from './pages/profile';
import EmployeeBenefits from './pages/employee/benefits';
import EmployeeBenefitsInfo from './pages/employee/benefits/info';
import ClientBenefits from './pages/client/benefits/consume';
import EmployeeBaselineAssessment from './pages/employee/baseline';
import EmployeeCheckout from './pages/employee/checkout';
import EmployeeCheckoutSuccess from './pages/employee/checkout/success';
import AdminReporting from './pages/admin/reporting';
import UploadPayrollFile from './pages/client/user/upload file';
import ResetPassword from './pages/resetPassword';
import IdVerificationFailed from './pages/signup/idfailed';

//flexible pay
import FlexiblePayHome from './pages/employee/benefits/flexiblePay';

const App = observer(() => {
  const commonStore = useStore()
  // useEffect(() => {
  //   store.checkAppVersion().then((ret) => {
  //     if (ret === false) {
  //       if (1 === 1) {
  //         if ('caches' in window) {
  //           caches.keys().then(function (cacheNames) {
  //             cacheNames.forEach(function (cacheName) {
  //               caches.delete(cacheName);
  //             });
  //           });
  //         }

  //         // Clear the cache by appending a random query string to the current URL
  //         // var currentUrl = window.location.href;
  //         // var newUrl = currentUrl + (currentUrl.indexOf('?') !== -1 ? '&' : '?') + 'nocache=' + Date.now();
  //         // window.location.href = newUrl;
  //       }
  //     }
  //   })
  // }, []);
  return (
    <Router>
      <div className="App">
        <LoadingScreen />
        <AutohideSnackbar content={commonStore.snackBarDetails.content} open={commonStore.snackBarDetails.open} type={commonStore.snackBarDetails.type} setSnackBarClose={commonStore.resetSnackBar} />
        <Routes>
          {/* NON PRIVATE ROUTER */}
          <Route path="/client/confirm" element={<ConfirmClientAccount />} />
          <Route path="/error" element={<ErrorPage />} />
          <Route path='*' element={<Navigate to='/signin' />} />
          <Route path='/signin' element={<SignIn />} />
          <Route path='/signup' element={<SignUp />} />
          <Route path="/client/assessment/add" element={<AddEmployeesForBenefitsAssessment />} />
          <Route path="/employees/benefits/assessment" element={<EmployeeBenefitsAssessment />} />
          <Route path="/employee/agreement" element={<EmployeeTerms />} />
          <Route path="/employee/verifyid" element={<VerifyId />} />
          <Route path="/employee/account/secure" element={<SecureAccount />} />
          <Route path="/employee/verify/face" element={<FaceRec />} />
          <Route path="/employee/account/confirm" element={<AuthSuccess />} />
          <Route path="/reset/password" element={<ResetPassword />} />
          <Route path="/employee/auth/password" element={<PasswordAuth />} />
          <Route path="/idverificationfailed" element={<IdVerificationFailed />} />

          {/*PRIVATE ROUTER */}
          <Route path="/client/benefits/assessments" element={<PrivateRouter><EmployerBenefitsAssessment /></PrivateRouter>} />
          <Route path="/client/activate/payroll" element={<PrivateRouter><ConnectPayroll /></PrivateRouter>} />
          <Route path="/client/agreement" element={<PrivateRouter><EmployerTerms /></PrivateRouter>} />
          <Route path="/client/home/:section" element={<PrivateRouter><ClientAdminHome /></PrivateRouter>} />
          <Route path="/admin/home/:section" element={<PrivateRouter><AdminHome /></PrivateRouter>} />
          <Route path="/employee/:section" element={<PrivateRouter><EmployeeDash /></PrivateRouter>} />
          <Route path="/profile" element={<PrivateRouter><Profile /></PrivateRouter>} />
          <Route path="/employee/benefits" element={<PrivateRouter><EmployeeBenefits /></PrivateRouter>} />
          <Route path="/employee/benefits/info" element={<PrivateRouter><EmployeeBenefitsInfo /></PrivateRouter>} />
          <Route path="/client/benefits/consume" element={<PrivateRouter><ClientBenefits /></PrivateRouter>} />
          <Route path="/employee/baseline" element={<PrivateRouter><EmployeeBaselineAssessment /></PrivateRouter>} />
          <Route path="/employee/flexiblepay" element={<PrivateRouter><FlexiblePayHome /></PrivateRouter>} />
          <Route path="/employee/checkout" element={<PrivateRouter><EmployeeCheckout /></PrivateRouter>} />
          <Route path="/employee/checkout/success" element={<PrivateRouter><EmployeeCheckoutSuccess /></PrivateRouter>} />
          <Route path="/admin/reporting" element={<PrivateRouter><AdminReporting /></PrivateRouter>} />
          <Route path="/client/employee/upload" element={<PrivateRouter><UploadPayrollFile /></PrivateRouter>} />
        </Routes>
      </div>
    </Router>
  );
})

export default App;
